import { getInitials, generateBackground } from '../../../pages/Account/avatarsUtils';
import { BtnIcon } from '../../Buttons';
import Search from '../../Search/Search';
import styles from './ChoiceClient.module.scss';
// import instance from '../../../api/erp/configAxios';
import instance from '../../../api/erp/configAxiosOrganizations';
import { IconSort, IconCross, IconFilter } from '../../Icons';
import { useEffect, useState } from 'react';
import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

function ChoiceClient({ handleClientInfo, client }) {
	// получение списка клиентов
	const [clients, setClients] = useState([]);
	const isDesktop = useMediaQuery({ minWidth: 1550 });

	console.log(clients, 'myClients');

	const { sideBarActive } = useSelector((state) => state.sideBar);

	const handleGetClients = (url) => {
		instance
			.get(url)
			.then((response) => {
				setClients(response.data.results);
			})
			.catch((error) => {});
	};
	console.log(localStorage.getItem('terminalID'));

	useEffect(() => {
		// handleGetClients(`?terminal=${Number(localStorage.getItem('terminalID')) + 1}&user_type=2`);
		handleGetClients(`/list/`);
	}, []);

	return (
		<div className={`${styles.choiceClient}`}>
			<div className={styles.topLine}>
				<h4 className={styles.choiceClient__title}>Выберите клиента</h4>
			</div>
			<div className={styles.choiceClient__body}>
				{/*<div className={styles.choiceClient__search}>
					<Search />
					 {[<IconSort size="large" color="primary_white" />, <IconFilter size="large" color="primary_white" />].map(
						(icon, index) => (
							<BtnIcon key={index} type="button" icon={icon} size="p-6" />
						),
					)} 
				</div>*/}
				<div className={`${styles.client_info} `}>
					<div className={`${styles.choiceClient__list}`}>
						{clients?.map((el, index) => (
							<div
								className={`${styles.choiceClient__item} ${client?.id === el.id ? styles.active : ''} ${
									sideBarActive ? styles.choiceClient_withSideBar : ''
								}`}
								key={el.id}
								onClick={() => handleClientInfo(el)}
							>
								<div className={styles.item__img} style={{ background: generateBackground(el.organization_name) }}>
									{getInitials(el.organization_name)}
								</div>
								<div className={styles.item__info}>
									<p className={styles.item__info_name}>
										{!isDesktop ? el.organization_name?.slice(0, 22) + '...' : el.organization_name}
									</p>
									<p className={styles.item__info_contract}>Договор №{el.inn}</p>
								</div>
							</div>
						))}
					</div>
					{/* //! Тут будет модалка о клиенте */}
					{/* {client && <DataClient client={client} handleNextStep={handleNextStep} />} */}
				</div>
			</div>
		</div>
	);
}

export default ChoiceClient;
