import styles from './PhotoFix.module.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useForm } from 'react-hook-form';
import MainInput from '../../../../../../../components/Inputs/MainInput/MainInput';
import { useState } from 'react';
import UniversalButton from '../../../../../../../components/Buttons/UniversalButton/UniversalButton';
import { IconPlus, IconTrash } from '../../../../../../../components/Icons';
import ImageUploading from 'react-images-uploading';
import RViewerJS from 'viewerjs-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import S3Manager from '../../../../../../../helpers/s3storage';
import { setPhotoFixContainer } from '../../../../../../../store/slices/containerAcceptanceSlice';

function PhotoFix() {
	const { register, setFocus, setValue, formState } = useForm({});
	const { errors } = formState;

	// Пломбы
	const [seal, setSeal] = useState('');
	const handleChangeSeal = (e) => {
		setSeal(e.target.value);
	};

	// Стили для раскрывающихся блоков
	const styleAccordion = {
		borderRadius: '12px !important',
		margin: '0 !important',
		boxShadow: 'none',
		'&::before': {
			display: 'none',
		},
		'& .MuiAccordionSummary-root': {
			padding: '0 12px !important',
			minHeight: '48px !important',
		},
		'& .MuiAccordionSummary-content': {
			fontSize: '16px',
			fontWeight: '600',
			lineHeight: '24px',
			letterSpacing: '0.08',
			textAlign: 'center',
			margin: '0 !important',
		},
		'& .MuiAccordionDetails-root ': {
			padding: '0 12px 12px 12px !important',
			fontSize: '14px',
			fontWeight: '500',
			lineHeight: '22px',
			letterSpacing: '0.035',
		},
		'& .MuiSvgIcon-root': { fill: '#1C1C1E' },
	};

	// Фотофиксация контейнера
	const { reportId } = useParams();
	const dispatch = useDispatch();
	const { photoFixContainer } = useSelector((state) => state.containerAccept);
	const [images, setImages] = useState(() => {
		const savedImages = localStorage.getItem('uploadedImages');
		return savedImages ? JSON.parse(savedImages) : [];
	});

	const imageUploader = new S3Manager('damages');

	const onChange = async (imageList, addUpdateIndex) => {
		setImages(imageList);
		const newImage = imageList[addUpdateIndex];
		if (newImage) {
			try {
				const result = await imageUploader.uploadImage({
					actId: reportId,
					file: { name: 'expample.png', data_url: newImage.data_url },
				});
				const photka = await imageUploader.getImage(result.Key);
				await dispatch(setPhotoFixContainer({ Key: result.Key, Location: result.Location, imageSrc: photka }));
			} catch (error) {
				console.error('Ошибка загрузки изображения:', error);
			}
		}
	};

	return (
		<div className={styles.photoFix__list}>
			<Accordion sx={styleAccordion}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
					Инструкция
				</AccordionSummary>
				<AccordionDetails>
					По ВКЗ Альянс работаем по следующей схеме:прием и осмотр ктк осуществляется по стандартной схеме, подгружаем фото
					повреждений и акт, указываем статус Поврежден, отправляем в общий сток клиента. !!!Смету не составляем!!! При выдаче
					снимаем блок (СИНИЙ ЗАМОЧЕК) самостоятельно, без дополнительного согласования. Если водитель отказывается ставить
					поврежденный ктк, работаем также по стандартной схеме, через согласование дополнительных крановых.
				</AccordionDetails>
			</Accordion>
			<Accordion sx={styleAccordion}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
					Пломба 1
				</AccordionSummary>
				<AccordionDetails>
					<div className={styles.photoFix__seal}>
						<MainInput
							title={'Номер пломбы'}
							errorTitle={'Некорректный номер'}
							name="seal"
							register={register}
							value={seal}
							handleFieldChange={handleChangeSeal}
							setValue={setValue}
							setFocus={setFocus}
							errors={errors}
							rules={{}}
							placeholder={'000000'}
						/>
						<UniversalButton
							typeButton={'button'}
							size={'medium'}
							disabled={true}
							color={'primary'}
							icon={<IconPlus color={'white'} size={'largeS'} />}
						/>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion sx={styleAccordion}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
					Фотофиксация
				</AccordionSummary>
				<AccordionDetails
					sx={{
						display: 'flex',
						gap: '4px',
						alignItems: 'center',
						flexWrap: 'wrap',
					}}
				>
					<ImageUploading
						multiple
						value={images}
						onChange={onChange}
						maxNumber={10}
						dataURLKey="data_url"
						acceptType={['jpg', 'png', 'jpeg']}
					>
						{({ imageList, onImageUpload, isDragging, dragProps }) => (
							<>
								<RViewerJS className={styles.viewer}>
									{photoFixContainer.pictures.map((image, index) => (
										<div key={`image_wrapper_${index}`} className={styles.onePhotoCard}>
											<img src={image.imageSrc} alt="example" />
											<div>
												<UniversalButton
													icon={<IconTrash size={'large'} color={'white'} />}
													typeButton={'button'}
												/>
											</div>
										</div>
									))}
								</RViewerJS>

								<div
									className={`${styles.uploadImageWrapper} ${styles.photoContainer} ${isDragging ? styles.dragging : ''} ${photoFixContainer?.pictures.length > 0 ? styles.miniUploadImageWrapper : ''}`}
									{...dragProps}
								>
									<button className={styles.uploadButton} onClick={onImageUpload}>
										{photoFixContainer?.pictures.length > 0 ? '' : 'Загрузить фото'}
										<IconPlus color={'black'} size={'large'} />
									</button>
								</div>
							</>
						)}
					</ImageUploading>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}

export default PhotoFix;
