import React, { useEffect, useState } from 'react';
import ContentUsers from '../ContentUsers/ContentUsers';
import { useDispatch, useSelector } from 'react-redux';
import styles from './TerminalUsers.module.scss';
import { Search } from '../../../../../components';
import UniversalButton from '../../../../../components/Buttons/UniversalButton/UniversalButton';
import { IconFilter, IconPlus } from '../../../../../components/Icons';
import { useForm } from 'react-hook-form';
import { clearUserInputs, getUserGroups, setCurrentUser, setModalType, setUseFormUserStub } from '../../../../../store/slices/userSlice';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../../store/slices/sideBarSlice';
import { setCurrentClient } from '../../../../../store/slices/clientSlice';
import ModalProfile from '../Modals/ModalProfile';
import AddTerminalUserChoose from '../Modals/ModalTerminalUserChoose';
import RequireUI from '../../../../../hoc/RequireUI';
import ModalPlug from '../../../../../components/Modal/ModalPlug';
export default function TerminalUsers() {
	// ! Может понадобится когда будем делать настройку прав у сотрудников
	const {
		register,
		setFocus,
		setValue,
		formState: { errors },
		clearErrors,
		handleSubmit,
		reset,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			// email: localStorage.getItem("email"),
			// FLName: localStorage.getItem("FLName"),
			// login: localStorage.getItem("login"),
			// numberContract: localStorage.getItem("numberContract"),
			// urAdress: localStorage.getItem("urAdress"),
			// urName: localStorage.getItem("urName"),
			// autoNum: '',
		},
	});

	const { terminalUserList, currentTerminalId } = useSelector((state) => state.terminalPage);
	const { sideBarActive } = useSelector((state) => state.sideBar);

	const dispatch = useDispatch();

	const handleOpenSideBarForNewTerminalUser = () => {
		dispatch(setSideBarActive(true));
		dispatch(setCurrentElementForSideBar(<AddTerminalUserChoose />));
	};

	const [isOpenModal, setIsOpenModal] = useState(false);

	const onClose = () => {
		setIsOpenModal(false);
	};

	const handleOpenModalPlug = () => {
		setIsOpenModal(true);
	};

	return (
		<>
			<ModalPlug isOpen={isOpenModal} fullScreen={true} onClose={onClose} page={'Терминалы-сотрудники'} />
			<div className={styles.TMI_Container}>
				<div className={`${styles.MainData} ${sideBarActive ? styles.MainData_withSidebar : ''}`}>
					<div className={styles.MainData_upperPart}>
						<div className={styles.title_and_button}>
							<p className={styles.blocks_title}>Сотрудники</p>
							<RequireUI type={{ name: 'AddEmpls', terminal_id: currentTerminalId }}>
								<UniversalButton
									icon={<IconPlus />}
									color={'primary'}
									size={'large'}
									onClick={handleOpenSideBarForNewTerminalUser}
									type={'with-border'}
								/>
							</RequireUI>
						</div>
						<div className={styles.search_and_button}>
							<Search disabled={true} handleOpenModalPlug={handleOpenModalPlug} />

							{/* <UniversalButton
							icon={<IconFilter color={'primary_white'} />}
							color={'primary'}
							size={'large'}
							onClick={() => {
								console.log('search filter');
							}} //your function
							type={'outline'}
						/> */}
						</div>
					</div>
					<ContentUsers type={'terminalUsers'} items={terminalUserList} />
				</div>
			</div>
		</>
	);
}
