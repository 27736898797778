import { useEffect, useState } from 'react';
import '../../../../../../assets/styles/Tables.scss';
import { UniversalButton } from '../../../../../../components';
import { useDispatch } from 'react-redux';
import ModalEditInput from './ModalEditInput';

function ModalEditEntries({ handleCloseModal, selectEntries }) {
	const dispatch = useDispatch();

	const [transporterArray, setTransporterArray] = useState();
	const [transporter, setTransporter] = useState();

	const [vehicleArray, setVehicleArray] = useState();
	const [vehicle, setVehicle] = useState();

	const [driverArray, setDriverArray] = useState();
	const [driver, setDriver] = useState();

	const [seal_numberArray, setSeal_numberArray] = useState();
	const [seal_number, setSeal_number] = useState();

	const [chekin_atArray, setChekin_atArray] = useState();
	const [chekin_at, setChekin_at] = useState();

	const [terminal_managerArray, setTerminal_managerArray] = useState();
	const [terminal_manager, setTerminal_manager] = useState();

	useEffect(() => {
		setTransporterArray(selectEntries?.map((elem, index) => elem.transporter));
		setVehicleArray(selectEntries?.map((elem, index) => elem.vehicle));
		setDriverArray(selectEntries?.map((elem, index) => elem.vehicle_driver));
		setSeal_numberArray(selectEntries?.map((elem, index) => elem.seal_number));
		setTerminal_managerArray(selectEntries?.map((elem, index) => elem.name));
		setChekin_atArray(selectEntries?.map((elem, index) => elem.chekin_at));
	}, [selectEntries]);

	// отправка редактирования на сервер
	const handleSaveEntries = async () => {
		handleCloseModal();
	};

	return (
		<div className="modalEdit">
			<div>
				<div className="modalEditСontainers__header">
					Пропуска
					<div>
						{selectEntries?.map((elem, index) => (
							<p key={index}>№{elem.id}</p>
						))}
					</div>
				</div>
				<div className="modalEdit__body">
					<form>
						{transporterArray && (
							<ModalEditInput
								name="vehicle_driver"
								value={transporter}
								setValue={setTransporter}
								arrayValues={transporterArray}
								label="Перевозчик"
							/>
						)}
						{vehicleArray && (
							<ModalEditInput
								name="vehicle_driver"
								value={vehicle}
								setValue={setVehicle}
								arrayValues={vehicleArray}
								label="Автомобиль"
							/>
						)}
						{driverArray && (
							<ModalEditInput
								name="vehicle_driver"
								value={driver}
								setValue={setDriver}
								arrayValues={driverArray}
								label="Водитель"
							/>
						)}
						{seal_numberArray && (
							<ModalEditInput
								name="vehicle_driver"
								value={seal_number}
								setValue={setSeal_number}
								arrayValues={seal_numberArray}
								label="Номер прицепа"
							/>
						)}
						{terminal_managerArray && (
							<ModalEditInput
								name="vehicle_driver"
								value={terminal_manager}
								setValue={setTerminal_manager}
								arrayValues={terminal_managerArray}
								label="Ответственный на терминале"
							/>
						)}
						{chekin_atArray && (
							<ModalEditInput
								name="vehicle_driver"
								value={chekin_at}
								setValue={setChekin_at}
								arrayValues={chekin_atArray}
								label="Ожидаемое время проезда"
							/>
						)}
					</form>
				</div>
			</div>
			<div className="modalEdit__footer">
				<div className="footer__btnsChanges">
					<UniversalButton label={'Отменить'} onClick={handleCloseModal} color={'error'} size={'medium'} typeButton={'button'} />
					<UniversalButton
						label={'Сохранить'}
						onClick={handleSaveEntries}
						color={'succesful'}
						size={'medium'}
						typeButton={'button'}
					/>
				</div>
			</div>
		</div>
	);
}

export default ModalEditEntries;
