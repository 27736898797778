import React from 'react';
import styles from './OrderBlock.module.scss';
import Search from '../../Search/Search';
import { IconFilter, IconSort } from '../../Icons';
import { BtnIcon } from '../../Buttons';

function OrderBlock({ title, children, topLineBtn }) {
	return (
		<div className={styles.main_block}>
			<div className={styles.main_block_topLine}>
				<p className={styles.main_block_title}>{title}</p>
				{topLineBtn || <></>}
			</div>
			{/* 	<div className={styles.containers__search}>
				<Search />
				{[<IconSort size="large" color="primary_white" />, <IconFilter size="large" color="primary_white" />]?.map(
					(icon, index) => (
						<BtnIcon key={index} type="button" icon={icon} size="p-6" />
					),
				)} 
			</div> */}
			{children}
		</div>
	);
}

export default OrderBlock;
