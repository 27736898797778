import { useForm } from 'react-hook-form';
import '../../../../../../assets/styles/Tables.scss';
import { useEffect, useState } from 'react';

function ModalEditInput({ name, value, setValue, arrayValues, label }) {
	const { register } = useForm();
	const [error, setError] = useState(false);

	// Значение в инпут
	useEffect(() => {
		if (arrayValues) {
			if (arrayValues.every((element) => element === arrayValues[0])) {
				setValue(arrayValues[0]);
			} else {
				setValue('Разное');
			}
		}
	}, []);

	// Ошибка при пустом инпуте
	useEffect(() => {
		if (value && value.length > 0) {
			setError(false);
		} else {
			setError(true);
		}
	}, [value]);

	return (
		<div className="editSelect">
			<label className="editSelect__label required">{label}</label>
			<div className={`editSelect__container ${error ? 'error' : ''}`}>
				<input
					{...register(name, {
						onChange: (e) => setValue(e.target.value),
					})}
					value={value ? value : ''}
					placeholder="Введите текст"
				/>
			</div>
		</div>
	);
}

export default ModalEditInput;
