import { useDispatch, useSelector } from 'react-redux';
import { Search, Select, UniversalButton } from '../../../../../components';
import { IconActionLock, IconActionUnlock } from '../../../../../components/Icons';
import styles from './Modals.module.scss';
import { useEffect } from 'react';
import { getTerminalList } from '../../../../../store/slices/terminalPageSlice';
import { getPermissionList } from '../../../../../store/slices/permissionsSlice';
import {
	clearNessesaryDataBeforeCreatingUserAccessToTerminal,
	deleteTerminalAccessForUser,
	postTerminalAccessForUser,
	setChangeModalTypeForAccesses,
	setChangeModalTypeForChangeUserPermissions,
	setCurrentUserChoosenTerminalData,
	setCurrentUserNewTerminalData,
	setInputChange,
	setTerminalForAccessId,
} from '../../../../../store/slices/userSlice';

function ModalAccesses({ activeTab, setActiveTab, handleOpenAccessGroupSettings, modalProfileType }) {
	const dispatch = useDispatch();
	// const currentUserTerminalList = useSelector((state) => state.user.currentUser.terminals);
	const {
		currentUser,
		userList,
		currentUserTerminalList,
		currentUserChoosenTerminalData,
		userChoosenTerminalGroupPermissions,
		terminalAccessUserGroupId,
		inputChange,
	} = useSelector((state) => state.user);
	const { terminalList } = useSelector((state) => state.terminalPage);

	// Получение списка терминалов только во вкладке "Сотрудники" для избежания ошибки при открытии этой же вкладке в терминалах
	useEffect(() => {
		if (modalProfileType === 'users') {
			dispatch(getTerminalList());
		}
	}, []);

	const handleOpenRights = (el) => {
		console.log(el, 'ЭЛ');
		dispatch(getPermissionList());
		setActiveTab(2);
		dispatch(setCurrentUserChoosenTerminalData(el));
		dispatch(setChangeModalTypeForChangeUserPermissions(true));
		dispatch(setChangeModalTypeForAccesses(false));
		// dispatch(setUserRightsModalActiv(true));
		// dispatch(setUserModalActive(false));
		// dispatch(set)
	};

	const handleAccess = ({ action, terminal_id }) => {
		if (action === 'open') {
			console.log(terminal_id, 'ID');
			dispatch(getPermissionList());
			dispatch(setTerminalForAccessId(terminal_id));
			dispatch(setCurrentUserNewTerminalData({ terminalList: terminalList, id: terminal_id }));
			dispatch(setChangeModalTypeForAccesses(true));
			dispatch(setChangeModalTypeForChangeUserPermissions(false));
			setActiveTab(4);
		}

		if (action === 'close') {
			dispatch(setInputChange(true));
			// if(inputChange){
			dispatch(deleteTerminalAccessForUser({ terminal_id: terminal_id, user_id: currentUser.id }));

			// }
		}
	};
	// };

	const findUsersTerminalInTerminalList = (el) => {
		// Проверяем каждый объект в массиве two
		for (let i = 0; i < currentUserTerminalList?.length; i++) {
			// Если находим объект с совпадающим id, возвращаем true
			if (currentUserTerminalList[i]?.id === el.id) {
				return currentUserTerminalList[i];
			}
		}
		// Если не нашли ни одного совпадения, возвращаем false
		return false;
	};

	// useEffect(()=>{clearNessesaryDataBeforeCreatingUserAccessToTerminal()},[])

	return (
		<div className={styles.terminal__accessList}>
			<Search />
			{terminalList?.map((el, ind) => (
				<div
					key={ind}
					className={`${styles.terminal__accessCard} ${findUsersTerminalInTerminalList(el) ? '' : styles.ban}`}
				>
					<div className={styles.terminal__accessCard_info}>
						<p className={styles.terminal__accessCard_name}>{el.name}</p>
						<p className={styles.terminal__accessCard_address}>{el.address}</p>
						<div>
							{findUsersTerminalInTerminalList(el) ? (
								<UniversalButton
									id={el.id}
									label={`Роль: ${findUsersTerminalInTerminalList(el)?.group?.name}`}
									icon={<IconActionUnlock size={'medium'} color={'primary_white'} />}
									size={'small'}
									color={'primary'}
									onClick={() => handleOpenRights(el.id)}
								/>
							) : (
								''
							)}
						</div>
					</div>
					<div className={styles.terminal__accessCard_stack}>
						{findUsersTerminalInTerminalList(el) ? (
							<>
								<p className={styles.terminal__accessCard_access}>Доступ открыт</p>
								<UniversalButton
									id={el.id}
									label={'Закрыть доступ'}
									icon={<IconActionLock size={'medium'} color={'primary_white'} />}
									size={'small'}
									color={'error'}
									onClick={() => handleAccess({ action: 'close', terminal_id: el.id })}
								/>
							</>
						) : (
							<>
								<p className={styles.terminal__accessCard_access}>Доступ закрыт</p>
								<UniversalButton
									label={'Открыть доступ'}
									icon={<IconActionUnlock size={'medium'} color={'primary_white'} />}
									size={'small'}
									color={'succesful'}
									onClick={() => handleAccess({ action: 'open', terminal_id: el.id })}
								/>
							</>
						)}
					</div>
				</div>
			))}
		</div>
	);
}

export default ModalAccesses;
