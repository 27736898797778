import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ModulesSettings.module.scss';
import { useMediaQuery } from 'react-responsive';
import UniversalButton from '../../../../../components/Buttons/UniversalButton/UniversalButton';
import { Switcher } from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { postTerminalData, putTerminalData, setTerminalDataCreate } from '../../../../../store/slices/terminalPageSlice';
import { RequireUIFunc } from '../../../../../hoc/RequireUI';
import ModalPlug from '../../../../../components/Modal/ModalPlug';

export default function TerminalMainInfo({ create, change, setPage }) {
	const [open, setOpen] = useState(false);
	const [switcherChecked, setSwitcherChecked] = useState(false);

	const navigate = useNavigate();
	const isCreateRoute = '/accountEmpl/createTerminal';
	const isDesktop = useMediaQuery({ minWidth: '1279.9px' });

	const updatedData = {
		bookkeeping_module: switcherChecked,
	};

	const dispatch = useDispatch();

	const { currentTerminalId, currentTerminalData, terminalData } = useSelector((state) => state.terminalPage);

	const { putTerminalData_success, putTerminalData_loading, putTerminalData_error } = useSelector((state) => state.terminalPage);

	const handleClose = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const handleSwitcherChange = (isChecked) => {
		setSwitcherChecked(isChecked);
	};

	const fetchData = (requestData) => {
		dispatch(postTerminalData({ requestData }));
	};

	const onSubmit = async () => {
		if (window.location.pathname !== isCreateRoute) {
			dispatch(putTerminalData({ id: currentTerminalId, requestData: updatedData }));
			setOpen(true);
		} else {
			const reqData = {
				name: terminalData.name,
				address: terminalData.address,
				contact_number: terminalData.contact_number,
				max_teu: terminalData.max_teu,
				bookkeeping_module: switcherChecked,
			};
			await fetchData(reqData);
			dispatch(setTerminalDataCreate(null));
			navigate('/accountEmpl/terminals');
		}
	};

	useEffect(() => {
		if (window.location.pathname !== isCreateRoute) {
			setSwitcherChecked(currentTerminalData.bookkeeping_module);
		}
	}, [currentTerminalData]);

	//право на изменение терминала
	const permByChangeTerminal = !RequireUIFunc({ name: 'ChangeTerminals', terminal_id: currentTerminalId });

	const [isOpenModal, setIsOpenModal] = useState(false);

	const onClose = () => {
		setIsOpenModal(false);
	};

	const handleClickSettingOpenModal = () => {
		setIsOpenModal(true);
	};

	return (
		<>
			<ModalPlug isOpen={isOpenModal} fullScreen={true} onClose={onClose} page={'Терминалы-настройки'} />
			<div className={styles.TMI_Container}>
				{putTerminalData_success && (
					<Snackbar
						open={open}
						onClose={handleClose}
						autoHideDuration={3000}
						anchorOrigin={!isDesktop ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'left' }}
					>
						<Alert severity="success" sx={{ width: '100%' }}>
							Настройки терминала успешно изменены
						</Alert>
					</Snackbar>
				)}
				<div className={styles.MainData}>
					<div className={styles.formContainers}>
						<p className={styles.blocks_title}>Настройка модулей</p>
						<form action="" className={styles.modulesSettings_form} onSubmit={onSubmit}>
							<div className={styles.modulesSettings_formBlock}>
								<div className={styles.modulesSettings_formBlock_item}>
									<span>Контейнеры</span>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
								<div className={styles.modulesSettings_formBlock_item}>
									<p>Прием заявок на прием</p>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
								<div className={styles.modulesSettings_formBlock_item}>
									<p>Прием заявок на вывоз</p>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
								<div className={styles.modulesSettings_formBlock_item}>
									<p>Прием заявок на перегонку</p>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
							</div>
							<div className={styles.modulesSettings_formBlock}>
								<div className={styles.modulesSettings_formBlock_item}>
									<span>Склад</span>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
								<div className={styles.modulesSettings_formBlock_item}>
									<p>Прием заявок на разгрузку</p>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
								<div className={styles.modulesSettings_formBlock_item}>
									<p>Прием заявок на перетарку</p>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
								<div className={styles.modulesSettings_formBlock_item}>
									<p>Прием заявок на погрузку</p>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
							</div>
							<div className={styles.modulesSettings_formBlock}>
								<div className={styles.modulesSettings_formBlock_item}>
									<span>Железная дорога</span>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
								<div className={styles.modulesSettings_formBlock_item}>
									<p>Прием заявок на разгрузку</p>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
								<div className={styles.modulesSettings_formBlock_item}>
									<p>Прием заявок на перетарку</p>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
								<div className={styles.modulesSettings_formBlock_item}>
									<p>Прием заявок на погрузку</p>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
							</div>
							<div className={styles.modulesSettings_formBlock}>
								<div className={styles.modulesSettings_formBlock_item}>
									<span>Ремонт</span>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
								<div className={styles.modulesSettings_formBlock_item}>
									<p>Прием заявок на ремонт</p>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher disabled={true} />
									</div>
								</div>
							</div>
							<div className={styles.modulesSettings_formBlock}>
								<div className={styles.modulesSettings_formBlock_item}>
									<span>Бухгалтерия</span>
									<div onClick={handleClickSettingOpenModal}>
										<Switcher onChecked={handleSwitcherChange} externalChecked={switcherChecked} disabled={true} />
									</div>
								</div>
							</div>
						</form>
						{change && !isDesktop && (
							<div className={styles.actionContainerMob}>
								<UniversalButton
									label={'Сохранить'}
									color={'accent'}
									size={'large'}
									typeButton={'submit'}
									onClick={handleClickSettingOpenModal}
									disabled={permByChangeTerminal}
								/>
							</div>
						)}
					</div>
				</div>
				{change && isDesktop && (
					<div className={styles.actionContainerSet}>
						<UniversalButton
							label={'Сохранить'}
							color={'accent'}
							size={'large'}
							onClick={handleClickSettingOpenModal}
							disabled={permByChangeTerminal}
						/>
					</div>
				)}
				{create && (
					<div className={styles.actionContainerSet}>
						<UniversalButton
							label={'Назад'}
							size={'medium'}
							color={'secondary'}
							onClick={() => {
								setPage(0);
							}}
						/>
						<UniversalButton label={'Создать терминал'} color={'accent'} size={'medium'} onClick={onSubmit} />
					</div>
				)}
			</div>
		</>
	);
}
