import React from "react";
import { UniversalButton } from "../../../../../components";
import { IconPlusCircle } from "../../../../../components/Icons";
import { useDispatch } from "react-redux";
import {
  setCurrentElementForSideBar,
} from "../../../../../store/slices/sideBarSlice";
import ModalProfile from "./ModalProfile";
import { setCurrentClient } from "../../../../../store/slices/clientSlice";
import {
  getUserGroups,
  setCurrentUser,
  setModalType,
} from "../../../../../store/slices/userSlice";
import ModalUserListForTerminal from "./ModalUserListForTerminal";

import style from "./Modals.module.scss";
import IconCompareAdd from "../../../../../components/Icons/IconCompareAdd";

export default function AddTerminalUserChoose() {
  const dispatch = useDispatch();

  const handleOpenModalForNewUser = () => {
    dispatch(setCurrentClient(null));
    dispatch(setCurrentUser(null));
    dispatch(setModalType("create"));
    dispatch(getUserGroups());
    dispatch(
      setCurrentElementForSideBar(<ModalProfile type={"terminalUsers"} />)
    );
  };

  const handleOpenModalForExistingUser = () => {
    dispatch(setCurrentElementForSideBar(<ModalUserListForTerminal />));
  };

  return (
    <div className={style.modal__card}>
      <div className={style.card__user}>
        <p>Создать нового сотрудника или добавить с другого терминала?</p>
      </div>
      <div className={style.chooseButtons_block}>
        <UniversalButton
          icon={<IconPlusCircle color={"white"} />}
          color={"primary"}
          size={"medium"}
          label={"Создать нового сотрудника"}
          onClick={handleOpenModalForNewUser}
        />
        <UniversalButton
          icon={<IconCompareAdd color={"white"} />}
          color={"primary"}
          size={"medium"}
          label={"Добавить существующего"}
          onClick={handleOpenModalForExistingUser}
        />
      </div>
    </div>
  );
}
