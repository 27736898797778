import { useDispatch, useSelector } from 'react-redux';
import styles from './CardOrder.module.scss';
import { getOrderDetailsAction } from '../../../store/slices/orderPageSlice';
// import { getOrderDetailsAction, setCardOrderActive } from "../../../store/slices/orderPageSlice";

function CardOrder({ id, client, total_containers, completed_containers, container_without_cars, date_created }) {
	const { activeCardOrder } = useSelector((state) => state.orderPage);
	const dispatch = useDispatch();
	// const handleOpen = () => {
	//   if (id === activeCardOrder) {
	//     dispatch(setCardOrderActive(false));
	//   } else {
	//     dispatch(setCardOrderActive(id));
	//     dispatch(getOrderDetailsAction({order_id: id}))
	//   }
	// };

	// const handleGetOrderInfo = (id) => {
	//   dispatch(getOrderDetailsAction({order_id:id}))
	// }

	const dateFormatter = (date) => {
		const newDate = new Date(date);
		return `${newDate?.getDate()}.${newDate?.getMonth()}.${newDate?.getFullYear()}`;
	};

	return (
		<div
			className={`${styles.cardOrder} ${activeCardOrder === id ? styles.activeCardOrder : ''}`}
			// onClick={() => handleGetOrderInfo(id)}
		>
			<div className={styles.cardOrder__row}>
				<p className={styles.cardOrder__name}>{client ? client : '-'}</p>
				{/* // TODO номера заявки пока что нет, как появиться нужно сделать. Пока что будет использовааться ID */}
				<p className={styles.cardOrder__number}>№ {id ? id : '-'}</p>
			</div>
			<div className={styles.cardOrder__row}>
				<ul className={styles.cardOrder__info}>
					<li>
						<p>
							Контейнеры:{' '}
							<span className={styles.cardOrder__info_primary}>{total_containers ? total_containers : '0'}</span>
						</p>
					</li>
					<li>
						<p>
							Вывезено:{' '}
							<span className={styles.cardOrder__info_success}>
								{completed_containers ? completed_containers : '0'}
							</span>
						</p>
					</li>
					<li>
						<p>
							Нет водителей:{' '}
							<span className={styles.cardOrder__info_error}>
								{container_without_cars ? container_without_cars : '0'}
							</span>
						</p>
					</li>
				</ul>
				<ul className={styles.cardOrder__info}>
					<li>
						<p>Создана: {dateFormatter(date_created)}</p>
					</li>
					<li>
						{/* // TODO пока что данных на завершение заявки нет */}
						<p>Завершится: 07.12.2023</p>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default CardOrder;
