import styles from './Header.module.scss';
import { useMediaQuery } from 'react-responsive';

function Header({ text, btnDesktop, btnMobile, step, createOrder, type }) {
	// отслеживание ширины экрана
	const isDesktop = useMediaQuery({ minWidth: 450.9 });

	return (
		<div className={styles.header}>
			<p className={styles.header__title}>{text}</p>
			{step && (
				<p className={styles.header__step}>
					Шаг {step} из 3.{' '}
					{createOrder && step === 1
						? 'Выберите терминал'
						: createOrder && step === 2
							? 'Выберите клиента'
							: createOrder && step === 3 && type === 'import'
								? 'Добавьте контейнеры'
								: createOrder && 'Выберите контейнеры'}
				</p>
			)}
			{isDesktop ? btnDesktop : btnMobile}
		</div>
	);
}

export default Header;
