import React, { useEffect, useState } from 'react';
import styles from './Layouts.module.scss';
import style from '../../../../../components/Order/TerminalSmallCardOrder/TerminalSmallCardOrder.module.scss';
import { Search } from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { generateBackground, getInitials } from '../../../avatarsUtils';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../../store/slices/sideBarSlice';
import ModalRights from '../Modals/ModalRights';
import { clearAllPermissionArrays, setUserGroupPermissions } from '../../../../../store/slices/userSlice';
import { getGroup, getGroupList, setChangeModalTypeForGroupChange } from '../../../../../store/slices/groupSlice';
import { getPermissionList } from '../../../../../store/slices/permissionsSlice';
import { RequireUIFunc } from '../../../../../hoc/RequireUI';

export const JobCard = ({ el, index, active, setActive }) => {
	const dispatch = useDispatch();

	const handleActive = () => {
		active !== index ? setActive(index) : setActive(false);
		dispatch(setCurrentElementForSideBar(<ModalRights type="changeGroup" />));
		dispatch(setSideBarActive(true));
		dispatch(getGroup({ group_id: el.id }));
		dispatch(clearAllPermissionArrays());
		dispatch(setChangeModalTypeForGroupChange(true));
	};

	const { sideBarActive } = useSelector((state) => state.sideBar);

	//Право на изменение должности
	const permChangegroup = RequireUIFunc({ name: 'ChangeGroups' });

	return (
		<div
			onClick={() => {
				//Право на изменение должности
				permChangegroup && handleActive(el);
			}}
			className={`${style.mainCardBlock} ${
				active === index ? style.active_mainCardBlock : style.noActive_mainCardBlock
			} ${sideBarActive ? style.show : null}`}
		>
			<div
				className={`${style.mainCardBlock_leftLine} ${
					active === index ? style.activeMainCardBlock_leftLine : style.noActiveMainCardBlock_leftLine
				}`}
			/>
			<div className={style.mainCardBlock_IconInfo}>
				<div style={{ background: generateBackground(el.name) }} className={style.mainCardBlock_nameIcon}>
					{getInitials(el.name)}
				</div>
				<div className={style.mainCardBlock_info}>
					<p>{el.name}</p>
					<p>пользователи: {el?.users_count}</p>
				</div>
			</div>
		</div>
	);
};

function LayoutJobs({ searchValue, setSearchValue }) {
	const { sideBarActive } = useSelector((state) => state.sideBar);
	const { groupList } = useSelector((state) => state.group);
	const dispatch = useDispatch();
	const [active, setActive] = useState(false);

	useEffect(() => {
		dispatch(getGroupList());
		dispatch(getPermissionList());
	}, [dispatch]);

	return (
		<div className={styles.layout}>
			<div className={styles.layout__stack}>
				<div className={`${styles.layout__content} ${sideBarActive ? styles.layout__content_withSidebar : ''}`}>
					<div className={styles.whiteBoard}>
						<div className={styles.search}>
							<Search searchValue={searchValue} setSearchValue={setSearchValue} />
						</div>
						<div className={styles.jobContent}>
							{groupList?.map((el) => (
								<JobCard key={el.id} index={el.id} el={el} active={active} setActive={setActive} />
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LayoutJobs;
