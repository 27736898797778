import React, { useState } from 'react';
import ContentUsers from '../ContentUsers/ContentUsers';
import { useDispatch, useSelector } from 'react-redux';
import styles from './TerminalUsers.module.scss';
import { Search } from '../../../../../components';
import UniversalButton from '../../../../../components/Buttons/UniversalButton/UniversalButton';
import { IconFilter, IconPlus } from '../../../../../components/Icons';
import { setCurrentClient } from '../../../../../store/slices/clientSlice';
import { setModalType as setModalTypeClient } from '../../../../../store/slices/clientSlice';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../../store/slices/sideBarSlice';
import { setCurrentUser } from '../../../../../store/slices/userSlice';
import RequireUI from '../../../../../hoc/RequireUI';
import ModalClient from '../Modals/ModalsCllient/ModalClient';
import ModalPlug from '../../../../../components/Modal/ModalPlug';

export default function TerminalClients() {
	const { terminalClientList, currentTerminalId } = useSelector((state) => state.terminalPage);
	const { sideBarActive } = useSelector((state) => state.sideBar);
	const dispatch = useDispatch();

	const handleOpenSideBarForNewTerminalClient = () => {
		dispatch(setCurrentClient(null));
		dispatch(setCurrentUser(null));
		dispatch(setSideBarActive(true));
		dispatch(setModalTypeClient('create'));
		dispatch(setCurrentElementForSideBar(<ModalClient type={'terminalClients'} />));
	};

	const [isOpenModal, setIsOpenModal] = useState(false);

	const onClose = () => {
		setIsOpenModal(false);
	};

	const handleOpenModalPlug = () => {
		setIsOpenModal(true);
	};

	return (
		<>
			<ModalPlug isOpen={isOpenModal} fullScreen={true} onClose={onClose} page={'Терминалы-клиенты'} />
			<div className={styles.TMI_Container}>
				<div className={`${styles.MainData} ${sideBarActive ? styles.MainData_withSidebar : ''}`}>
					<div className={styles.MainData_upperPart}>
						<div className={styles.title_and_button}>
							<p className={styles.blocks_title}>Клиенты</p>
							{/* пермишен на добавление клиента(организации) */}
							{/* <RequireUI type={{ name: 'AddOrgs', terminal_id: currentTerminalId }}>
							<UniversalButton
								icon={<IconPlus />}
								color={'primary'}
								size={'large'}
								onClick={handleOpenSideBarForNewTerminalClient} //your function
								type={'with-border'}
							/>
						</RequireUI> */}
							<UniversalButton
								icon={<IconPlus />}
								color={'primary'}
								size={'large'}
								onClick={handleOpenSideBarForNewTerminalClient} //your function
								type={'with-border'}
							/>
						</div>
						<div className={styles.search_and_button}>
							<Search disabled={true} handleOpenModalPlug={handleOpenModalPlug} />
							{/* <UniversalButton
							icon={<IconFilter color={'primary_white'} />}
							color={'primary'}
							size={'large'}
							onClick={() => {
								console.log('filter');
							}}
							type={'outline'}
						/> */}
						</div>
					</div>
					<ContentUsers type={'terminalClients'} items={terminalClientList} />
				</div>
			</div>
		</>
	);
}
