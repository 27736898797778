import React, { useEffect, useRef, useState } from 'react';
import styles from './Modals.module.scss';
import { Modal, Preloader, Select, SelectLaptop, UniversalButton } from '../../../../../components';
import { IconCross, IconFileText } from '../../../../../components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { generateBackground, getInitials } from '../../../avatarsUtils';
import {
	clearNessesaryDataBeforeCreatingUserAccessToTerminal,
	setInputChange,
	setNewFullName,
	setNewGroup,
	setNewPassword,
	setNewUserName,
	setSelectedId,
	setSelectedOption,
	setUseFormUserStub,
} from '../../../../../store/slices/userSlice';
import MainInput from '../../../../../components/Inputs/MainInput/MainInput';
import { useMediaQuery } from 'react-responsive';
import { RequireUIFunc } from '../../../../../hoc/RequireUI';

export default function ModalUserInfo({
	handleCloseModal,
	handleOpenModal,
	handlePutUser,
	handleCreateUser,
	register,
	setFocus,
	setValue,
	errors,
	clearErrors,
	handleSubmit,
}) {
	const {
		currentUser,
		userGroups,
		selectedId,
		selectedOption,
		inputChange,
		newUserName,
		newFullName,
		newPassword,
		newGroup,
		modalType,
		getUser_pending,
		currentUserId,
		useFormUserStub,
	} = useSelector((state) => state.user);

	const { currentTerminalId } = useSelector((state) => state.terminalPage);

	const isDesktop = useMediaQuery({ minWidth: 743.9 });

	const [user_info, setUser_info] = useState(true);
	const [user_rools, setUser_rools] = useState(false);

	//! Нужно для правильной работы input в useForm при анмаунте компонента
	const [useFormPlug, setUseFormPlug] = useState('');

	const [showGroupModal, setShowGroupModal] = useState(false);

	const inputSelectRef = useRef(null);

	const dispatch = useDispatch();
	const handleOpenGroupModal = () => {
		setShowGroupModal(!showGroupModal);
	};

	const permByChangeEmpl = !RequireUIFunc({ name: 'ChangeEmpls', terminal_id: currentTerminalId });
	const permByCreateEmpl = !RequireUIFunc({ name: 'AddEmpls', terminal_id: currentTerminalId });

	//! Чтобы в инпутах отображались данные при переключении между вкладками, нужно сделать это
	useEffect(() => {
		setUseFormPlug(newUserName);
	}, [newFullName, newUserName, newGroup, newPassword, dispatch]);

	// useEffect(()=>{clearNessesaryDataBeforeCreatingUserAccessToTerminal()},[])

	return (
		<>
			{getUser_pending ? (
				<div className={styles.modal__preloader}>
					<Preloader />
				</div>
			) : (
				<div className={styles.card__userInfo}>
					<form
						onSubmit={
							modalType === 'update'
								? handleSubmit(handlePutUser)
								: modalType === 'create'
									? handleSubmit(handleCreateUser)
									: null
						}
					>
						<MainInput
							title={'Имя Фамилия'}
							errorTitle={'Введите фамилию и имя'}
							setInputChange={(bool) => dispatch(setInputChange(bool))}
							register={register}
							errors={errors}
							setFocus={setFocus}
							name="FLName"
							value={newFullName ? newFullName : ''}
							handleFieldChange={(e) => dispatch(setNewFullName(e.target.value))}
							setValue={setValue}
							rules={{
								required: newFullName ? null : 'Обязательное поле',
								minLength: {
									value: 10,
									message: 'Минимум 10 символов',
								},
							}}
							placeholder={'Константинов Константин'}
							disabled={modalType === 'create' ? permByCreateEmpl : permByChangeEmpl}
						/>
						{/* <div className={styles.card__userSelect}>
							<MainInput
								title={'Роль'}
								name="selectInput"
								errors={errors}
								setFocus={setFocus}
								register={register}
								setValue={setValue}
								setInputChange={(bool) => dispatch(setInputChange(bool))}
								handleFieldChange={(e) => dispatch(setNewGroup(e.target.value))}
								value={newGroup ? newGroup : ''}
								rules={{
									required: newGroup ? null : 'Обязательное поле',
								}}
								placeholder={'Выберите из списка'}
								modalDropList={showGroupModal}
								onClick={handleOpenGroupModal}
								disabled={Boolean(() => {
									return !RequireUIFunc({ name: 'ChangeEmpls', terminal_id: currentTerminalId });
								})}
							/>
							{!isDesktop ? (
								showGroupModal && (
									<Modal
										title={'Тип пользователя'}
										arrow
										children={
											<Select
												setValue={setValue}
												// (data) => {
												//   dispatch(setNewUserInfo({ property: "group", data: data }));
												// }
												oneUser={currentUser}
												setInputChange={(bool) => dispatch(setInputChange(bool))}
												options={userGroups}
												// handleFieldChange={(e)=>dispatch(setNewGroup(e.target.value))}
												onCloseModal={handleOpenGroupModal}
												selectedOption={currentUser?.group}
												setSelectedOption={(option) => dispatch(setSelectedOption(option))}
												setSelectedId={(optionId) => dispatch(setSelectedId(optionId))}
											/>
										}
										onClose={handleOpenGroupModal}
									/>
								)
							) : showGroupModal ? (
								<SelectLaptop
									options={userGroups}
									oneUser={currentUser}
									inputChange={inputChange}
									setInputChange={(bool) => dispatch(setInputChange(bool))}
									selectedOption={selectedOption}
									// handleFieldChange={(e)=>dispatch(setNewGroup(e.target.value))}
									setSelectedOption={(option_id) => dispatch(setSelectedOption(option_id))}
									setSelectedId={(id) => dispatch(setSelectedId(id))}
									setShowModal={setShowGroupModal}
									setValue={setValue}
								/>
							) : (
								''
							)}
						</div> */}

						<MainInput
							title={'Логин'}
							errorTitle={'Некорректный формат логина'}
							value={newUserName ? newUserName : ''}
							register={register}
							errors={errors}
							setFocus={setFocus}
							handleFieldChange={(e) => dispatch(setNewUserName(e.target.value))}
							setInputChange={(bool) => dispatch(setInputChange(bool))}
							setValue={setValue}
							name="login"
							rules={{
								required: newUserName ? null : 'Обязательное поле',
								minLength: {
									value: 2,
									message: 'Минимум 2 символа',
								},
							}}
							placeholder={'Введите логин'}
							disabled={modalType === 'create' ? permByCreateEmpl : permByChangeEmpl}
						/>
						<MainInput
							title={'Пароль'}
							errorTitle={'Некорректный формат пароля'}
							setInputChange={(bool) => dispatch(setInputChange(bool))}
							name="password"
							register={register}
							errors={errors}
							setFocus={setFocus}
							value={newPassword ? newPassword : ''}
							handleFieldChange={(e) => dispatch(setNewPassword(e.target.value))}
							setValue={setValue}
							rules={{
								required: 'Обязательное поле',
								minLength: {
									value: 6,
									message: 'Минимум 6 символов',
								},
								pattern: {
									value: /^[^\sа-яА-Я]{6,}$/,
									message: 'Латинские символы',
								},
							}}
							placeholder={'Введите пароль'}
							disabled={modalType === 'create' ? permByCreateEmpl : permByChangeEmpl}
						/>
					</form>
				</div>
			)}
		</>
	);
}
