import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import '../../../../../../assets/styles/Tables.scss';
import { MRT_EditActionButtons, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useDispatch } from 'react-redux';
import ModalEditContainers from './ModalEditContainers';
import { useSelector } from 'react-redux';
import { Preloader, Switcher, UniversalButton } from '../../../../../../components';
import { useMediaQuery } from 'react-responsive';
import {
	IconCross,
	IconEdit,
	IconEyeClose,
	IconFilter,
	IconMaxmize,
	IconMinimize,
	IconSearch,
	IconSettings,
} from '../../../../../../components/Icons';
import {
	getContainersList,
	setContainersFetching,
	setFilters,
	setOrdering,
	setGlobalSearch,
	getLines,
	putOneContainer,
	getTerminalList,
} from '../../../../../../store/slices/allContainersSlice';
import { Alert, Snackbar } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';
import { RequireUIFunc } from '../../../../../../hoc/RequireUI';
// Иконка календаря для фильтрации по датам
const IconCalendar = createSvgIcon(
	<svg width="14" height="14" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.3333 2.99984V4.33317H11.3333V2.99984H12C12.5523 2.99984 13 3.44755 13 3.99984V5.34359L3 5.34359V3.99984C3 3.44755 3.44772 2.99984 4 2.99984H4.66667V4.33317H5.66667V2.99984L10.3333 2.99984ZM3 6.34359L3 11.9998C3 12.5521 3.44772 12.9998 4 12.9998H12C12.5523 12.9998 13 12.5521 13 11.9998V6.34359L3 6.34359ZM4 1.99984H4.66667V0.666504H5.66667V1.99984L10.3333 1.99984V0.666504H11.3333V1.99984H12C13.1046 1.99984 14 2.89527 14 3.99984V11.9998C14 13.1044 13.1046 13.9998 12 13.9998H4C2.89543 13.9998 2 13.1044 2 11.9998V3.99984C2 2.89527 2.89543 1.99984 4 1.99984Z"
			fill="#ffffff"
		/>
	</svg>,
	'IconCalendar',
);

// иконки
const fontAwesomeIcons = {
	FullscreenIcon: () => <IconMaxmize size={'largeS'} color={'white'} />,
	FullscreenExitIcon: () => <IconMinimize size={'largeS'} color={'white'} />,
	ViewColumnIcon: () => <IconSettings size={'largeS'} color={'white'} />,
	FilterListIcon: () => <IconFilter size={'largeS'} color={'white'} />,
	FilterListOffIcon: () => <IconFilter size={'largeS'} color={'white'} />,
	SearchIcon: () => <IconSearch size={'largeS'} color={'white'} />,
	SearchOffIcon: () => <IconSearch size={'largeS'} color={'white'} />,
	ArrowDropDownIcon: () => <IconSearch size={'largeS'} color={'white'} />,
	ClearAllIcon: () => <IconSearch size={'largeS'} color={'white'} />,
	SortIcon: (props) => <IconSearch size={'largeS'} color={'white'} {...props} />,
	VisibilityOffIcon: () => <IconEyeClose size={'large'} color={'white'} />,
	RestartAltIcon: () => <IconSearch size={'largeS'} color={'white'} />,
};

const TableContainers = ({ columnVisibility, setColumnVisibility, rowSelection, setRowSelection }) => {
	const dispatch = useDispatch();
	// отслеживание ширины окна
	const isMobile = useMediaQuery({ maxWidth: 639.9 });
	const isMobilePreloader = useMediaQuery({ maxWidth: 679.9 });
	const {
		containersList,
		count,
		fetching,
		getContainersList_pending,
		firstLoad,
		lineList,
		terminalList,
		allStatuses,
		putOneContainer_success,
		putBulkContainers_success,
		putOneContainer_rejected,
		putBulkContainers_rejected,
	} = useSelector((state) => state.allContainers);

	// бесконечная загрузка
	const tableContainerRef = useRef(null);

	// прелоадер при бесконечном скролле
	const [isLoadingRows, setIsLoadingRows] = useState(false);

	const fetchMoreOnBottomReached = useCallback((containerRefElement) => {
		if (containerRefElement) {
			const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
			if (scrollTop + clientHeight + 2 >= scrollHeight && scrollTop != 0) {
				dispatch(setContainersFetching(true));
			} else {
				dispatch(setContainersFetching(false));
			}
		}
	}, []);

	useEffect(() => {
		fetchMoreOnBottomReached(tableContainerRef.current);
	}, [fetchMoreOnBottomReached]);

	// Получение списка контейнеров
	useEffect(() => {
		if (fetching) {
			dispatch(getContainersList());
		}
	}, [fetching]);

	// Прелоадер в футере таблицы при дозагрузке
	useEffect(() => {
		if (getContainersList_pending) {
			setIsLoadingRows(true);
		} else {
			setIsLoadingRows(false);
		}
	}, [getContainersList_pending]);

	// Получение линий контейнера и списка терминалов
	useEffect(() => {
		dispatch(getLines());
		dispatch(getTerminalList());
	}, []);

	// Вспомогательная функция по поиску по типам заявок
	function findOrder(array, key, value) {
		const foundObject = array.find((obj) => obj[key] === value);
		return foundObject ? foundObject : '';
	}

	// колонки
	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => row.status_display,
				id: 'status',
				header: 'Статус',
				size: 192,

				// редактирование
				editVariant: 'select',
				editSelectOptions: allStatuses['status']?.map((el) => el.name),
				muiEditTextFieldProps: {
					select: true,
				},

				// фильтрация
				muiFilterTextFieldProps: { placeholder: 'Выберите статус' },
				filterSelectOptions: allStatuses['status']?.map((el) => el.name),
				filterVariant: 'select',

				// сортировка от меньшего к большему по первому клику
				sortDescFirst: true,
			},
			{
				accessorFn: (row) => row.line_display,
				id: 'line',
				header: 'Линия',
				size: 232,
				editVariant: 'select',
				editSelectOptions: lineList?.map((el) => el.line_name),
				muiEditTextFieldProps: {
					select: true,
				},
				muiFilterTextFieldProps: { placeholder: 'Выберите линию' },
				filterSelectOptions: lineList?.map((el) => el.line_name),
				filterVariant: 'select',
				sortDescFirst: true,
			},
			{
				accessorFn: (row) => row.container_number,
				id: 'container_number',
				header: '№ контейнера',
				size: 230,
				enableHiding: false,
				muiFilterTextFieldProps: { placeholder: 'Введите №' },
				sortDescFirst: true,
				// Скрыть в меню
				visibleInShowHideMenu: false,
				enableEditing: false,
				Edit: () => null,
			},
			{
				accessorFn: (row) => row.size_display,
				id: 'size',
				header: 'Размер',
				size: 200,
				editVariant: 'select',
				editSelectOptions: allStatuses['size']?.map((el) => el.name),
				muiEditTextFieldProps: {
					select: true,
				},
				muiFilterTextFieldProps: { placeholder: 'Выберите размер' },
				filterSelectOptions: allStatuses['size']?.map((el) => el.name),
				filterVariant: 'select',
				sortDescFirst: true,
			},
			{
				accessorFn: (row) => row.type_display,
				id: 'type',
				header: 'Тип',
				size: 180,
				editVariant: 'select',
				editSelectOptions: allStatuses['type']?.map((el) => el.name),
				muiEditTextFieldProps: {
					select: true,
				},
				muiFilterTextFieldProps: { placeholder: 'Выберите тип' },
				filterSelectOptions: allStatuses['type']?.map((el) => el.name),
				filterVariant: 'select',
			},
			{
				accessorFn: (row) => row.client_display,
				id: 'org',
				header: 'Клиент',
				size: 280,
				enableEditing: false,
				Edit: () => null,
				muiFilterTextFieldProps: {
					placeholder: 'Введите название',
				},
			},
			{
				accessorFn: (row) => row.booking,
				id: 'booking',
				accessorKey: '',
				header: 'Букинг',
				size: 280,
				muiFilterTextFieldProps: {
					placeholder: 'Введите название',
				},
			},
			{
				accessorFn: (row) => (row.is_filled ? 'Да' : '-'),
				id: 'is_filled',
				header: 'Заполнен',
				size: 280,

				enableEditing: false,
				Edit: () => null,

				// фильтрация
				muiFilterTextFieldProps: { placeholder: 'Выберите' },
				filterSelectOptions: [
					{ label: 'Да', value: 'true' },
					{ label: 'Нет', value: 'false' },
				],
				filterVariant: 'select',
			},
			{
				accessorFn: (row) => (row.is_dangerous ? 'Да' : '-'),
				id: 'is_dangerous',
				header: 'Опасный груз',
				size: 280,

				enableEditing: false,
				Edit: () => null,

				// фильтрация
				muiFilterTextFieldProps: { placeholder: 'Выберите' },
				filterSelectOptions: [
					{ label: 'Да', value: 'true' },
					{ label: 'Нет', value: 'false' },
				],
				filterVariant: 'select',
			},
			{
				accessorFn: (row) => (row.is_damaged ? 'Да' : '-'),
				id: 'is_damaged',
				accessorKey: 'is_damaged',
				header: 'Поврежден',
				size: 280,

				enableEditing: false,
				Edit: () => null,

				// фильтрация
				muiFilterTextFieldProps: { placeholder: 'Выберите' },
				filterSelectOptions: [
					{ label: 'Да', value: 'true' },
					{ label: 'Нет', value: 'false' },
				],
				filterVariant: 'select',
			},
			{
				accessorFn: (row) => row.entrypass[0]?.terminal_display,
				id: 'terminal',
				header: 'Терминал',
				size: 230,

				enableEditing: false,
				Edit: () => null,

				muiFilterTextFieldProps: { placeholder: 'Выберите терминал' },
				filterSelectOptions: terminalList?.map((el) => el.name),
				filterVariant: 'select',
				sortDescFirst: true,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на ввоз').gatein_number,
				id: 'order_in',
				header: '№ Заявки на ввоз',
				size: 250,

				enableEditing: false,
				Edit: () => null,

				muiFilterTextFieldProps: { placeholder: 'Введите №' },
				sortDescFirst: true,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на ввоз').status_display,
				id: 'entrypass_in_status',
				header: 'Статус пропуска (ввоз)',
				size: 320,

				enableEditing: false,
				Edit: () => null,

				muiFilterTextFieldProps: { placeholder: 'Выберите статус' },
				filterSelectOptions: allStatuses['entrypass_in_status']?.map((el) => el.name),
				filterVariant: 'select',
				sortDescFirst: true,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на ввоз').vehicle_display,
				id: 'vehicle_in',
				header: 'Номер A/М (ввоз)',
				size: 230,

				enableEditing: false,
				Edit: () => null,

				muiFilterTextFieldProps: { placeholder: 'Введите №' },
				sortDescFirst: true,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на ввоз').vehicle_driver,
				id: 'driver_in',
				header: 'Водитель (ввоз)',
				size: 230,

				enableEditing: false,
				Edit: () => null,

				muiFilterTextFieldProps: { placeholder: 'Введите ФИО' },
				sortDescFirst: true,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на ввоз').checkin_at,
				id: 'checkin_at',
				header: 'Дата ввоза',
				size: 340,

				enableEditing: false,
				Edit: () => null,

				filterVariant: 'date-range',
			},
			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на вывоз').gateout_number,
				id: 'order_out',
				header: '№ Заявки на вывоз',
				size: 250,

				enableEditing: false,
				Edit: () => null,

				muiFilterTextFieldProps: { placeholder: 'Введите №' },
				sortDescFirst: true,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на вывоз').status_display,
				id: 'entrypass_out_status',
				header: 'Статус пропуска (вывоз)',
				size: 320,

				enableEditing: false,
				Edit: () => null,

				muiFilterTextFieldProps: { placeholder: 'Выберите статус' },
				filterSelectOptions: allStatuses['entrypass_out_status']?.map((el) => el.name),
				filterVariant: 'select',
				sortDescFirst: true,
			},
			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на вывоз').vehicle_display,
				id: 'vehicle_out',
				header: 'Номер А/М (вывоз)',
				size: 260,

				enableEditing: false,
				Edit: () => null,

				muiFilterTextFieldProps: { placeholder: 'Введите №' },
				sortDescFirst: true,
			},
			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на вывоз').vehicle_driver,
				id: 'driver_out',
				header: 'Водитель (вывоз)',
				size: 230,

				enableEditing: false,
				Edit: () => null,

				muiFilterTextFieldProps: { placeholder: 'Введите ФИО' },
				sortDescFirst: true,
			},
			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на вывоз').chekin_at,
				id: 'checkout_at',
				header: 'Дата вывоза',
				size: 340,

				enableEditing: false,
				Edit: () => null,

				filterVariant: 'date-range',
			},
		],
		[lineList, terminalList],
	);

	const [dataContainer, setDataContainer] = useState({});

	// отправка редактирования контейнера на сервер
	const handleSaveOneContainer = ({ values, row, table }) => {
		// Формирование requestData
		for (const key in values) {
			if (values.hasOwnProperty(key) && row.original.hasOwnProperty(key)) {
				if (key === 'size') {
					if (values['size'] !== row.original['size_display']) {
						dataContainer['size'] = allStatuses['size'][allStatuses['size'].findIndex((item) => item.name == values[key])].id;
					}
				} else if (key === 'type') {
					if (values['type'] !== row.original['type_display']) {
						dataContainer['type'] = allStatuses['type'][allStatuses['type'].findIndex((item) => item.name == values[key])].id;
					}
				} else if (key === 'line') {
					if (values['line'] !== row.original['line_display']) {
						dataContainer['line'] = lineList[lineList.findIndex((item) => item.line_name == values[key])].id;
					}
				} else if (key === 'status') {
					if (values['status'] !== row.original['status_display']) {
						dataContainer['status'] =
							allStatuses['status'][allStatuses['status'].findIndex((item) => item.name == values[key])].id;
					}
				} else if (key === 'container_number' || key === 'booking') {
					if (values[key] !== row.original[key]) {
						dataContainer[key] = values[key];
					}
				}
			}
		}

		if (Object.entries(dataContainer).length !== 0) {
			dispatch(
				putOneContainer({
					terminal_pk: row.original.entrypass[0].terminal,
					container_pk: row.original.id,
					requestData: dataContainer,
				}),
			);
			setOpenToast(true);
			setDataContainer({});
		}
		table.setEditingRow(null);
	};

	// Уведомление
	const [openToast, setOpenToast] = useState(false);

	// Логика закрытия тоста
	const handleClose = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenToast(false);
	};

	// выбор контейнеров
	const [selectContainers, setSelectContainers] = useState([]);

	// модальное окно для редактирования множества контейнеров
	const [showModalEditContainers, setShowModalEditContainers] = useState(false);
	const handleCloseModal = () => {
		setShowModalEditContainers(false);
	};

	// редактирование параметров на запросы
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState();
	const [sorting, setSorting] = useState([]);

	// Сортировка
	useEffect(() => {
		if (sorting) {
			dispatch(setOrdering(sorting));
			dispatch(setContainersFetching(true));
			setSelectContainers([]);
			setRowSelection({});
		}
	}, [sorting]);

	// Фильтрация в колонках
	useEffect(() => {
		if (columnFilters) {
			dispatch(setFilters(columnFilters));
			dispatch(setContainersFetching(true));
			setSelectContainers([]);
			setRowSelection({});
		}
	}, [columnFilters]);

	// Глобальный поиск
	useEffect(() => {
		dispatch(setGlobalSearch(globalFilter));
		dispatch(setContainersFetching(true));
		setSelectContainers([]);
		setRowSelection({});
	}, [globalFilter]);

	// функции для повержден и опасный груз
	const [isCheckedDamaged, setCheckedDamaged] = useState(false);
	const [isCheckedDangerous, setCheckedDangerous] = useState(false);
	const [isCheckedFilled, setCheckedFilled] = useState(false);

	const handleCheckedDamaged = (newChecked) => {
		setCheckedDamaged(newChecked);
		dataContainer['is_damaged'] = newChecked;
	};

	const handleCheckedDangerous = (newChecked) => {
		setCheckedDangerous(newChecked);
		dataContainer['is_dangerous'] = newChecked;
	};

	const handleCheckedFilled = (newChecked) => {
		setCheckedFilled(newChecked);
		dataContainer['is_filled'] = newChecked;
	};

	const table = useMaterialReactTable({
		data: containersList,
		columns,
		enableSorting: !getContainersList_pending,
		initialState: {
			showGlobalFilter: true,
			// порядок колонок
			columnOrder: [
				'mrt-row-select',
				'mrt-row-actions',
				'status',
				'line',
				'container_number',
				'size',
				'type',
				'org',
				'booking',
				'is_filled',
				'is_dangerous',
				'is_damaged',
				'terminal',
				'order_in',
				'entrypass_in_status',
				'vehicle_in',
				'driver_in',
				'checkin_at',
				'order_out',
				'entrypass_out_status',
				'vehicle_out',
				'driver_out',
				'checkout_at',
			],
		},

		// сортировка, фильтрация и поиск через сервер
		manualFiltering: true,
		manualSorting: true,
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		state: {
			rowSelection,
			columnFilters,
			globalFilter,
			sorting,
			columnVisibility,
		},

		// Календарь в фильтрации по датам
		muiFilterDatePickerProps: {
			slots: { openPickerIcon: IconCalendar },
		},

		// Отслеживание скрытия колонок
		onColumnVisibilityChange: setColumnVisibility,

		// фильтрация в колонках
		muiFilterTextFieldProps: {
			sx: {
				'> div': {
					fontFamily: 'Montserrat',
				},

				'> div::before': {
					borderBottom: '1px solid #E4DFFF !important',
				},

				'> div::after': {
					borderBottom: '1px solid #E4DFFF !important',
				},

				'> div div': {
					opacity: '1',
					color: '#F3EEFF',
					fontSize: '12px',
					fontWeight: '400',
					lineHeight: '20px',
				},

				input: {
					padding: '2px 0 4px',

					'&::placeholder': {
						opacity: '1',
						color: '#F3EEFF',
						fontSize: '12px',
						fontWeight: '400',
						fontFamily: 'Montserrat',
						lineHeight: '20px',
					},
				},

				'button svg': {
					color: '#AEAEB2',
				},

				'> div > svg': {
					color: '#0053DA',
				},
			},
		},

		// стиль контейнера таблицы
		muiTablePaperProps: {
			sx: {
				borderRadius: '24px',
			},
		},

		// отключить количество строк на странице
		enablePagination: false,

		// смена порядка колонок
		enableColumnOrdering: true,

		// русский язык
		localization: MRT_Localization_RU,

		// менять размер колонки кнопка в самой колонке
		enableColumnResizing: true,
		columnResizeMode: 'onChange',
		layoutMode: 'grid',

		// поисковая строка
		positionGlobalFilter: 'left',
		muiSearchTextFieldProps: {
			placeholder: 'Начните писать, например: NVNH349912, ECON, FESCO',
		},

		// изменение иконок
		icons: fontAwesomeIcons,

		// убрать кнопку меняющую ширину колонок в хедере
		enableDensityToggle: false,

		// выбор строк
		enableRowSelection: true,
		positionToolbarAlertBanner: 'bottom',
		onRowSelectionChange: setRowSelection,
		getRowId: (row) => row.id,

		// редактирование строк
		enableEditing: true,
		createDisplayMode: 'modal',
		editDisplayMode: 'modal',

		// хедер
		muiTableHeadCellProps: {
			sx: {
				backgroundColor: '#274b7e',
				color: '#fffbff',

				'div > span > span > svg.MuiTableSortLabel-icon': {
					opacity: '1 !important',
					color: '#fffbff !important',
					fill: '#fffbff !important',
				},

				'div span span.MuiTableSortLabel-root': {
					opacity: '1 !important',
				},
			},
		},

		muiTopToolbarProps: {
			sx: {
				backgroundColor: '#fffbff',
				minHeight: '60px',

				'div:first-of-type': {
					width: '100%',
					padding: '0px',
				},

				'div:first-of-type.MuiInputAdornment-root': {
					width: 'auto !important',
					marginLeft: '4px',

					button: {
						padding: '0px',
						color: '#ffffff',
					},
				},

				'div:nth-of-type(2)': {
					alignItems: 'center',
					height: '100%',
				},

				'div:nth-of-type(2) > span': {
					display: 'none',
				},

				'div:nth-of-type(2) > div': {
					gap: '8px',
				},

				'div:nth-of-type(2) > .MuiCollapse-root': {
					width: '100%',
					borderRadius: '8px',
					background: '#0053DA',
					border: 'none',
					padding: '2px 2px 2px 4px',

					svg: {
						background: '#0040A7',
						borderRadius: '8px',
						width: '30px',
						height: '28px',
						marginRight: '4px',
						padding: '6px',
					},

					'& input': {
						borderRadius: '8px',
						background: '#fffbff',
						padding: '6px 12px',
						color: '#000000',
						width: '100%',
						fontFamily: 'Montserrat',
						fontSize: '14px',
						lineHeight: '20px',
					},

					'& fieldset': {
						border: 'none',
					},
				},

				button: {
					borderRadius: '8px',
					background: '#0053DA',
					padding: '8px',

					i: {
						color: '#ffffff',
					},
				},

				'button:focus, button:active, button:hover': {
					background: '#0053DA',
				},
			},
		},

		// липкий хедер
		enableStickyHeader: true,

		// высота тела таблицы
		muiTableContainerProps: {
			ref: tableContainerRef,
			sx: {
				maxHeight: 'calc(100vh - 270px)',
				minHeight: 'calc(100vh - 270px)',

				'@media(min-width: 1279.9px)': {
					maxHeight: 'calc(100vh - 230px)',
					minHeight: 'calc(100vh - 230px)',
				},
			},

			onScroll: (event) => fetchMoreOnBottomReached(event.target),
		},

		// модальное окно
		renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
			<div className="modalEditСontainers">
				<div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '4px 16px' }}>
						<UniversalButton
							icon={<IconCross size={'large'} color={'black'} />}
							onClick={() => {
								table.setEditingRow(null);
							}}
							size={'large'}
							typeButton={'button'}
						/>
					</div>
					<div className="modalEdit">
						<div className="modalEdit__header">
							<div className="modalEdit__header_info">
								<p>Контейнер №{row.original.container_number}</p>
								<span>Клиент: {row.original.client_display}</span>
							</div>
						</div>
						<div className="modalEdit__body">
							<div className="modalEdit__body_inputs">{internalEditComponents}</div>
							<div className="modalEdit__body_switchers">
								<div className="switchers__item">
									Заполненный
									<Switcher onChecked={handleCheckedFilled} externalChecked={row.original.is_filled} />
								</div>
								<div className="switchers__item">
									Опасный
									<Switcher onChecked={handleCheckedDangerous} externalChecked={row.original.is_dangerous} />
								</div>
								<div className="switchers__item">
									Поврежден
									<Switcher onChecked={handleCheckedDamaged} externalChecked={row.original.is_damaged} />
								</div>
							</div>
						</div>
						<div className="modalEdit__footer">
							<MRT_EditActionButtons variant="text" table={table} row={row} />
						</div>
					</div>
				</div>
			</div>
		),

		// функция для сохранения изменений
		onEditingRowSave: handleSaveOneContainer,

		// футер таблицы
		muiBottomToolbarProps: {
			sx: {
				minHeight: 'none',
				background: '#274B7E',

				'div:nth-of-type(2)': {
					padding: '0px',
				},
			},
		},

		renderBottomToolbarCustomActions: ({ table, row }) => (
			<div className="table__footer">
				<UniversalButton
					label={'Изменить'}
					color={'succesful'}
					disabled={!RequireUIFunc({ name: 'ChangeContainers' }) || Object.keys(rowSelection).length < 1}
					onClick={() => {
						if (Object.keys(rowSelection).length > 0) {
							if (Object.keys(rowSelection).length === 1) {
								table.setEditingRow(table.getSelectedRowModel().rows[0]);
							} else {
								setShowModalEditContainers(true);
								const selectedRowsModel = table.getSelectedRowModel();

								if (selectedRowsModel && selectedRowsModel.rows) {
									setSelectContainers(() => {
										const uniqueValues = selectedRowsModel.rows.reduce((acc, el) => {
											const originalValue = el.original;
											acc.push(originalValue);
											return acc;
										}, []);

										return uniqueValues;
									});
								}
							}
						}
					}}
					size={'medium'}
					typeButton={'button'}
				/>
				{count === 0 ? (
					<div>Нет результатов</div>
				) : isMobilePreloader ? (
					<div className="table__footer_preloader">
						{isLoadingRows ? <Preloader small={true} /> : `Загружено ${containersList?.length} из ${count}`}
					</div>
				) : (
					<div className="table__footer_preloader">
						{isLoadingRows && <Preloader small={true} />}
						Загружено {containersList?.length} из {count}
					</div>
				)}
			</div>
		),

		// сообщение сколько выбрано строк
		muiToolbarAlertBannerProps: {
			sx: {
				position: 'relative',
				'> div': {
					position: 'absolute',
					left: '110px',
					top: '-1px',
					color: '#fffbff',

					button: {
						display: 'none',
					},
				},

				'@media(max-width: 550px)': {
					'> div': {
						top: '12px',
					},

					'> div > div': {
						padding: '8px',
						fontSize: '12px',
					},
				},
			},
		},
	});

	return (
		<>
			{firstLoad ? (
				<>
					{(putOneContainer_success || putBulkContainers_success) && (
						<Snackbar
							open={openToast}
							onClose={handleClose}
							autoHideDuration={3000}
							anchorOrigin={isMobile ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'left' }}
						>
							<Alert severity="success" sx={{ width: '100%' }}>
								Данные контейнера(ов) успешно обновлены
							</Alert>
						</Snackbar>
					)}

					{(putOneContainer_rejected || putBulkContainers_rejected) && (
						<Snackbar
							open={openToast}
							onClose={handleClose}
							autoHideDuration={3000}
							anchorOrigin={isMobile ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'left' }}
						>
							<Alert severity="error" sx={{ width: '100%' }}>
								Данные контейнера(ов) не обновлены
							</Alert>
						</Snackbar>
					)}

					{showModalEditContainers ? (
						<div className="modal__wrapper">
							<div className="modalEditСontainers__stack">
								<div className="modalEditСontainers">
									<div className="modalEditСontainers__block">
										<div>
											<UniversalButton
												icon={<IconCross size={'large'} color={'black'} />}
												onClick={handleCloseModal}
												size={'large'}
												typeButton={'button'}
											/>
										</div>

										<ModalEditContainers
											handleCloseModal={handleCloseModal}
											selectContainers={selectContainers}
											allLines={lineList}
											allSizes={allStatuses['size']}
											allTypes={allStatuses['type']}
											allStatuses={allStatuses['status']}
											setOpenToast={setOpenToast}
										/>
									</div>
								</div>
							</div>
						</div>
					) : null}
					<div className="table__container tableContainers">
						<MaterialReactTable table={table} />
					</div>
				</>
			) : (
				<Preloader bg={'true'} />
			)}
		</>
	);
};

export default TableContainers;
