import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';
// import publicFormReducer from './reducers/publicForm';
// import registrationReducer from './reducers/registrationUser';
import groupsUsersReducer from './reducers/groupsUsers';
import oneUserReducer from './reducers/erp';
// import permissionsListReducer from './reducers/permissions';
import containersReducer from './reducers/clientContainer';
import linesContainerReducer from './reducers/linesContainer';
// RTK Slices
import vehicleReducer from './slices/vehicleSlice';
import publicFormReducer from './slices/publicFormSlice';
import orderReducer from './slices/orderSlice';
import permissionsReducer from './slices/permissionsSlice';
import erpReducer from './slices/erpSlice';
import orderPageReducer from './slices/orderPageSlice';
import containerAcceptanceReducer from './slices/containerAcceptanceSlice'
import registrationReducer from './slices/registrationSlice';
import terminalPageReducer from './slices/terminalPageSlice';
import loginReducer from './slices/loginSlice';
import userReducer from './slices/userSlice';
import clientReducer from './slices/clientSlice';
import sideBarReducer from './slices/sideBarSlice';
import groupReducer from './slices/groupSlice';
import meReducer from './slices/meSlice';
import confirmationWindowReducer from './slices/confirmationWindowSlice';
import allContainersReducer from './slices/allContainersSlice';
import entriesSliceReducer from './slices/entriesSlice';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore(
	{
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}),
		reducer: {
			groupsUsers: groupsUsersReducer,
			userOne: oneUserReducer,
			// permList: permissionsListReducer,
			containers: containersReducer,
			linesContainer: linesContainerReducer,
			//! RTK Slices
			publicForm: publicFormReducer,
			vehicle: vehicleReducer,
			order: orderReducer,
			containerAccept: containerAcceptanceReducer,
			orderPage: orderPageReducer,
			registration: registrationReducer,
			login: loginReducer,
			terminalPage: terminalPageReducer,
			user: userReducer,
			client: clientReducer,
			permissions: permissionsReducer,
			sideBar: sideBarReducer,
			group: groupReducer,
			me: meReducer,
			confirmationWindow: confirmationWindowReducer,
			allContainers: allContainersReducer,
			entries: entriesSliceReducer,
			// erp: erpReducer
		},
	},

	// composeEnhancers(applyMiddleware(thunk))
);

export default store;
