import styles from '../AccountEmpl.module.scss';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useRef, useState } from 'react';
import LayoutClients from '../components/Layouts/LayoutClients';
import { Slider } from '../../../../components';
import Header from '../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearClientDataWhileUnmounting,
	getClients,
	setClientGroup,
	setClientList,
	setClientModalActive,
	setClientNextNull,
	setClientUrl,
	setClientsFetching,
	setClientsUrlAfterClientGroupChange,
	setCurrentClient,
	setModalType,
} from '../../../../store/slices/clientSlice';
import UniversalButton from '../../../../components/Buttons/UniversalButton/UniversalButton';
import { IconFileText } from '../../../../components/Icons';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../store/slices/sideBarSlice';

import RequireUI from '../../../../hoc/RequireUI';

// function AccountEmplClients({ userPermissions }) {
// 	const dispatch = useDispatch();
// 	const { currentTerminalId } = useSelector((state) => state.terminalPage);

// 	const { fetching, clientList, clientGroup, url } = useSelector((state) => state.client);

// 	const [searchValue, setSearchValue] = useState('');

// 	//Номер выбранного слайда
// 	const [sliderCont, setSliderCont] = useState('0');

// 	// отслеживание ширины окна
// 	const isDesktop = useMediaQuery({ minWidth: 1279.9 });

// 	useEffect(() => {
// 		dispatch(getClients());
// 	}, [dispatch, fetching, url]);

// 	const scrollHandler = (e) => {
// 		if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100) {
// 			dispatch(setClientsFetching(true));
// 		} else {
// 			dispatch(setClientsFetching(false));
// 		}
// 	};

// 	useEffect(() => {
// 		const scrollContainer = document.getElementById('content__users');
// 		scrollContainer.addEventListener('scroll', scrollHandler);
// 		return function () {
// 			scrollContainer.removeEventListener('scroll', scrollHandler);
// 		};
// 	}, []);

// 	useEffect(() => {
// 		dispatch(setClientModalActive(false));
// 		let params = clientGroup == '0' ? '' : `&is_manager_confirmed=${clientGroup}`;
// 		dispatch(setClientsUrlAfterClientGroupChange(`/?limit=30&offset=0&user_type=2${params}`));
// 		dispatch(setClientList([]));
// 		dispatch(setClientNextNull());
// 		dispatch(setClientsFetching(true));
// 	}, [clientGroup, dispatch]);

import useCountItem from '../../../../hooks/useCountItem';
import ModalClient from '../components/Modals/ModalsCllient/ModalClient';
import { useUpdateEffect } from '../../../../hooks/useUpdateEffect';

function AccountEmplClients({ userPermissions }) {
	const dispatch = useDispatch();

	const { fetching, clientList, clientGroup, url, next, getClients_pending } = useSelector((state) => state.client);
	const { currentTerminalId } = useSelector((state) => state.terminalPage);
	const [searchValue, setSearchValue] = useState('');

	//Номер выбранного слайда
	const [sliderCont, setSliderCont] = useState('0');

	// отслеживание ширины окна
	const isDesktop = useMediaQuery({ minWidth: 1279.9 });
	const countItem = useCountItem();

	useUpdateEffect(() => {
		const numberCLientGroup = Number(clientGroup);
		dispatch(getClients({ is_confirmed: numberCLientGroup }));
	}, [clientGroup]);

	useEffect(() => {
		dispatch(setClientUrl(10));
	}, [countItem]);

	useEffect(() => {
		return () => dispatch(clearClientDataWhileUnmounting());
	}, []);

	useUpdateEffect(() => {
		if (countItem > 0) {
			dispatch(setClientModalActive(false));
			dispatch(setClientList([]));
			dispatch(setClientNextNull());
			let params = Number(clientGroup) ? `&is_manager_confirmed=${clientGroup}` : '';
			dispatch(setClientsUrlAfterClientGroupChange(`?type=2&limit=${10}&offset=0${params}`));
			dispatch(setClientsFetching(true));
		}
	}, [clientGroup, dispatch]);

	const handleOpenModalForNewClient = () => {
		dispatch(setCurrentElementForSideBar(<ModalClient type={'clients'} />));
		dispatch(setSideBarActive(true));
		dispatch(
			setCurrentClient({
				contract_number: '',
				organization_address: '',
				organization_name: '',
				contact_email: '',
				is_manager_confirmed: '',
			}),
		);
		dispatch(setModalType('create'));
		// TODO
		// dispatch(getUserGroups());
	};

	const typesUser = [
		{ name: 'Одобренные', id: 1 },
		{ name: 'Ожидают одобрения', id: 2 },
		{ name: 'Отклоненные', id: 3 },
	];

	return (
		<>
			<div className={styles.account__wrap}>
				<Header
					text={'Клиенты'}
					btnDesktop={
						//  пермишен на добавление клиента(организации)
						// <RequireUI type={{ name: 'AddOrgs', terminal_id: currentTerminalId }}>
						// 	<UniversalButton
						// 		icon={<IconFileText color={'primary_white'} />}
						// 		color={'primary'}
						// 		size={'medium'}
						// 		label={'Добавить клиента'}
						// 		onClick={handleOpenModalForNewClient}
						// 		type={'outline'}
						// 	/>
						// </RequireUI>
						<UniversalButton
							icon={<IconFileText color={'primary_white'} />}
							color={'primary'}
							size={'medium'}
							label={'Добавить клиента'}
							onClick={handleOpenModalForNewClient}
							type={'outline'}
						/>
					}
				/>
				<div className={styles.account__container}>
					{/* <div className={styles.account__slider}>
						<Slider
							// setSliderCont={setSliderCont}
							allName={'Все клиенты'}
							data={typesUser}
							user_group={clientGroup}
							setUser_group={(e) => dispatch(setClientGroup(e))}
							isDisabled={getClients_pending}
						/>
					</div> */}
					<div className={styles.account__stack}>
						<LayoutClients
							// users={clientList}
							searchValue={searchValue}
							setSearchValue={setSearchValue}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default AccountEmplClients;
