import { useEffect, useState } from 'react';
import styles from './ModalPlug.module.scss';
import UniversalButton from '../Buttons/UniversalButton/UniversalButton';
import { IconCross } from '../Icons';
import { useForm } from 'react-hook-form';
import MainInput from '../Inputs/MainInput/MainInput';
import CheckBox from '../CheckBox/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { contactUs, setInitialContactUsStatus } from '../../store/slices/publicFormSlice';
import Notification from '../Notification/Notification';

function ModalPlug({ isOpen, onClose, fullScreen, page }) {
	const dispatch = useDispatch();
	const { contactUs_success, contactUs_error } = useSelector((state) => state.publicForm);
	const {
		register,
		setValue,
		handleSubmit,
		setFocus,
		formState: { errors },
	} = useForm({});

	// уведомления
	const [isActive, setIsActive] = useState(false);
	const [text, setText] = useState('');
	const [descr, setDescr] = useState('');
	const [status, setStatus] = useState('');
	const onCloseNotification = () => {
		setIsActive(false);
	};

	const [phoneNumberPlug, setPhoneNumberPlug] = useState('');
	const handleFieldPhoneNumberPlugChange = (e) => {
		setPhoneNumberPlug(e.target.value);
	};

	const [emailPlug, setEmailPlug] = useState('');
	const handleFieldEmailPlugChange = (e) => {
		setEmailPlug(e.target.value);
	};

	const onSubmitModal = () => {
		dispatch(
			contactUs({
				requestData: {
					email: emailPlug == '' ? null : emailPlug,
					phone: phoneNumberPlug == '' ? null : phoneNumberPlug,
					page: page == '' ? null : page,
				},
			}),
		);
	};

	useEffect(() => {
		if (contactUs_success) {
			setPhoneNumberPlug('');
			setEmailPlug('');
			setCheckPolicy(false);
			setIsActive(true);
			setText('Сообщение отправлено');
			setDescr('Мы свяжемся с Вами в ближайшее время!');
			setStatus('success');
		} else if (contactUs_error) {
			setIsActive(true);
			setText('Сообщение не отправлено');
			setDescr('Попробуйте еще раз');
			setStatus('error');
		}
		dispatch(setInitialContactUsStatus());
	}, [contactUs_success, contactUs_error]);

	const [checkPolicy, setCheckPolicy] = useState(false);
	const switchCheckPolicy = () => {
		setCheckPolicy(!checkPolicy);
	};

	if (!isOpen) return null;

	return (
		<>
			<div className={fullScreen ? styles.modalOverlay : styles.container} onClick={fullScreen ? onClose : null}>
				<div className={fullScreen ? styles.fullScreen : styles.modalWrap}>
					<div
						className={`${fullScreen ? styles.modalContainerEnd : styles.modalContainer} 
						${page === 'Терминалы-контейнеры' && styles.backContainers}
						${page === 'Терминалы-заявки' && styles.backOrders}
						${page === 'Терминалы-аналитика' && styles.backAnalytic}`}
						onClick={(e) => e.stopPropagation()}
					>
						{fullScreen ? (
							<UniversalButton
								icon={<IconCross size={'large'} color={'black'} />}
								onClick={onClose}
								size={'large'}
								typeButton={'button'}
							/>
						) : null}
						<div className={styles.modal__content}>
							<div className={styles.problem__notification}>
								<Notification
									text={text}
									descr={descr}
									onClose={onCloseNotification}
									isActive={isActive}
									isStatus={status}
								/>
							</div>
							<form onSubmit={handleSubmit(onSubmitModal)} className={styles.modal__form}>
								<h3>Функционал Демо ограничен</h3>
								<p>
									Чтобы получить консультацию по полным возможностям TOS Core, оставьте заявку и наши специлисты свяжутся
									с Вами
								</p>
								<MainInput
									title={'Номер телефона'}
									errorTitle={'Некорректный номер телефона'}
									name="phoneNumberPlug"
									register={register}
									value={phoneNumberPlug ? phoneNumberPlug : ''}
									handleFieldChange={handleFieldPhoneNumberPlugChange}
									setValue={setValue}
									setFocus={setFocus}
									errors={errors}
									mask={'+7 (999)-999-99-99'}
									rules={{
										pattern: {
											value: /^(\+\d{1,2}\s?)?(\(\d{1,4}\)|\d{1,4})([\s.-]?\d{1,12})+$/,
											message: 'Введите номер телефона в формате +7 (999)-999-99-99',
										},
									}}
									placeholder={'+7 (999)-999-99-99'}
								/>
								<MainInput
									title={'Эл. почта'}
									errorTitle={'Некорректный формат эл. почты'}
									name="emailPlug"
									register={register}
									value={emailPlug ? emailPlug : ''}
									handleFieldChange={handleFieldEmailPlugChange}
									setValue={setValue}
									setFocus={setFocus}
									errors={errors}
									rules={{
										pattern: {
											value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											message: 'Пример: ivan.petrov@mail.ru',
										},
									}}
									placeholder={'ivan.petrov@mail.ru'}
								/>
								<div className={styles.modal__btn}>
									<div className={styles.modal__privacy}>
										<CheckBox typeCheck={checkPolicy} type={2} onClick={switchCheckPolicy} />
										<p>
											Я принимаю{' '}
											<a href="/policy" target="_blank" rel="noopener noreferrer">
												политику обработки персональных данных
											</a>
										</p>
									</div>
									<UniversalButton
										label={'Отправить'}
										color={'primary'}
										size={'large'}
										typeButton={'submit'}
										disabled={!(checkPolicy && (phoneNumberPlug.length > 17 || emailPlug.length > 4))}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ModalPlug;
