import React, { useEffect, useState } from 'react';
import styles from './Modals.module.scss';
import { getInitials, generateBackground } from '../../../avatarsUtils';
import { getOneUserApi, postClient, putClientAction } from '../../../../../store/actions/erp';
import StatusBtn from './components/StatusBtn';
import {
    IconCross,
    IconTrash,
} from '../../../../../components/Icons';
import { Form, Notification, UniversalButton } from '../../../../../components';
import MainInput from "../../../../../components/Inputs/MainInput/MainInput";
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser } from '../../../../../store/actions/erp';
import ChangeBtn from './components/ChangeBtn';
import { getCurrentUser } from '../../../../../store/selectors/erpSelector';


function ModalTransporter({
    setUsers,
    handleCloseModal,
    setSliderCont,
    corName,
    sliderCont,
    corAdress,
    mail,
    clientStatus,
    contrNum,
    clientId,
    setCorName,
    setMail,
    setCorAdress,
    setContrNum,
    client
}) {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('')
    const [urName, setUrName] = useState('');

    // удаление клиента
    const handleDeleteUser = async () => {
        try {
            await dispatch(deleteUser(clientId));
            setUsers((prevUsers) =>
                prevUsers.filter((user) => user.id !== clientId)
            );
            handleCloseModal();
        } catch (error) {

        }
    };

    // верификация клиента
    const updateDataById = (id, updatedValues) => {
        let newResData = { is_manager_confirmed: updatedValues.is_manager_confirmed === 1 ? 'Одобрен' : 'Отклонен' };
        setUsers((prevData) => prevData.map((user) => (user.id === id ? { ...user, ...newResData } : user)));
    };

    // запрос на смену верификации клиента
    const handlePostClient = async ({ status }) => {
        try {
            let requestData = {
                is_manager_confirmed: status === 'принять' ? 1 : status === 'отклонить' ? 3 : undefined,
            };
            await dispatch(postClient(clientId, requestData));
            updateDataById(clientId, requestData);
            setSliderCont(String(sliderCont))
            handleCloseModal();
        } catch (error) {

        }
    };

    const [inputChange, setInputChange] = useState(false);
    const oneUser = useSelector(getCurrentUser);
    // запрос на изменение клиента
    const handlePutClient = async () => {

        try {
            let requestData = {}

            if (corName !== '' && mail !== '' && corAdress !== '' && contrNum !== '') {
                if (client.corporate_name != corName) {
                    requestData['corporate_name'] = corName;
                }
                if (client.email != mail) {
                    requestData['email'] = mail;
                }
                if (client.corporate_address != corAdress) {
                    requestData['corporate_address'] = corAdress;
                }
                if (client.contract_data) {
                    if (client.contract_data.contract_number != contrNum) {
                        requestData['contract_number'] = contrNum;
                    }
                } else requestData['contract_number'] = contrNum;
                await dispatch(putClientAction(clientId, requestData));
                await dispatch(getOneUserApi(clientId));
                setDisabledBtn(false)
            } else {
                setIsActive(true);
            }
        } catch (error) { }
    };


    // сброс изменений
    const handlePutBaseClient = () => {
        setCorName(client?.corporate_name);
        setMail(client?.email);
        setCorAdress(client?.corporate_address);
        setContrNum(client?.contract_data.contract_number);
    };

    const [disabledBtn, setDisabledBtn] = useState(true)

    // если нет номера контракта кнопки принять и отклонить недоступны
    useEffect(() => {
        if (client?.contract_data) {
            setDisabledBtn(false)
        } else {
            setDisabledBtn(true)
        }
    }, [client]);

    const onClose = () => {
        setIsActive(false);
    };

    const [isActive, setIsActive] = useState(false);

    return (
        <>
            <Notification
                text={"Ошибка"}
                descr={"Поля не должны быть пустыми"}
                onClose={onClose}
                isActive={isActive}
                isStatus={"error"}
            />
            <div className={styles.modal__card}>

                <div className={styles.MainCardInfo}>

                    <div className={styles.card__user}>
                        <div className={styles.card__userName}>

                            <div
                                className={styles.card__img}
                                style={{ background: generateBackground(corName) }}
                            >
                                {getInitials(corName)}
                            </div>
                            <div className={styles.card__info}>
                                <span>{corName}</span>
                                <p>Номер договора: {contrNum}</p>
                            </div>
                        </div>
                        <div>
                            <UniversalButton
                                icon={<IconTrash color={'primary_white'} size={'large'} />}
                                color={"error"}
                                size={"medium"}
                                onClick={handleDeleteUser}
                                type={"with-border"}
                            />
                        </div>
                    </div>
                    <div className={styles.card__userInfo}>
                        <Form>
                            <MainInput
                                title={'Наименование юр. лица'}
                                errorTitle={'Некорректное наименование'}
                                name="urName"
                                value={urName}
                                setValue={setUrName}
                                rules={{
                                    required: "Обязательное поле",
                                    minLength: {
                                        value: 5,
                                        message: "Минимум 5 символов",
                                    },
                                }}
                                placeholder={'ПАО "Аэрофлот'}
                            />
                            <MainInput
                                title={'Электронная почта'}
                                errorTitle={'Некорректный формат почты'}
                                name="email"
                                value={email}
                                setValue={setEmail}
                                rules={{
                                    required: "Обязательное поле",
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Например: ivan.petrov@mail.ru",
                                    },
                                }}
                                placeholder={'ivan.petrov@mail.ru'}
                            />
                            <MainInput
                                title={'Юридический адрес'}
                                errorTitle={'Некорректный адрес'}
                                name="urAdress"
                                value={corAdress}
                                setValue={setCorAdress}
                                rules={{
                                    required: "Обязательное поле",
                                    minLength: {
                                        value: 5,
                                        message: "Минимум 5 символов",
                                    },
                                }}
                                placeholder={'г. Москва, ул. Ленина, д.43, к.1'}
                            />
                        </Form>
                    </div>
                </div>
                <div>
                    <ChangeBtn inputChange={inputChange} handlePutBaseUser={handlePutBaseClient} setInputChange={setInputChange} handlePutUser={handlePutClient} />
                    <div className={styles.card__btns}>
                        <UniversalButton
                            icon={<IconCross color={'core-state-error'} size={'large'} />}
                            color={"error"}
                            size={"medium"}
                            onClick={handleCloseModal}
                            type={"with-border"}
                        />
                        {
                            clientStatus === 'Одобрен' ? <StatusBtn status={'отклонить'} buttonText={'Отклонить'} bgColor={'error'} handlePostClient={handlePostClient} /> :
                                clientStatus === 'Отклонен' ? <StatusBtn status={'принять'} buttonText={'Принять'} bgColor={'succesful'} handlePostClient={handlePostClient} /> :
                                    <>
                                        <StatusBtn status={'отклонить'} buttonText={'Отклонить'} bgColor={'error'} handlePostClient={handlePostClient} />
                                        <StatusBtn status={'принять'} buttonText={'Принять'} bgColor={'succesful'} handlePostClient={handlePostClient} />
                                    </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalTransporter;