import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	addContainersInOrder,
	getOrgContainers,
	setChoosenContainerForChangeCancelVariant,
	setSaveLinkContainersButtonActive,
	updateOrganizationContainerList,
} from '../../../../store/slices/orderSlice';
import Search from '../../../Search/Search';
import { IconContainerCar, IconDisketa, IconFilter, IconSort } from '../../../Icons';
import UniversalButton from '../../../Buttons/UniversalButton/UniversalButton';

import styles from './style.module.scss';
import { BtnIcon } from '../../../Buttons';
import ConfirmationWindow from '../../../ConfirmationWindow/ConfirmationWindow';
import ContainersItem from '../../Containers/ContainersItem';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../store/slices/sideBarSlice';
import ChangeContainerForm from '../../ChangeContainerForm/ChangeContainerForm';
export default function AvailiableContainers() {
	const dispatch = useDispatch();
	const { terminalId } = useParams();
	const { currentOrderData, organizationContainerList, currentOrderContainerList, containersToAddInOrder, getOrgContainers_success } =
		useSelector((state) => state.order);
	// const [actualContainersList, setActualContainersList] = useState([]);

	// useEffect(() => {
	// 	setActualContainersList(organizationContainerList);
	// }, [organizationContainerList]);

	const selectAllCards = () => {
		console.log('trutti');
	};
	const handleDelete = () => {
		console.log('handleDelete');
	};
	const handleChange = (e, container) => {
		console.log('EXPORT CONTAINERLIST -> AVAILIABLE CONTAINERS CHANGE CONTAINER', container);
		dispatch(setSideBarActive(true));
		dispatch(setCurrentElementForSideBar(<ChangeContainerForm type={'export_after_organization_containers'} />));
		dispatch(setChoosenContainerForChangeCancelVariant(container));
		// organizationContainerList
	};

	const handleAddToOrder = (container) => {
		dispatch(setSaveLinkContainersButtonActive(true));
		dispatch(addContainersInOrder({ type: 'export_after', data: container }));
	};

	// const uniqueOrganizationContainerList = organizationContainerList.filter((container) => {
	// 	container.id !== currentOrderContainerList.every((el) => el.id);
	// });

	// const currentOrderContainerListIds = currentOrderContainerList.map(({ id }) => id);
	// const uniqueOrganizationContainerList = organizationContainerList.filter((container) => {
	// 	if (!uniqueOrganizationContainerListIds.includes(container.id)) {
	// 		return container;
	// 	}
	// });

	//! Проверка на уникальность и отстуствие повторений добавленных к заявке контейнеров в массиве контейнеров организаций
	useEffect(() => {
		dispatch(updateOrganizationContainerList());
	}, [currentOrderContainerList, containersToAddInOrder, getOrgContainers_success]);

	useEffect(() => {
		console.log(organizationContainerList, 'ЛИСТИК');
	}, [organizationContainerList]);
	return (
		<div className={styles.main_block}>
			<div className={styles.main_block_topLine}>
				<p className={styles.main_block_title}>Контейнеры</p>
				{/* {topLineBtn || <></>} */}
			</div>
			{/* <div className={styles.containers__search}>
				<Search />
			 {[<IconSort size="large" color="primary_white" />, <IconFilter size="large" color="primary_white" />]?.map(
					(icon, index) => (
						<BtnIcon key={index} type="button" icon={icon} size="p-6" />
					),
				)} 
			</div> */}
			<div className={styles.main_block_container_list}>
				{/* <p>INS</p> */}
				{/* {confWindow || removeAllCard ? (
					<ConfirmationWindow
						titleText={`Вы уверены, что хотите удалить ${removeAllCard ? 'все контейнеры?' : 'контейнер №' + containerEL.container_number}`}
						acceptBtnText={'Да, удалить'}
						acceptBtnClick={handleRemoveCard}
						rejectBtnText={'Отменить'}
						rejectBtnClick={() => (removeAllCard ? setRemoveAllCard(false) : setConfWindow(false))}
					/>
				) : (
					<></>
				)} */}
				<div className={styles.containers_list}>
					{organizationContainerList.map((item, index) => (
						<ContainersItem
							type={'notAdded'}
							key={index}
							el={item}
							handleDelete={handleDelete}
							handleChange={handleChange}
							handleAddToOrder={handleAddToOrder}
							// addContainer
							// setContainerEL={setContainerEL}
							// active

							// setConfWindow={setConfWindow}
							// data={data}
							// setData={setData}
							// setData1={setData1}
							// setData2={setData}
						/>
					))}
				</div>
				<div className={styles.containers_bottom_buttons}>
					{/* <div>
						<UniversalButton
							icon={<IconDisketa size={'medium'} color={'core-state-success'} />}
							size={'small'}
							color={'succesful'}
							type={'with-border'}
						/>
						<UniversalButton
							icon={<IconContainerCar size={'medium'} color={'white'} />}
							size={'small'}
							label={'0'}
							color={'primary'}
						/>
					</div> */}
				</div>
			</div>
		</div>
	);
}
