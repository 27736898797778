import React, { useEffect } from 'react';
import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import Search from '../../Search/Search';
import { BtnIcon } from '../../Buttons';
import { IconFilter, IconSort } from '../../Icons';
import style from './Terminals.module.scss';
import TerminalSmallCardOrder from '../TerminalSmallCardOrder/TerminalSmallCardOrder';
import { getTerminalList } from '../../../store/slices/terminalPageSlice';
import { useDispatch, useSelector } from 'react-redux';

function Terminals({ activeTerminal, setActiveTerminal }) {
	const dispatch = useDispatch();
	const { terminalList } = useSelector((state) => state.terminalPage);

	useEffect(() => {
		dispatch(getTerminalList());
	}, []);

	return (
		<div className={style.block_bg}>
			{/* Верхняя строка */}
			<div className={style.top_title_line}>
				<p>Терминалы</p>
			</div>

			{/* Строка поиска и иконки */}
			{/* <div className={style.search_line}>
				<Search />
				 {[<IconSort size="large" color="primary_white" />, <IconFilter size="large" color="primary_white" />].map(
					(icon, index) => (
						<BtnIcon key={index} type="button" icon={icon} size="p-6" />
					),
				)} 
			</div> */}

			{/* Список терминалов */}
			<div className={style.terminal_list}>
				{terminalList.map((el, index) => (
					//* Старая карточка терминала(большая) - // <TerminalCardOrder key={index} />
					<TerminalSmallCardOrder el={el} active={activeTerminal} setActive={setActiveTerminal} index={index} key={index} />
				))}
			</div>
		</div>
	);
}

export default Terminals;
