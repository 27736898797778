import { NavLink } from 'react-router-dom';
import styles from './MenuDesktop.module.scss';
import SubMenu from './SubMenu';
import Cookies from 'js-cookie';
import {
	IconArchive,
	IconArrowLeft,
	IconArrowRight,
	IconContainerCar,
	IconEmpls,
	IconFileImg,
	IconHammer,
	IconLock,
	IconPackage,
	IconPlusCircle,
	IconStatisCircle,
	IconTerminals,
	IconUsers2,
} from '../../../../../components/Icons';
import UniversalButton from '../../../../../components/Buttons/UniversalButton/UniversalButton';
import { useState } from 'react';
import {
	IconCar,
	IconContainer,
	IconDataBase,
	IconFileText,
	IconHome,
	IconSettings,
	IconTable,
	IconUsers,
} from '../../../../../components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { RequireUIFunc } from '../../../../../hoc/RequireUI';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../../store/slices/sideBarSlice';
import ProblemReport from './ProblemReport';

function MenuDesktop() {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(true);
	const { currentTerminalId } = useSelector((state) => state.terminalPage);
	const { sideBarActive } = useSelector((state) => state.sideBar);

	const handleModalSupport = () => {
		dispatch(setCurrentElementForSideBar(<ProblemReport />));
		dispatch(setSideBarActive(true));
	};

	// пункты меню
	const menuConfig = {
		// пункты для сотрудника
		1: [
			// { path: 'main', label: 'Главная', icon: <IconHome color={'black'} size={'medium'} /> },
			// { path: 'terminals', label: 'Терминалы', icon: <IconTerminals color={'black'} size={'medium'} /> },
			RequireUIFunc({ name: 'ViewTerminals' })
				? {
						path: 'terminals',
						label: 'Терминалы',
						icon: <IconTerminals color={'black'} size={'medium'} />,
					}
				: null,
			{
				label: 'Контейнеры',
				icon: <IconContainer color={'black'} size={'medium'} />,
				subMenu: [
					{ path: 'summary', label: 'Сводная таблица', icon: <IconTable color={'black'} size={'medium'} /> },

					{ path: 'tableEntries', label: 'Таблица пропусков', icon: <IconTable color={'black'} size={'medium'} /> },

					{ path: 'orders', label: 'Заявки на контейнеры', icon: <IconFileImg color={'black'} size={'medium'} /> },

					{
						path: 'createImportOrder',
						label: 'Принять контейнеры',
						icon: <IconPlusCircle color={'black'} size={'medium'} />,
					},

					{
						path: 'createExportOrder',
						label: 'Вывезти контейнеры',
						icon: <IconContainerCar color={'black'} size={'medium'} />,
					},
				],
			},
			{
				label: 'Пользователи',
				icon: <IconUsers2 color={'black'} size={'medium'} />,
				subMenu: [
					// пермишен на просмотр вкладки с клиентами(организациями)
					// RequireUIFunc({ name: 'ViewOrgs', terminal_id: currentTerminalId })
					// 	? { path: 'clients', label: 'Клиенты', icon: <IconUsers color={'black'} size={'medium'} /> }
					// 	: null,
					{ path: 'clients', label: 'Клиенты', icon: <IconUsers color={'black'} size={'medium'} /> },
					RequireUIFunc({ name: 'ViewEmpls' })
						? { path: 'empls', label: 'Сотрудники', icon: <IconEmpls color={'black'} size={'medium'} /> }
						: null,
					{ label: 'Действия сотрудников', icon: <IconLock color={'black'} size={'medium'} /> },
					RequireUIFunc({ name: 'ViewGroups' })
						? {
								path: 'createJob',
								label: 'Должности',
								icon: <IconUsers color={'black'} size={'medium'} />,
							}
						: null,
					// { path: 'cars', label: 'Автомобили', icon: <IconCar color={'black'} size={'medium'} /> },
					// { path: 'transporters', label: 'Перевозчики', icon: <IconUsers color={'black'} size={'medium'} /> },
				],
			},

			{
				label: 'Ремонт',
				icon: <IconHammer color={'black'} size={'medium'} />,
				subMenu: [
					{ label: 'Согласование смет', icon: <IconLock color={'black'} size={'medium'} /> },
					{ label: 'Заявки на ремонт', icon: <IconLock color={'black'} size={'medium'} /> },
				],
			},

			{
				label: 'Документы',
				icon: <IconArchive color={'black'} size={'medium'} />,
				subMenu: [
					{ label: 'ТН', icon: <IconLock color={'black'} size={'medium'} /> },
					{ label: 'Договоры', icon: <IconLock color={'black'} size={'medium'} /> },
					{ label: 'Акты приемки', icon: <IconLock color={'black'} size={'medium'} /> },
					{ label: 'Акты дефектовки', icon: <IconLock color={'black'} size={'medium'} /> },
					{ label: 'Акты недосдачи', icon: <IconLock color={'black'} size={'medium'} /> },
				],
			},

			{
				label: 'Грузы',
				icon: <IconPackage color={'black'} size={'medium'} />,
				subMenu: [
					{ label: 'Заявки на перетарку', icon: <IconLock color={'black'} size={'medium'} /> },
					{ label: 'Склад', icon: <IconLock color={'black'} size={'medium'} /> },
				],
			},

			{
				label: 'Ставки и тарифы',
				icon: <IconTable color={'black'} size={'medium'} />,
				subMenu: [
					{ label: 'Тарифы на хранение', icon: <IconLock color={'black'} size={'medium'} /> },
					{ label: 'Тарифы на ремонт', icon: <IconLock color={'black'} size={'medium'} /> },
					{ label: 'Тарифы на мойку', icon: <IconLock color={'black'} size={'medium'} /> },
					{ label: 'Тарифы на прочее', icon: <IconLock color={'black'} size={'medium'} /> },
				],
			},

			{
				label: 'Аналитика',
				icon: <IconStatisCircle color={'black'} size={'medium'} />,
				subMenu: [
					{ label: 'Обзор терминала', icon: <IconLock color={'black'} size={'medium'} /> },
					{ label: 'Эконом. метрики', icon: <IconLock color={'black'} size={'medium'} /> },
					{ label: 'Себестоимость', icon: <IconLock color={'black'} size={'medium'} /> },
				],
			},
		],

		// пункты для клиента
		2: [
			{ path: 'main', label: 'Главная', icon: <IconHome color={'black'} size={'medium'} /> },
			{ path: 'summary', label: 'Сводная таблица', icon: <IconTable color={'black'} size={'medium'} /> },
		],
	};

	const filteredMenuConfig = Object.fromEntries(
		Object.entries(menuConfig).map(([key, value]) => [key, value.filter((item) => item !== null)]),
	);

	const userType = Cookies.get('userType');
	const userMenu = filteredMenuConfig[userType];

	return (
		<div className={styles.menuDesktop}>
			<ul className={styles.menuDesktop__list}>
				<UniversalButton
					icon={
						open ? (
							<IconArrowLeft size={'medium'} color={'core-tertiary-70'} />
						) : (
							<IconArrowRight size={'medium'} color={'core-tertiary-70'} />
						)
					}
					color={'accent'}
					size={'medium'}
					type={'with-border'}
					onClick={() => {
						setOpen(!open);
					}}
				/>
				{userMenu.map((item, index) => (
					<li
						key={index}
						className={`${item.subMenu ? styles.menuDesktop__subMenu : styles.menuDesktop__item}`}
						style={open ? { width: '240px' } : { display: 'flex', justifyContent: 'flex-start' }}
					>
						{item.subMenu ? (
							<SubMenu item={item} open={open} />
						) : (
							<>
								<NavLink to={item.path} className={({ isActive }) => `${isActive ? styles.activeLink : ''}`}>
									{item.icon}
									{!open ? null : item.label}
								</NavLink>
							</>
						)}
					</li>
				))}
			</ul>

			{/* дополнительные нижние пункты меню */}
			<ul className={styles.menuDesktop__other} style={open ? null : { width: 'fit-content' }}>
				{/* <li className={styles.menuDesktop__item} style={open ? null : { display: 'flex', justifyContent: 'flex-start' }}>
					<NavLink>
						<IconHome color={'black'} size={'medium'} />
						{!open ? null : 'Справочник'}
					</NavLink>
				</li> */}
				<li
					className={styles.menuDesktop__item}
					style={open ? null : { display: 'flex', justifyContent: 'flex-start' }}
					onClick={handleModalSupport}
				>
					<div className={`${styles.menuDesktop__item_openModal} ${sideBarActive ? styles.activeLink : ''}`}>
						<IconSettings color={'black'} size={'medium'} />
						{!open ? null : 'Сообщить об ошибке'}
					</div>
				</li>
			</ul>
		</div>
	);
}

export default MenuDesktop;
