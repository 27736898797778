import React, { useEffect } from 'react';
import CardOrder from '../CardOrder/CardOrder';
import style from './OrderList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { allowFetching, getOrdersAction } from '../../../store/slices/orderPageSlice';
import { Link } from 'react-router-dom';
import { getOrders, setOrdersFetching } from '../../../store/slices/orderSlice';
import Preloader from '../../Preloader/Preloader';

function OrderList({ orderList }) {
	const dispatch = useDispatch();
	const { ordersFetching, orderFilters, getOrders_pending } = useSelector((state) => state.order);
	//! orderList пока не работал - сделал ниже тестовый массив

	const scrollHandler = (e) => {
		if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100) {
			dispatch(setOrdersFetching(true));
		}
	};

	useEffect(() => {
		const scrollContainer = document.getElementById('orderList_scroll');
		scrollContainer.addEventListener('scroll', scrollHandler);
		return function () {
			scrollContainer.removeEventListener('scroll', scrollHandler);
		};
	}, []);

	useEffect(() => {
		dispatch(getOrders());
	}, [dispatch, ordersFetching, orderFilters.order_type]);

	return (
		<div className={style.content}>
			{getOrders_pending ? (
				<div className={style.orderList_wrapper_preloader}>
					<Preloader />
				</div>
			) : (
				''
			)}

			<div className={style.orderList_wrapper} id="orderList_scroll">
				{orderList.length > 0 ? (
					orderList?.map((el) => {
						return (
							<Link to={`${el.terminal.id}/${el.id}`} key={el.id}>
								<CardOrder
									id={el.id}
									client={el.client.organization_name}
									total_containers={el.completion.total}
									completed_containers={el.completion.arrived}
									container_without_cars={el.completion.without_vehicle}
									date_created={el.created_at}
								/>
							</Link>
						);
					})
				) : (
					<div className={style.orderList_wrapper__nonResult}>Нет результатов</div>
				)}
			</div>
		</div>
	);
}
export default OrderList;
