import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconActionLock({ size, color, className }) {
    return (
        <div className={`${styles.icon} ${className}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)}
                height={IconSize(size)} viewBox="0 0 16 16" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.3334 6.66667V5.33333C11.3334 3.49238 9.84099 2 8.00004 2C6.15909 2 4.66671 3.49238 4.66671 5.33333V6.66667H4.00004C3.63185 6.66667 3.33337 6.96514 3.33337 7.33333V10.6667C3.33337 12.5076 4.82576 14 6.66671 14H9.33337C11.1743 14 12.6667 12.5076 12.6667 10.6667V7.33333C12.6667 6.96514 12.3682 6.66667 12 6.66667H11.3334ZM10.3334 6.66667H5.66671V5.33333C5.66671 4.04467 6.71138 3 8.00004 3C9.28871 3 10.3334 4.04467 10.3334 5.33333V6.66667ZM4.33337 7.66667V10.6667C4.33337 11.9553 5.37804 13 6.66671 13H9.33337C10.622 13 11.6667 11.9553 11.6667 10.6667V7.66667H4.33337ZM9.00607 9.66876C9.00607 10.0415 8.80233 10.3668 8.50004 10.5394V11.3333C8.50004 11.5174 8.3508 11.6667 8.16671 11.6667H7.83337C7.64928 11.6667 7.50004 11.5174 7.50004 11.3333V10.5359C7.20107 10.3624 7.00004 10.0391 7.00004 9.66876C7.00004 9.11532 7.4491 8.66667 8.00305 8.66667C8.557 8.66667 9.00607 9.11532 9.00607 9.66876Z" fill={IconColor(color)} />
            </svg>
        </div>
    );
}

export default IconActionLock;

