import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconHammer({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 13.33 13.34">
				<path
					className="cls-1"
					d="M11.62,10.31l-.71.71,2.47,2.47a.33.33,0,0,1,0,.47l-.58.58a.33.33,0,0,1-.47,0L9.15,11.37l2.14-2.14a.16.16,0,0,1,.16,0,3,3,0,0,0,2.77-.84,2.88,2.88,0,0,0,.87-2.48v0A4,4,0,0,0,15,5.35,2.55,2.55,0,0,0,14.84,5a.15.15,0,0,0-.25,0l-.29.29-.3.3h0l-.73.73a.66.66,0,0,1-.94,0l-.5-.5a.68.68,0,0,1,0-1l.73-.73h0l.29-.29.29-.29a.16.16,0,0,0,0-.26l-.35-.14a3.78,3.78,0,0,0-.46-.1h0a2.9,2.9,0,0,0-2.49.87,2.92,2.92,0,0,0-.85,2.7.18.18,0,0,1-.05.16L6.68,8.9l-.61-.61L7.86,6.5l-.7-.71L3.49,9.46l0,0a2.34,2.34,0,0,1,.67-3.76l.92-.43a1,1,0,0,0,.61-1,.6.6,0,0,1,.18-.44L6,3.65,7.48,5l.7-.71-2-2a.17.17,0,0,0-.24,0l-.78.8A1.6,1.6,0,0,0,4.66,4.3a.05.05,0,0,1,0,0l-.92.43a3.33,3.33,0,0,0-1,5.36l.45.45a.4.4,0,0,0,.57,0L5.37,9l.6.6L2.8,12.77a1.33,1.33,0,0,0,0,1.88l.58.59a1.33,1.33,0,0,0,1.88,0l3.18-3.17,3.19,3.18a1.33,1.33,0,0,0,1.88,0l.58-.58a1.34,1.34,0,0,0,0-1.89ZM4.56,14.53a.33.33,0,0,1-.47,0L3.5,14a.33.33,0,0,1,0-.47l6.06-6a1.16,1.16,0,0,0,.32-1.05,2,2,0,0,1,.57-1.8,2.06,2.06,0,0,1,.67-.46h0a1.66,1.66,0,0,0,0,2.35l.51.51A1.67,1.67,0,0,0,14,7a2,2,0,0,1-.45.63,1.93,1.93,0,0,1-1.84.57,1.18,1.18,0,0,0-1.08.31Z"
					transform="translate(-1.79 -2.31)"
					fill={IconColor(color)}
				/>
			</svg>
		</div>
	);
}

export default IconHammer;
