import styles from '../AccountEmpl.module.scss';
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from 'react';
import { Slider } from '../../../../components';
import { Modal } from '../../../../components';
import instance from '../../../../api/erp/configAxios';
import ModalTransporter from '../components/Modals/ModalTransporter';
import LayoutTransporters from '../components/Layouts/LayoutTransporters';
import Header from '../components/Header/Header';


function AccountEmplTransporters({ userPermissions }) {

    const [searchValue, setSearchValue] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [user_group, setUser_group] = useState(0);
    const [fetching, setFetching] = useState(true);
    const [dataUser, setDataUser] = useState({});

    const [client, setClient] = useState('');

    const [mail, setMail] = useState('');
    const [corName, setCorName] = useState('');
    const [corAdress, setCorAdress] = useState('');
    const [contrNum, setContrNum] = useState('');
    const [clientId, setClientId] = useState('');
    const [clientStatus, setClientStatus] = useState('');
    const [sliderCont, setSliderCont] = useState('0')

    // отслеживание ширины окна
    const isDesktop = useMediaQuery({ minWidth: 1279.9 });

    //   инфинити скролл и лимит(start)
    const [url, setUrl] = useState(
        `/api/erp/users/?limit=100&offset=0&user_type=3`
    );

    useEffect(() => {
        if (fetching && url) {
            const startIndex = url?.indexOf('?');
            const trimmedUrl = url?.substring(startIndex);
            instance
                .get(`${trimmedUrl}`)
                .then((response) => {
                    setUsers((prevArr) => [...prevArr, ...response.data.results]);
                    setUrl(response.data.next);
                })
                .catch((error) => {
                })
                .finally(() => setFetching(false));
        }
    }, [fetching, url]);

    const scrollHandler = (e) => {
        if (
            e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) <
            100
        ) {
            setFetching(true);
        }
    };

    useEffect(() => {
        const scrollContainer = document.getElementById('content__users');
        scrollContainer.addEventListener('scroll', scrollHandler);
        return function () {
            scrollContainer.removeEventListener('scroll', scrollHandler);
        };
    }, []);


    useEffect(() => {
        let params = sliderCont === '0' ? '' : `&is_manager_confirmed=${sliderCont}`;
        setUrl(`/api/erp/users/?limit=100&offset=0&user_type=3${params}`);
        setUsers([]);
        setFetching(true);
    }, [sliderCont]);
    //   инфинити скролл и лимит(end)

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleGetClient = (id) => {
        instance
            .get(`/${id}/`)
            .then((response) => {
                setClient(response.data)
                setCorName(response.data.transporter_name)
                setMail(response.data.email)
                setCorAdress(response.data.transporter_address)
                setClientId(response.data.id)
                setClientStatus(response.data.is_manager_confirmed)
            })
            .catch((error) => { })
    };

    const handleUser = async (event, el) => {
        setShowModal(true);
        let currentUser = event.currentTarget.id;
        handleGetClient(currentUser)
        setDataUser({ currentUser });
        setClient(el)
    };

    const typesUser = [
        { name: 'Одобренные', id: 1 },
        { name: 'Ожидают одобрения', id: 2 },
        { name: 'Отклоненные', id: 3 },
    ];


    return (
        <>
            <div className={styles.account__wrap}>
                <Header text={"Перевозчики"} />
                <div className={styles.account__container}>
                    <div className={styles.account__slider}>
                        <Slider setSliderCont={setSliderCont} allName={'Все перевозчики'} data={typesUser} user_group={user_group} setUser_group={setUser_group} />
                    </div>
                    <div className={styles.account__stack}>
                        <LayoutTransporters
                            handleUser={handleUser}
                            showModal={showModal}
                            // oneUser={oneUser}
                            contrNum={contrNum}
                            mail={mail}
                            corName={corName}
                            corAdress={corAdress}
                            handleCloseModal={handleCloseModal}
                            users={users}
                            setUsers={setUsers}
                            clientStatus={clientStatus}
                            setSliderCont={setSliderCont}
                            sliderCont={sliderCont}
                            dataUser={dataUser}
                            searchValue={searchValue}
                            clientId={clientId}
                            setSearchValue={setSearchValue}
                            user_group={user_group}
                            setUser_group={setUser_group}
                            setCorName={setCorName}
                            setMail={setMail}
                            setCorAdress={setCorAdress}
                            setContrNum={setContrNum}
                            client={client}
                        />
                    </div>
                </div>
            </div>
            {!isDesktop && showModal && (
                <Modal onClose={handleCloseModal} noCross>
                    <ModalTransporter
                        corName={corName}
                        corAdress={corAdress}
                        clientId={clientId}
                        setUsers={setUsers}
                        clientStatus={clientStatus}
                        sliderCont={sliderCont}
                        handleCloseModal={handleCloseModal}
                        mail={mail}
                        setSliderCont={setSliderCont}
                        contrNum={contrNum}
                        setCorName={setCorName}
                        setMail={setMail}
                        setCorAdress={setCorAdress}
                        setContrNum={setContrNum}
                        client={client}
                    />
                </Modal>
            )}
        </>
    );
}

export default AccountEmplTransporters;