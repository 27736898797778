import { UniversalButton } from '../../../../components';
import styles from '../AccountEmpl.module.scss';
import Header from '../components/Header/Header';
import TableEntries from '../components/Tables/TableEntries/TableEntries';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useState } from 'react';
import { getExportExcel } from '../../../../store/slices/entriesSlice';

function AccountEmplTableEntries() {
	const dispatch = useDispatch();

	const { getExportExcel_pending } = useSelector((state) => state.entries);

	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});

	// Видимость столбцов
	const [columnVisibility, setColumnVisibility] = useState({
		name: true,
		status_display: true,
		order_number: true,
		container_number: true,
		transporter_org: true,
		vehicle_number: true,
		vehicle_driver: true,
		vehicle_trailer_number: true,
		seal_number: true,
		contact_number: true,
		eta_checkin: true,
	});

	const handleExportExcel = () => {
		let viewCols;
		if (Object.values(columnVisibility).every((value) => value === true)) {
			viewCols = '';
		} else {
			viewCols =
				'exclude=' +
				Object.keys(columnVisibility)
					.filter((key) => !columnVisibility[key])
					.join(',');
		}
		if (Object.keys(rowSelection).length !== 0) {
			if (viewCols === '') {
				viewCols += 'entrypass_ids_in=' + Object.keys(rowSelection).join(',');
			} else {
				viewCols += '&entrypass_ids_in=' + Object.keys(rowSelection).join(',');
			}
		}
		dispatch(getExportExcel(viewCols));
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Таблица пропусков'}
				btnDesktop={
					getExportExcel_pending ? (
						<div className={styles.btnExport}>
							<UniversalButton color={'primary'} size={'medium'} label={'. . .'} />
						</div>
					) : (
						<UniversalButton color={'primary'} size={'medium'} label={'Экспортировать данные'} onClick={handleExportExcel} />
					)
				}
				btnMobile={
					getExportExcel_pending ? (
						<div className={styles.btnMobileExport}>
							<UniversalButton color={'primary'} size={'medium'} label={'. . .'} />
						</div>
					) : (
						<UniversalButton color={'primary'} size={'medium'} label={'Экспорт'} onClick={handleExportExcel} />
					)
				}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale="ru"
						// Placeholder
						localeText={{
							fieldDayPlaceholder: () => '01',
							fieldMonthPlaceholder: () => '01',
							fieldYearPlaceholder: () => '2024',
						}}
					>
						<TableEntries
							columnVisibility={columnVisibility}
							setColumnVisibility={setColumnVisibility}
							rowSelection={rowSelection}
							setRowSelection={setRowSelection}
						/>
					</LocalizationProvider>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableEntries;
