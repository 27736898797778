import React, { useEffect } from "react";
import ContentUsers from "../ContentUsers/ContentUsers";
import { Search } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../../../store/slices/userSlice";

import style from "./Modals.module.scss";

export default function ModalUserListForTerminal() {
  const dispatch = useDispatch();

  const { userList } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUsers());
  }, [userList]);

  return (
    <div className={style.modal__card}>
      <div className={style.card__user}>
        <p>Список всех сотрудников</p>
      </div>
      <Search />
      {/* <div>
        {userList.map((el) => {
          return <p>{el.full_name}</p>;
        })}
      </div> */}
      <div className={style.wrapper_contentUsers}>
        <ContentUsers type={"addUsersForTerminal"} items={userList} />
      </div>
    </div>
  );
}
