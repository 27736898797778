import { Search, Slider } from "../../../../../components";
import { BtnIcon } from "../../../../../components/Buttons";
import { IconMaxmize, IconSettings } from "../../../../../components/Icons";
import styles from "./Layouts.module.scss";
import ContentUsers from "../ContentUsers/ContentUsers";
import ModalTransporter from "../Modals/ModalTransporter";
import { useMediaQuery } from "react-responsive";

function LayoutTransporters({
    setUsers,
    handleUser,
    corName,
    contrNum,
    showModal,
    dataUser,
    users,
    searchValue,
    setSearchValue,
    handleCloseModal,
    user_group,
    setUser_group,
    corAdress,
    sliderCont,
    mail,
    setSliderCont,
    clientId,
    clientStatus,
    setCorName,
    setMail,
    setCorAdress,
    setContrNum,
    handlePutUser,
    handlePutBaseUser,
    client
}) {
    // отслеживание ширины окна
    const isDesktop = useMediaQuery({ minWidth: 743.9 });

    return (
        <div className={styles.layout}>
            <div className={styles.layout__stack}>
                <div className={styles.layout__content}>
                    {isDesktop && (
                        <div className={styles.search}>
                            <Search searchValue={searchValue} setSearchValue={setSearchValue} />
                            {/* <div className={styles.search__btns}>
                                <BtnIcon type={"button"} icon={<IconSettings size={"large"} color={"primary_white"} />} size={"p-12"} />
                                <BtnIcon type={"button"} icon={<IconMaxmize size={"large"} color={"primary_white"} />} size={"p-12"} />
                            </div> */}
                        </div>
                    )}

                    <div className={styles.content}>
                        <ContentUsers clientStatus={clientStatus} clients setUsers={setUsers} users={users} dataUser={dataUser} showModal={showModal} handleUser={handleUser} />
                        <div className={`${styles.content__card} ${showModal ? styles.show : ""}`}>
                            <ModalTransporter
                                corName={corName}
                                corAdress={corAdress}
                                clientId={clientId}
                                setUsers={setUsers}
                                clientStatus={clientStatus}
                                setSliderCont={setSliderCont}
                                sliderCont={sliderCont}
                                handleCloseModal={handleCloseModal}
                                mail={mail}
                                contrNum={contrNum}
                                setCorName={setCorName}
                                setMail={setMail}
                                setCorAdress={setCorAdress}
                                setContrNum={setContrNum}
                                client={client}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.layout__footer}>
                <div className={styles.page__block}>
                    <p>На странице:</p>
                    <div className={styles.page__items}>{users.length}</div>
                </div>
            </div>
        </div>
    );
}

export default LayoutTransporters;