import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconActionUnlock({ size, color, className }) {
    return (
        <div className={`${styles.icon} ${className}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)}
                height={IconSize(size)} viewBox="0 0 16 16" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.33337 8.66667H1.33337V7.66667V5.33333C1.33337 3.49238 2.82576 2 4.66671 2C6.50766 2 8.00004 3.49238 8.00004 5.33333V6.66667H14C14.3682 6.66667 14.6667 6.96514 14.6667 7.33333V10.6667C14.6667 12.5076 13.1743 14 11.3334 14H8.66671C6.82576 14 5.33337 12.5076 5.33337 10.6667V7.33333C5.33337 6.96514 5.63185 6.66667 6.00004 6.66667H7.00004V5.33333C7.00004 4.04467 5.95537 3 4.66671 3C3.37804 3 2.33337 4.04467 2.33337 5.33333L2.33337 6.66667L2.33337 8.66667ZM6.33337 7.66667V10.6667C6.33337 11.9553 7.37804 13 8.66671 13H11.3334C12.622 13 13.6667 11.9553 13.6667 10.6667V7.66667H6.33337ZM11.0061 9.66876C11.0061 10.0415 10.8023 10.3668 10.5 10.5394V11.3333C10.5 11.5174 10.3508 11.6667 10.1667 11.6667H9.83337C9.64928 11.6667 9.50004 11.5174 9.50004 11.3333V10.5359C9.20107 10.3624 9.00004 10.0391 9.00004 9.66876C9.00004 9.11532 9.4491 8.66667 10.0031 8.66667C10.557 8.66667 11.0061 9.11532 11.0061 9.66876Z" fill={IconColor(color)} />
            </svg>
        </div>
    );
}

export default IconActionUnlock;
