import styles from '../AccountEmpl.module.scss';
import { IconFileText } from '../../../../components/Icons';
import LayoutUsers from '../components/Layouts/LayoutUsers';
import { Search, Slider } from '../../../../components';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalProfile from '../components/Modals/ModalProfile';
import useCountItem from '../../../../hooks/useCountItem';
import Header from '../components/Header/Header';
import { useForm } from 'react-hook-form';
import UniversalButton from '../../../../components/Buttons/UniversalButton/UniversalButton';
import {
	getUserGroups,
	getUsers,
	setCurrentUser,
	setModalType,
	setUserFetching,
	setUserGroup,
	setUserUrlAfterUserGroupChange,
	setUserList,
	setUserNextNull,
	setUserUrl,
} from '../../../../store/slices/userSlice';

import { setCurrentElementForSideBar, setSideBarActive } from '../../../../store/slices/sideBarSlice';
import RequireUI, { RequireUIFunc } from '../../../../hoc/RequireUI';

function AccountEmplEmpls({ userPermissions }) {
	// отслеживание ширины окна
	const isDesktop = useMediaQuery({ minWidth: 1279.9 });

	const dispatch = useDispatch();
	const { currentTerminalId } = useSelector((state) => state.terminalPage);

	const { fetching, url, userList, chosenUserGroup, userGroups, getUsers_pending } = useSelector((state) => state.user);

	const [searchValue, setSearchValue] = useState('');

	//! Для кастомного хука
	const [changeCondition, setChangeCondition] = useState(false);

	const permViewGrops = RequireUIFunc({ name: 'ViewGroups' }); // право на запрос просмотра групп

	// ! Возможно понадобится когда будет реализована логика с модалкой на выдачу прав
	// const {
	//   register,
	//   setFocus,
	//   setValue,
	//   formState: { errors },
	//   clearErrors,
	//   handleSubmit,
	// } = useForm({
	//   mode: "onChange",
	//   defaultValues: {
	//     email: localStorage.getItem("email"),
	//     FLName: localStorage.getItem("FLName"),
	//     login: localStorage.getItem("login"),
	//     numberContract: localStorage.getItem("numberContract"),
	//     urAdress: localStorage.getItem("urAdress"),
	//     urName: localStorage.getItem("urName"),
	//     autoNum: '',
	//   },
	// });

	const countItem = useCountItem();

	// 	const scrollHandler = (e) => {
	// 		if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100) {
	// 			dispatch(setUserFetching(true));
	// 		} else {
	// 			dispatch(setUserFetching(false));
	// 		}
	// 	};

	// 	useEffect(() => {
	// 		const scrollContainer = document.getElementById('content__users');
	// 		scrollContainer.addEventListener('scroll', scrollHandler);

	// 		return function () {
	// 			scrollContainer.removeEventListener('scroll', scrollHandler);
	// 		};
	// 	}, []);

	// ! Выбор количества загружаемых элементов в зависимости от ширины окна
	useEffect(() => {
		dispatch(setUserUrl(countItem));
	}, [countItem, chosenUserGroup]);

	// ! получение пользователей
	useEffect(() => {
		if (countItem) {
			//25.04.24 условие предотвращает дубляж
			dispatch(getUsers());
		}
	}, [dispatch, fetching, url]);

	// ! получение групп пользователей
	useEffect(() => {
		if (permViewGrops) {
			dispatch(getUserGroups()); //запрос отправляется если есть право на просмотр групп пользователей
		}
	}, []);

	//!Перезагрузка запроса при изменении группы пользователей
	useEffect(() => {
		//TODO допислись вариант с кастомным хуком подгрузкой разного количества данных
		dispatch(setSideBarActive(false));
		let params = Number(chosenUserGroup) ? `&user_group=${chosenUserGroup}` : '';
		dispatch(setUserUrlAfterUserGroupChange(`/?limit=${countItem}&offset=0&user_type=1${params}`));
		dispatch(setUserList([]));
		dispatch(setUserNextNull());
		dispatch(setUserFetching(true));
	}, [chosenUserGroup, dispatch]);

	const handleOpenModalForNewUser = () => {
		dispatch(setCurrentUser({ full_name: '', username: '', group: '' }));
		dispatch(setCurrentElementForSideBar(<ModalProfile type={'users'} />));
		dispatch(setSideBarActive(true));
		dispatch(setModalType('create'));
		dispatch(getUserGroups());
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Сотрудники'}
				btnDesktop={
					<RequireUI type={{ name: 'AddEmpls', terminal_id: currentTerminalId }}>
						<UniversalButton
							icon={<IconFileText color={'primary_white'} />}
							color={'primary'}
							size={'medium'}
							label={'Добавить сотрудника'}
							onClick={handleOpenModalForNewUser}
							type={'outline'}
						/>
					</RequireUI>
				}
			/>
			<div className={styles.account__container}>
				{/* пермишен на просмотр групп (должностей) */}
				<RequireUI type={{ name: 'ViewGroups', terminal_id: currentTerminalId }}>
					<div className={styles.account__slider}>
						<Slider
							user_group={chosenUserGroup}
							setUser_group={(el) => dispatch(setUserGroup(el))}
							allName={'Все сотрудники'}
							data={userGroups}
							isDisabled={getUsers_pending}
						/>
					</div>
				</RequireUI>

				<div className={styles.account__stack}>
					<LayoutUsers
						// users={userList}
						searchValue={searchValue}
						setSearchValue={setSearchValue}
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplEmpls;
