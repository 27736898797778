import { Preloader, Search, SelectLaptop, UniversalButton } from '../../../../../components';
import styles from './Layouts.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import MainInput from '../../../../../components/Inputs/MainInput/MainInput';
import { useEffect, useRef, useState } from 'react';
import { IconCross } from '../../../../../components/Icons';
import { Link } from 'react-router-dom';
import { generateBackground, getInitialsCLient } from '../../../avatarsUtils';
import { getClients, setClientGroup, setClientsFetching } from '../../../../../store/slices/clientSlice';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useUpdateEffect } from '../../../../../hooks/useUpdateEffect';

// Левая часть страницы с клиентами (фильтрация)
function LayoutClientsInfo({ onChangeFilterType, filterType }) {
	const { count } = useSelector((state) => state.client);
	const {
		register,
		setFocus,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
	});

	// Типы для фильтрации
	const typesUser = [
		{ name: 'Одобренные', id: 1 },
		{ name: 'Ожидают одобрения', id: 2 },
		{ name: 'Отклоненные', id: 3 },
	];

	const [selectedOption, setSelectedOption] = useState(null);
	const [selectedId, setSelectedId] = useState('');

	//! Выбор фильтра с клиентом
	const handleChangelClient = (event) => {
		setSelectedOption(event.target.value);
		setSelectedId(event.target.value);
	};

	useUpdateEffect(() => {
		onChangeFilterType(typesUser.find((item) => item.id === selectedId));
	}, [selectedId]);

	useEffect(() => {
		if (!filterType) {
			setSelectedOption(null);
			setSelectedId('');
		}
	}, [filterType]);

	return (
		<div className={styles.layout__blockInfo}>
			<div className={styles.layout__info}>
				<p>Клиенты: {count}</p>
			</div>
			<div className={styles.layout__filters}>
				<h6>Фильтрация</h6>
				<div className={styles.filter_select}>
					<div className={styles.selectContainer}>
						<p>Статус</p>
						<FormControl sx={{ m: 1, minWidth: 120, margin: '0px' }} size="small">
							<Select
								id="demo-select-small"
								value={selectedOption}
								onChange={handleChangelClient}
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
								sx={{ borderRadius: '8px' }}
								required
							>
								<MenuItem value={null}>
									<p>не выбран</p>
								</MenuItem>
								{typesUser?.map((el, index) => (
									<MenuItem key={index} value={el.id}>
										{el.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</div>
			</div>
		</div>
	);
}

// Правая часть страницы с клиентами (список и поиск)
function LayoutClientsList({ searchValue, setSearchValue, filterType, setFilterType }) {
	const { clientList, getClients_pending, fetching, url } = useSelector((state) => state.client);
	const dispatch = useDispatch();

	//! Кастомный хук
	const useUpdateEffect = (effect, deps) => {
		const isInitialMount = useRef(true);
		useEffect(() => {
			if (isInitialMount.current) {
				isInitialMount.current = false;
			} else {
				return effect();
			}
		}, deps);
	};

	const scrollHandler = (e) => {
		if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100) {
			dispatch(setClientsFetching(true));
		}
	};

	useEffect(() => {
		const scrollContainer = document.getElementById('clientsList_scroll');
		scrollContainer.addEventListener('scroll', scrollHandler);
		return function () {
			scrollContainer.removeEventListener('scroll', scrollHandler);
		};
	}, []);

	useUpdateEffect(() => {
		dispatch(getClients());
	}, [dispatch, fetching, url]);

	return (
		<div className={styles.layout__blockList}>
			<div className={styles.ordersList_search}>
				<div className={styles.search}>
					<Search searchValue={searchValue} setSearchValue={setSearchValue} />
				</div>
				<AllClientsFilters filterType={filterType} setFilterType={setFilterType} />
				<div className={styles.content}>
					<div className={styles.client__card_list} id="clientsList_scroll">
						{clientList?.map((el, ind) => (
							<Link to={`${el?.id}`} className={styles.client__card_link} key={ind}>
								<div className={styles.client__card_big}>
									<div>
										<div
											className={styles.client__img}
											style={{ background: generateBackground(el?.organization_name) }}
										>
											{getInitialsCLient(el?.organization_name)}
										</div>
										<div className={styles.client__info}>
											<span>{el?.organization_name}</span>
											<p>Договор №3434343</p>
										</div>
									</div>
									<div className={styles.client__status}>
										<p
											className={`${
												el?.is_manager_confirmed === 'Одобрен'
													? styles.approved
													: el?.is_manager_confirmed === 'Ожидает одобрения'
														? styles.awaiting
														: el?.is_manager_confirmed === 'Отклонен'
															? styles.rejected
															: ''
											}`}
										>
											{el?.is_manager_confirmed}
										</p>
									</div>
								</div>
							</Link>
						))}
					</div>

					{getClients_pending ? (
						<div className={styles.preloader_inwrapper}>
							<Preloader />
						</div>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
}

// Фильтрация на правой части после поисковой строки
function AllClientsFilters({ filterType, setFilterType }) {
	// Удаление всех фильтров
	const removeAllFiters = () => {
		setFilterType('');
	};
	// Удаление фильтра по типу
	const removeFilterType = () => {
		setFilterType('');
	};

	return (
		<div className={styles.allClientsFilters}>
			{filterType && (
				<>
					<UniversalButton color={'primary'} size={'small'} label={'Сбросить фильтры'} onClick={removeAllFiters} />
					<UniversalButton
						color={'primary'}
						size={'small'}
						label={filterType?.name}
						type={'with-border'}
						icon={<IconCross color={'primary-active'} />}
						onClick={removeFilterType}
					/>
				</>
			)}
		</div>
	);
}

function LayoutClients({ searchValue, setSearchValue }) {
	const dispatch = useDispatch();
	// Передача фильтрв
	const [filterType, setFilterType] = useState({});

	//! Кастомный хук
	const useUpdateEffect = (effect, deps) => {
		const isInitialMount = useRef(true);
		useEffect(() => {
			if (isInitialMount.current) {
				isInitialMount.current = false;
			} else {
				return effect();
			}
		}, deps);
	};

	const handleFilterType = (filterType) => {
		setFilterType(filterType);
	};
	useUpdateEffect(() => {
		console.log('Effect triggered');
	}, [filterType]);

	useUpdateEffect(() => {
		console.log(filterType, 'TIP FILTRA');
	}, [filterType]);
	useUpdateEffect(() => {
		console.log('VSERAVNO GRYPPA RABOTAYET');
		dispatch(setClientGroup(filterType?.id));
	}, [filterType, dispatch]);

	return (
		<div className={styles.layoutOrders}>
			<LayoutClientsInfo onChangeFilterType={handleFilterType} filterType={filterType} />
			<LayoutClientsList
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				filterType={filterType}
				setFilterType={setFilterType}
			/>
		</div>
	);
}

export default LayoutClients;
