import React from 'react';
import styles from './AllOrdersFilterItems.module.scss';
import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import { IconCross } from '../../Icons';

function AllOrdersFilterItems({ filterItems, removeFilterItem, setFilterItems, setImportOrderSwitch, setExportOrderSwitch, setNoManger }) {
	const removeAllFiters = () => {
		setImportOrderSwitch(false);
		setExportOrderSwitch(false);
		setNoManger(false);
		setFilterItems([]);
	};

	return (
		<div className={styles.searchFiltr_items}>
			{filterItems.length !== 0 && (
				<UniversalButton color={'primary'} size={'small'} label={'Сбросить фильтры'} onClick={removeAllFiters} />
			)}
			{filterItems.map((text, index) => (
				<UniversalButton
					key={index}
					color={'primary'}
					size={'small'}
					label={text}
					type={'with-border'}
					icon={<IconCross color={'primary-active'} />}
					onClick={() => removeFilterItem(index, text)}
				/>
			))}
		</div>
	);
}

export default AllOrdersFilterItems;
