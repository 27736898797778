import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './PassComponent.module.scss';
import Search from '../../Search/Search';
import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import PassCard from './PassCard/PassCard';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../store/slices/sideBarSlice';
import ModalPassChange from './ModalPassChange/ModalPassChange';
import { DatePickerRange } from './RangeDatePicker/DatePicker';
import {
	clearModalInputsForMultiplePasses,
	getEntries,
	setEntriesFetching,
	setEntryPassFetchingData,
	setPassFilters,
} from '../../../store/slices/orderSlice';
import { useMediaQuery } from 'react-responsive';
import { IconCheck, IconEdit, IconFileText, IconRestart } from '../../Icons';
import { useParams } from 'react-router-dom';

//test mui select
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Alert, Snackbar } from '@mui/material';
import Asynchronous from '../AutoCompleteSearch/AutoCompleteSearch';

const PassComponent = ({ handleOpenModalPlug }) => {
	const [openToast, setOpenToast] = useState(false);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	//селекты фильтров
	const [status, setStatus] = useState('');
	const [responsible, setResponsible] = useState('');
	const [confirmed, setConfirmed] = useState('');

	const dispatch = useDispatch();
	const { entriesList, entriesFetching, passStatus, passFilters, putEntriesList_success } = useSelector((state) => state.order);
	const isDesktop = useMediaQuery({ minWidth: 1200 });

	const { orderId } = useParams();

	//Данные пропусков
	const [selectedContainers, setSelectedContainers] = useState([]);
	const [selectedCards, setSelectedCards] = useState(Array(entriesList?.length)?.fill(false)); //Логика выбора всех карточек

	const handleSelectCard = (index, selected) => {
		// Хендлер для выбора конкретной карточки по индексу
		setSelectedCards((prev) => {
			const newSelectedCards = [...prev];
			newSelectedCards[index] = selected;
			return newSelectedCards;
		});

		// Получение данных выбранной карточки
		const selectedCardData = entriesList[index];

		setSelectedContainers((prevSelected) => {
			if (selected) {
				// Если карточка выбрана, добавляем ее данные в массив выбранных карточек
				return [...prevSelected, { id: selectedCardData.id, container_number: selectedCardData.container.container_number }];
			} else {
				// Если карточка снята с выбора, удаляем ее данные из массива выбранных карточек
				return prevSelected?.filter((container) => container.id !== selectedCardData.id);
			}
		});
	};

	const handleSelectAll = () => {
		setSelectedCards(Array(entriesList?.length)?.fill(true)); // Выбор всех карточек
		setSelectedContainers(entriesList?.map((el) => ({ id: el.id, container_number: el.container.container_number })));
	};

	const handleResetSelection = () => {
		setSelectedCards(Array(entriesList?.length)?.fill(false)); // Сбрасываем выбор всех карточек
		setSelectedContainers([]);
	};

	const handleChange = () => {
		if (selectedContainers?.length) {
			dispatch(setSideBarActive(true));
			if (selectedContainers?.length > 1) {
				dispatch(clearModalInputsForMultiplePasses());
			}
			dispatch(
				setCurrentElementForSideBar(
					<ModalPassChange
						selectedContainers={selectedContainers}
						setSelectedContainers={setSelectedContainers}
						setSelectedCards={setSelectedCards}
						setOpenToast={setOpenToast}
						selectedCards={selectedCards}
					/>,
				),
			);
		}
	};

	// Фильтр по статусу
	const handleChangeStatus = (event) => {
		setStatus(event.target.value);
		dispatch(setPassFilters({ field: 'status', data: event.target.value }));
	};

	//! Фильтр  даты начала
	const handleChangeStartDate = (data) => {
		if (data) {
			dispatch(setPassFilters({ field: 'end_date', data: null }));
			dispatch(setPassFilters({ field: 'start_date', data: data }));
		} else {
			dispatch(setPassFilters({ field: 'start_date', data: null }));
		}
	};
	//! Фильтр даты окончания
	const handleChangeEndDate = (data) => {
		if (data) {
			dispatch(setPassFilters({ field: 'end_date', data: data }));
		} else {
			dispatch(setPassFilters({ field: 'end_date', data: null }));
		}
	};

	//ручной сброс фильров
	const hansleResetFilters = () => {
		dispatch(setPassFilters({ field: 'status', data: null }));
		dispatch(setPassFilters({ field: 'start_date', data: null }));
		dispatch(setPassFilters({ field: 'end_date', data: null }));
		setStatus(null);

		dispatch(getEntries({ orderId }));
	};

	//логика закрытия тоста
	const handleClose = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenToast(false);
	};

	const scrollHandler = (e) => {
		if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100) {
			dispatch(setEntriesFetching(true));
		}
	};

	useEffect(() => {
		dispatch(getEntries({ orderId }));
	}, [dispatch, entriesFetching]);

	useEffect(() => {
		// dispatch(setEntriesFetching(true));
		return () => {
			dispatch(setEntryPassFetchingData());
		};
	}, []);

	return (
		<div className={style.containerPass}>
			{putEntriesList_success && (
				<Snackbar
					open={openToast}
					onClose={handleClose}
					autoHideDuration={3000}
					anchorOrigin={!isDesktop ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'left' }}
				>
					<Alert severity="success" sx={{ width: '100%' }}>
						Данные пропускa(в) успешно обновлены
					</Alert>
				</Snackbar>
			)}
			<div className={style.filtersPass}>
				<div className={style.allContainers}>
					<p>Всего контейнеров: {entriesList?.length}</p>
					<p>Выбрано: {selectedContainers?.length}</p>
				</div>
				<div className={style.filters}>
					<div className={style.resetFilters}>
						<p>Фильтрация</p>
						<UniversalButton
							label={'Сбросить фильтры'}
							color={'secondary'}
							type={'button'}
							size={'medium'}
							onClick={hansleResetFilters}
						/>
					</div>
					<form className={style.selects}>
						<div className={style.statusSelect}>
							<p>Статус пропуска</p>
							<FormControl sx={{ m: 1, minWidth: 120, margin: '0px' }} size="small">
								<Select
									id="demo-select-small"
									value={status}
									onChange={handleChangeStatus}
									displayEmpty
									inputProps={{ 'aria-label': 'Without label' }}
									sx={{ borderRadius: '8px' }}
								>
									<MenuItem value={null}>
										<p>не выбран</p>
									</MenuItem>
									{passStatus.map((el, index) => (
										<MenuItem key={index} value={index}>
											{el}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						<div className={style.statusSelect} onClick={handleOpenModalPlug}>
							<p>Ответственный</p>
							<FormControl sx={{ m: 1, minWidth: 120, margin: '0px' }} size="small">
								<Select
									id="demo-select-small"
									value={responsible}
									onChange={() => {}}
									displayEmpty
									inputProps={{ 'aria-label': 'Without label' }}
									sx={{ borderRadius: '8px' }}
									disabled
								>
									<MenuItem value={null}>
										<p>не выбран</p>
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className={style.statusSelect} onClick={handleOpenModalPlug}>
							<p>Подтвердил</p>
							<FormControl sx={{ m: 1, minWidth: 120, margin: '0px' }} size="small">
								<Select
									id="demo-select-small"
									value={confirmed}
									onChange={() => {}}
									displayEmpty
									inputProps={{ 'aria-label': 'Without label' }}
									sx={{ borderRadius: '8px' }}
									disabled
								>
									<MenuItem value="null">
										<p>не выбран</p>
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<DatePickerRange
							startDate={passFilters?.start_date}
							setStartDate={(e) => handleChangeStartDate(e)}
							endDate={passFilters?.end_date}
							setEndDate={(e) => handleChangeEndDate(e)}
						/>
					</form>
				</div>
			</div>
			<div className={style.mainPass}>
				{/* <Search /> */}
				<div className={style.buttonsBlock}>
					<UniversalButton
						label={isDesktop && 'Выбрать все'}
						color={'primary'}
						type={'button'}
						size={'medium'}
						onClick={handleSelectAll}
						icon={!isDesktop && <IconCheck size={'largeM'} color={'primary_white'} />}
					/>
					<UniversalButton
						label={isDesktop && 'Сбросить'}
						color={'secondary'}
						type={'button'}
						size={'medium'}
						onClick={handleResetSelection}
						icon={!isDesktop && <IconRestart size={'largeM'} color={'primary_white'} />}
					/>
					<UniversalButton
						label={isDesktop && 'Распечатать'}
						color={'accent'}
						type={'button'}
						size={'medium'}
						onClick={handleOpenModalPlug}
						icon={!isDesktop && <IconFileText size={'largeM'} color={'primary_white'} />}
					/>
					<UniversalButton
						label={isDesktop && 'Изменить'}
						color={'succesful'}
						type={'button'}
						size={'medium'}
						onClick={handleChange}
						icon={!isDesktop && <IconEdit size={'largeM'} color={'primary_white'} />}
					/>
				</div>
				<div className={style.passList} id="passList_scroll" onScroll={scrollHandler}>
					{entriesList?.map((el, index) => (
						<PassCard
							key={el.id}
							numContainer={el.container.container_number} //Номер контейнера тут понятно
							numOrder={el.order} //Номер ореда тут понятно
							status={el.status} //уточнить статусы на беке +
							transport={el.vehicle_display ? el.vehicle_display : 'не выбран'} //изменяем на нужный транспорт
							driver={el.vehicle_driver ? el.vehicle_driver : 'не выбран'} //изменяем на нужного водителя
							responsible={el.responsible ? el.responsible : 'не выбран'} // Ответственного не нашел пока в объекте (Этого пока нет)
							security={el.security ? el.security : 'не выбран'} // Охранника не нашел пока в объекте (Этого пока нет)
							isSelected={selectedCards[index]} //Состояние выбора каждой карточки
							onSelect={(selected) => handleSelectCard(index, selected)}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default PassComponent;

// seal_number номер пломбы работает только при одиночном редактировании

// Можно брать
// eta_checkin ожидаемое время прибытия date-time-field
// водитель, номер пломбы, номер прицепа (string)
