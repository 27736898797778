import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrdersAction } from '../../../../store/slices/orderPageSlice';
import Header from '../components/Header/Header';
import { UniversalButton } from '../../../../components';
import { IconContainer, IconContainerCar, IconDisketa, IconFileText, IconSettings, IconSort } from '../../../../components/Icons';
import { Slider } from '../../../../components';
import MainOrderInfo from '../../../../components/Order/MainOrderInfo/MainOrderInfo';
import {
	addContainersInOrder,
	clearNavigationViaRequestSuccess,
	clearTemporaryDataForOrderCreate,
	getContainersInOrder,
	getOrderData,
	postContainer,
	putLinkContainers,
	setSaveLinkContainersButtonActive,
	setSaveLinkContainersButtonClicked,
} from '../../../../store/slices/orderSlice';
import MainOrderContainers from '../../../../components/Order/MainOrderContainers/MainOrderContainers';
import PassComponent from '../../../../components/Order/PassComponent/PassComponent';
import { Alert, Snackbar } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { RequireUIFunc } from '../../../../hoc/RequireUI';
import { useUpdateEffect } from '../../../../hooks/useUpdateEffect';
import ModalPlug from '../../../../components/Modal/ModalPlug';

function AccountEmplOrderPage(props) {
	let { orderId, terminalId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		currentOrderContainerList,
		currentOrderData,
		currentOrderTemporaryContainerList,
		organizationContainerList,
		containersToAddInOrder,
		putLinkContainers_success,
		saveLinkContainersButtonActive,
		newOrder_terminal_id,
		newOrderData,
		allTypes,
		allSizes,
		lineList,
		putContainer_success,
	} = useSelector((state) => state.order);
	const { sideBarActive } = useSelector((state) => state.sideBar);
	//*шаги
	const [sliderCont, setSliderCont] = useState('0');
	//*snackbar
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const isDesktop = useMediaQuery({ minWidth: 1279.9 });

	const handleSaveChangesInOrder = () => {
		console.log('currentOrderContainerList', currentOrderContainerList);
		console.log('currentOrderTemporaryContainerList', currentOrderTemporaryContainerList);
		dispatch(setSaveLinkContainersButtonActive(false));
		setMessage('Список контейнеров в заявке изменен!');
		setOpen(true);
		let formattedContainers;
		if (currentOrderData.type == 1) {
			// .concat(currentOrderTemporaryContainerList)
			formattedContainers = currentOrderContainerList
				.map((container) => {
					return container.id;
				})
				.filter((el) => el !== undefined && el !== null);
		}
		if (currentOrderData.type == 2) {
			formattedContainers = currentOrderContainerList.map((container) => {
				return container.id;
			});
		}

		// let clientId;
		// // console.log(
		// // 	currentOrderContainerList[0],
		// // 	'CurOrdCont[0]',
		// // 	currentOrderContainerList[0].hasOwnProperty('client'),
		// // 	organizationContainerList[0],
		// // );
		// if (currentOrderContainerList[0] && currentOrderContainerList[0].hasOwnProperty('client')) {
		// 	clientId = currentOrderContainerList[0]?.client;
		// } else if (organizationContainerList[0] && organizationContainerList[0].hasOwnProperty('client')) {
		// 	clientId = organizationContainerList[0]?.client;
		// }
		console.log(formattedContainers, 'FORMAT');
		dispatch(
			putLinkContainers({
				terminal_id: terminalId,
				requestData: {
					org_id: currentOrderData.client.id,
					order_id: orderId,
					containers: formattedContainers,
				},
			}),
		);

		// const org_id = currentOrderContainerList[0].client;
		// const formattedContainers = currentOrderContainerList.map((container) => {
		// 	const { client, order, ...rest } = container;
		// 	return rest;
		// });
		// const formattedData = { containers: formattedContainers, order: Number(orderId), org_id: org_id };
		// dispatch(
		// 	postContainer({
		// 		requestData: {
		// 			org_id: org_id,
		// 			order_id: orderId,
		// 			containers: formattedContainers,
		// 		},
		// 		terminal_id: terminalId,
		// 	}),
		// );
		// dispatch(postContainer({ terminal_id: terminalId, requestData: formattedData }));
	};
	//! Очистка данных после создания заявки
	// useEffect(() => {

	// }, []);

	//! Переход на страницу заявки в случае успешной привязки контейнеров
	//TODO бывает баг при перезагрузке
	useEffect(() => {
		if (putLinkContainers_success === true && currentOrderData.terminal?.id && currentOrderData?.id) {
			// console.log('v uslovii');
			// navigate(
			// 	`/accountEmpl/orders/${currentOrderData?.terminal.id ? currentOrderData?.terminal.id : terminalId}/${currentOrderData?.id ? currentOrderData.id : orderId}`,
			// );
			dispatch(clearNavigationViaRequestSuccess());

			// dispatch(clearTemporaryDataForOrderCreate());
		}
	}, [putLinkContainers_success, open, navigate]);

	useUpdateEffect(() => {
		dispatch(getContainersInOrder({ terminal_id: terminalId, order_id: orderId, client_id: currentOrderData.client.id, page: 1 }));
	}, [putLinkContainers_success]);

	//* Закрытие тоста
	const handleClose = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setMessage('');
		setOpen(false);
	};

	// const selectAllCards = () => {
	// 	for (let i = 0; i <= organizationContainerList.length - 1; i++) {
	// 		handleAddToOrder(organizationContainerList[i]);
	// 	}
	// 	console.log('trutti');
	// };
	const handleDelete = () => {
		console.log('handleDelete');
	};
	const handleChange = () => {
		console.log('handleChange');
	};

	// const handleAddToOrder = (container) => {
	// 	dispatch(addContainersInOrder({ type: 'export_after', data: container }));
	// 	console.log('handleAddToOrder', container);
	// };

	const order_steps = [
		RequireUIFunc({ name: 'ViewContainers' })
			? {
					name: 'Контейнеры',
					id: 1,
					icon: <IconContainer size={'largeS'} color={'black'} />,
				}
			: null,

		{
			name: 'Пропуска',
			id: 2,
			icon: <IconContainerCar size={'largeS'} color={'black'} />,
		},
		// {
		// 	name: 'Связь контейнеров и т/c',
		// 	id: 3,
		// 	icon: <IconSort size={'largeS'} color={'white'} />,
		// },
		// {
		// 	name: 'Документы',
		// 	id: 4,
		// 	icon: <IconFileText size={'largeS'} color={'white'} />,
		// },
	];

	//! Получение данных по заявке API
	useEffect(() => {
		dispatch(getOrderData({ order_id: orderId, terminal_id: terminalId }));
	}, []);

	//Получение контейнеров по терминалу определенной организации
	useUpdateEffect(() => {
		console.log(currentOrderData, 'DATA TAK DATA');
		dispatch(getContainersInOrder({ terminal_id: terminalId, order_id: orderId, client_id: currentOrderData.client.id, page: 1 }));
	}, [currentOrderData]);

	const pageDisplay = () => {
		if (sliderCont === '0') {
			//TODO вкладка заявки - основное
			return <MainOrderInfo sliderCont={sliderCont} handleOpenModalPlug={handleOpenModalPlug} />;
		} else if (sliderCont === '1') {
			//TODO вкладка заявки - контенеры
			// return <div>шаг {Number(sliderCont) + 1}(контенеры)</div>;
			return <MainOrderContainers />;
		} else if (sliderCont === '2') {
			//TODO вкладка заявки - транспорт
			return <PassComponent handleOpenModalPlug={handleOpenModalPlug} />;
		} else if (sliderCont === '3') {
			//TODO вкладка заявки - связь контенеров и т/c
			return <div>шаг {Number(sliderCont) + 1}(связь контейнеров и т/c)</div>;
		} else if (sliderCont === '4') {
			//TODO вкладка заявки - документы
			return <div>шаг {Number(sliderCont) + 1}(документы)</div>;
		}
	};

	useUpdateEffect(() => {
		setMessage('Данные контейнера изменены!');
		setOpen(true);
	}, [putContainer_success]);

	//1 -заявка на ввоз
	//2 -заявка на вывоз

	const [isOpenModal, setIsOpenModal] = useState(false);

	const onClose = () => {
		setIsOpenModal(false);
	};

	const handleOpenModalPlug = () => {
		setIsOpenModal(true);
	};

	return (
		<>
			<ModalPlug isOpen={isOpenModal} fullScreen={true} onClose={onClose} page={'Заявка'} />
			<div className={styles.account__wrap}>
				{putLinkContainers_success && (
					<Snackbar
						open={open}
						onClose={handleClose}
						autoHideDuration={1500}
						anchorOrigin={!isDesktop ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'left' }}
					>
						<Alert severity="success" sx={{ width: '100%' }}>
							{message}
						</Alert>
					</Snackbar>
				)}
				<Header
					createOrder
					text={currentOrderData.type == 1 ? `Заявка на прием №${orderId}` : `Заявка на вывоз №${orderId}`}
					btnDesktop={
						<UniversalButton
							label={'Экспортировать данные'}
							typeButton={'button'}
							size={'small'}
							icon={<IconFileText color={'white'} />}
							color={'primary'}
							onClick={handleOpenModalPlug}
						/>
					}
				/>
				<div className={styles.account__container}>
					<div className={styles.account__slider}>
						<Slider allName={'Основное'} data={order_steps} user_group={sliderCont} setUser_group={setSliderCont} />
					</div>
					<div className={`${styles.contentBtns} ${styles.orderPageBtn}`}>
						{/* //* Основной контент */}
						{pageDisplay()}
						{sliderCont == 1 && (
							<div
								// className={
								// 	organizationContainerList?.length !== 0 && sliderCont == 1
								// 		? `${styles.lowerBtns_spaceBetween}`
								// 		: `${styles.lowerBtns_flexEnd}`
								// }
								className={styles.lowerBtns_flexEnd}
							>
								{/* {organizationContainerList?.length !== 0 && sliderCont == 1 && (
							<UniversalButton size={'small'} label={'Выбрать все'} color={'accent'} onClick={selectAllCards} />
						)} */}

								<UniversalButton
									label={'Сохранить данные'}
									typeButton={'button'}
									size={'small'}
									disabled={!saveLinkContainersButtonActive}
									icon={<IconDisketa color={'white'} />}
									color={'primary'}
									onClick={() => handleSaveChangesInOrder()}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default AccountEmplOrderPage;
