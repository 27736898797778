import { useEffect, useRef, useState } from 'react';
import { BtnIcon, BtnText } from '../../Buttons';
import { IconContainerCar, IconPlus, IconRestart, IconCross, IconDownload, IconDanger, IconWarningTool } from '../../Icons';
import styles from './AddContainers.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import UniversalSelect from './UniversalSelect';
import Switcher from '../../Switcher/Switcher';
import { useForm } from 'react-hook-form';
import MainInput from '../../Inputs/MainInput/MainInput';
import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import {
	addContainersInOrder,
	postContainer,
	getLines,
	setNewContainerData,
	setAddContainerButtonClicked,
	setSaveLinkContainersButtonActive,
	getContainersInOrder,
} from '../../../store/slices/orderSlice';
import { useParams } from 'react-router-dom';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { fields } from './ImportsTable/importFields';
import { useUpdateEffect } from '../../../hooks/useUpdateEffect';

function AddContainers({ type, mob, setViewMobContainers, setPage, data, setData }) {
	//? type - тип для действий в зависимости от того созданная это заявка или в процессе создания
	//? import_before
	//? import_after

	const [resetForm, setResetForm] = useState(false);
	const { terminalId, orderId } = useParams();
	const dispatch = useDispatch();
	const {
		lineList,
		newOrder_terminal_id,
		newOrder_organization_id,
		newContainerData,
		allSizes,
		allTypes,
		currentOrderData,
		addContainerButtonClicked,
		postContainer_success,
	} = useSelector((state) => state.order);

	const { register, handleSubmit, unregister, setFocus, formState, setValue, clearErrors, reset } = useForm({});
	const { errors } = formState;

	//* Счетчик контейнеров
	const [containerCounter, setContainerCounter] = useState(1);
	//* Счетчик для локального id
	const [localIdCounter, setLocalIdCounter] = useState(1);
	//* Временный массив контейнеров, который потом отправляется в запросе
	const [temporaryContainersArray, setTemporaryContainersArray] = useState([]);
	//* Данные инпута в который вводим информацию о контейнере
	const [containerInputData, setContainerInputData] = useState({
		local_id: localIdCounter,
		container_number: '',
		booking: '',
		size: newContainerData?.size,
		type: newContainerData?.type,
		line: newContainerData?.line,
		is_dangerous: false,
		is_damaged: false,
	});

	const [isOpen, setIsOpen] = useState(false);

	//* Кастомный хук
	const useUpdateEffect = (effect, deps) => {
		const isInitialMount = useRef(true);
		useEffect(() => {
			if (isInitialMount.current) {
				isInitialMount.current = false;
			} else {
				return effect();
			}
		}, deps);
	};

	//! Действие для добавления нового под номер контейнера
	const handleAddInput = () => {
		//* Снимаем регистрацию с инпута для ввода номера контейнера
		unregister(`container_number_0`);
		//* Увеличиваем количество контейнеров
		setContainerCounter((prevState) => prevState + 1);
		setLocalIdCounter((prevState) => prevState + 1);
		setTemporaryContainersArray([...temporaryContainersArray, containerInputData]);
		setContainerInputData({
			local_id: localIdCounter + 1,
			container_number: '',
			booking: '',
			size: newContainerData?.size,
			type: newContainerData?.type,
			line: newContainerData?.line,
			is_damaged: false,
			is_dangerous: false,
		});
		//* Увеличение счетчика для присвоения local_id объекта контейнера
	};

	//! Действие для удаления инпута под номер/с номером контейнера
	const handleRemoveInput = (container_number) => {
		unregister(`container_number_${container_number.local_id}`);
		unregister(`container_number_${containerCounter}`);
		//* Фильтр локального массива контейнеров, исключающий объект с local_id удаляемого контейнера
		setTemporaryContainersArray((prevItems) =>
			prevItems.map((el) => {
				if (el.local_id === container_number.local_id) {
					return { ...el, container_number: '' };
				} else {
					return el;
				}
			}),
		);
		setContainerCounter((prevState) => prevState - 1);
		setTemporaryContainersArray((prevItems) => prevItems.filter((item) => item.local_id !== container_number.local_id));
	};

	//! Изменение номера контейнера в любом из созданных инпутов (учитываются изменения линий/размера/типа)
	const handleContainerNumberChange = (container_number, value) => {
		//* Изменение номеров конейнеров
		setTemporaryContainersArray((prevItems) =>
			prevItems.map((item) =>
				item.local_id === container_number.local_id
					? {
							...item,
							container_number: value,
						}
					: item,
			),
		);

		dispatch(setNewContainerData({ type: 'container_number', data: value }));
	};

	//! Функция добавляющая в объект контейнера новые свойства (line, size, type, isFilled ...)
	const handleSelectedOptionChange = (key, selectedOption) => {
		dispatch(setNewContainerData({ type: [key], data: selectedOption }));
	};

	//! Функция сброса формы
	const handleResetForm = () => {
		setLocalIdCounter(1);
		unregister(`container_number_0`);
		// setResetForm(true);
		dispatch(setNewContainerData({ type: 'clearAll', data: '' }));
		setTemporaryContainersArray([]);
		setContainerInputData({ local_id: localIdCounter, container_number: '', booking: '', is_damaged: false, is_dangerous: false });
		setContainerCounter(1);
	};

	//! Функция добавляющая в объект контейнера информацию о повреждении
	const onCheckedDamage = ({ container, type, value }) => {
		switch (type) {
			case 'base_input':
				setContainerInputData({ ...containerInputData, is_damaged: value });
				break;
			case 'array_input':
				setTemporaryContainersArray((prevItems) =>
					prevItems.map((item) =>
						item.local_id === container.local_id
							? {
									...item,
									is_damaged: value,
								}
							: item,
					),
				);
				dispatch(setNewContainerData({ type: 'is_damaged', data: value }));
				break;
			default:
				break;
		}
	};
	//! Функция добавляющая в объект контейнера информацию о наличии опасного груза
	const onCheckedDanger = ({ container, type, value }) => {
		switch (type) {
			case 'base_input':
				setContainerInputData({ ...containerInputData, is_dangerous: value });
				break;
			case 'array_input':
				setTemporaryContainersArray((prevItems) =>
					prevItems.map((item) =>
						item.local_id === container.local_id
							? {
									...item,
									is_dangerous: value,
								}
							: item,
					),
				);
				dispatch(setNewContainerData({ type: 'is_dangerous', data: value }));
				break;
			default:
				break;
		}
	};

	//! Действие при нажатии на кнопку добавить
	const handleAddContainerInOrder = async (e) => {
		//* Сброс регистраций инпутов
		for (let i = 0; i <= containerCounter; i++) {
			unregister(`container_number_${i}`);
		}
		dispatch(setSaveLinkContainersButtonActive(true));
		//* Устанавливаем состояние кнопки "Нажато", чтобы по состоянию отправился запрос
		dispatch(setAddContainerButtonClicked());
		// setLocalIdCounter(1);
	};

	//! Запрос на все получение всех линий
	useEffect(() => {
		dispatch(getLines());
	}, []);

	//! Создание контейнера после его добавления в список
	useUpdateEffect(() => {
		let containers;
		//* Для процесса создания новой заявки на ввоз используется массив containersToAddInOrder
		if (type === 'import_before') {
			containers = temporaryContainersArray;
		}
		//* Для процесса добавления контейнеров к уже созданной заявке на ввоз используется массив currentOrderTemporaryContainerList
		if (type === 'import_after') {
			containers = temporaryContainersArray;
		}
		const formattedContainers = containers.map((container) => {
			const typeObj = allTypes.find((type) => type.name === container.type);
			const typeId = typeObj ? typeObj.id : null;
			const sizeObj = allSizes.find((size) => size.name === container.size);
			const sizeId = sizeObj ? sizeObj.id : null;
			const lineObj = lineList.find((line) => line.line_name === container.line);
			const lineSlug = lineObj ? lineObj.id : null;
			return {
				...container,
				type: typeId,
				size: sizeId,
				line: lineSlug,
			};
		});
		//* Создание отформатированных контейнеров
		dispatch(
			postContainer({
				terminal_id: type === 'import_before' ? newOrder_terminal_id : terminalId,
				beforeAfter: type === 'import_before' ? 'before' : 'after',
				requestData: {
					org_id: type === 'import_before' ? newOrder_organization_id : currentOrderData.client.id,
					// order_id: newOrderData?.id,
					containers: formattedContainers,
				},
			}),
		);
		// handleResetForm();
	}, [dispatch, addContainerButtonClicked]);

	//! Добавление линий/типа/размера в для первого инпута
	useEffect(() => {
		setContainerInputData({
			...containerInputData,
			local_id: localIdCounter,
			type: newContainerData.type,
			size: newContainerData.size,
			line: newContainerData.line,
		});
	}, [newContainerData, containerCounter, postContainer_success]);

	//! Очистка массива контейнеров после успешного добавления
	useUpdateEffect(() => {
		setContainerInputData({
			...containerInputData,
			local_id: localIdCounter,
			container_number: '',
			booking: '',
			// type: '',
			// size: '',
			// line: '',
			is_damaged: false,
			is_dangerous: false,
		});
		unregister('line', 'size', 'type');
		setContainerCounter(1);
		setNewContainerData({ type: 'clearAll', data: '' });
		handleResetForm();
		setTemporaryContainersArray([]);
	}, [postContainer_success]);

	const onClose = () => {
		setIsOpen(false);
	};

	const sizes = {
		20: 1,
		40: 2,
		45: 3,
	};

	const types = {
		DC: 1,
		DV: 2,
		HC: 3,
		PW: 4,
		OT: 5,
		OTHC: 6,
		RF: 7,
		RFHC: 8,
		FR: 9,
		FRHC: 10,
		TC: 11,
		HR: 12,
	};

	const onSubmitImport = (data) => {
		const newData = data?.validData?.map((item) => ({
			...item,
			line: 1,
			local_id: 1,
			size: sizes[item.size],
			type: Number(types[item.type.replace(/\s/g, '')]),
		}));
		// dispatch(addContainersInOrder({ type: 'import_after', data: newData }));
		dispatch(
			postContainer({
				terminal_id: terminalId,
				beforeAfter: 'after',
				requestData: {
					org_id: currentOrderData.client.id,
					// order_id: newOrderData?.id,
					containers: newData,
				},
			}),
		);
		dispatch(setSaveLinkContainersButtonActive(true));
	};
	//* Обнуляем данные которые записываются в объект контейнера
	// useUpdateEffect(() => {
	// 	dispatch(setNewContainerData({ type: 'clearAll' }));
	// }, [postContainer_success]);

	return (
		<div className={styles.addContainers}>
			<ReactSpreadsheetImport
				isOpen={isOpen}
				onClose={onClose}
				onSubmit={onSubmitImport}
				fields={fields}
				translations={{
					uploadStep: {
						title: 'Загрузка таблицы контейнеров',
						manifestTitle: 'Данные, которые мы ожидаем:',
						manifestDescription: '(На следующих шагах у вас будет возможность переименовать или удалить столбцы.)',
						dropzone: {
							title: 'Загрузить .xlsx, .xls or .csv file',
							errorToastDescription: 'загрузка отклонена',
							activeDropzoneTitle: 'Drop file here...',
							buttonTitle: 'Выберите файл',
							loadingTitle: 'Обработка...',
						},
						// selectSheet: {
						// 	title: 'Select the sheet to use',
						// 	nextButtonTitle: 'Далее',
						// 	backButtonTitle: 'Back',
						// },
					},
					selectHeaderStep: {
						title: 'Выбор строк таблицы',
						nextButtonTitle: 'Далее',
					},
					matchColumnsStep: {
						title: 'Сопоставление столбцов',
						userTableTitle: 'Ваша таблица',
						templateTitle: 'Итоговая таблица',
						nextButtonTitle: 'Далее',
					},
					validationStep: {
						title: 'Проверка данных',
						discardButtonTitle: 'Отменить выбранные строки',
						filterSwitchTitle: 'Показывать только строки с ошибками',
						nextButtonTitle: 'Подтвердить импорт',
					},
					alerts: {
						confirmClose: {
							headerTitle: 'Выйти из импорта',
							bodyText: 'Вы уверены? Ваша текущая информация не будет сохранена.',
							cancelButtonTitle: 'Отмена',
							exitButtonTitle: 'Выйти',
						},
						submitIncomplete: {
							headerTitle: 'Обнаружены ошибки',
							bodyText: 'Еще есть строки, содержащие ошибки. Строки с ошибками будут игнорироваться при отправке.',
							bodyTextSubmitForbidden: 'Есть еще несколько строк, содержащих ошибки.',
							cancelButtonTitle: 'Отмена',
							finishButtonTitle: 'Отправить',
						},
						unmatchedRequiredFields: {
							headerTitle: 'Не все столбцы совпали',
							bodyText: 'Есть обязательные столбцы, которые не сопоставляются или игнорируются. Вы хотите продолжить?',
							listTitle: 'Столбцы не совпадают:',
							cancelButtonTitle: 'Отмена',
							continueButtonTitle: 'Продолжить',
						},
						toast: {
							error: 'Ошибка',
						},
					},
				}}
			/>
			<div className={styles.addContainers__mainBlock}>
				<div>
					<div className={styles.addContainers__header}>
						<div className={styles.topTitle_btn}>
							<h4 className={styles.addContainers__title}>Добавьте контейнеры</h4>
							<UniversalButton
								size="small"
								onClick={handleResetForm}
								color="error"
								typeButton="button"
								type="with-border"
								label="Очистить"
							/>
						</div>
						<p className={styles.addContainers__subtitle}>Вы можете добавлять контейнеры группами или по одному</p>
					</div>
					<div className={styles.addContainers__body}>
						<div className={styles.addContainers__form}>
							<div className={styles.typeSizeBlock}>
								<UniversalSelect
									resetForm={resetForm}
									setResetForm={setResetForm}
									placeholder={'MSC'}
									nameCreateOrder="line"
									label="Линия"
									options={lineList}
									elementName="true"
									selectedOption={newContainerData['line']}
									// setSelectedOption={(e) =>
									// 	setNewContainerData((prevState) => {
									// 		return { ...prevState, line: e };
									// 	})
									// }
									setSelectedOption={(e) => dispatch(setNewContainerData({ type: 'line', data: e }))}
									// setSelectedOption={(value) => handleSelectedOptionChange('line', value)}
									onSelectedOptionChange={(value) => handleSelectedOptionChange('line', value)}
									setFocus={setFocus}
									register={register}
									errors={errors}
									setValue={setValue}
									// rules={
									//   required: false
									// }
								/>
								<UniversalSelect
									resetForm={resetForm}
									placeholder={'DV'}
									setResetForm={setResetForm}
									nameCreateOrder="type"
									label="Тип"
									options={allTypes}
									selectedOption={newContainerData['type'] || false}
									// setSelectedOption={(e) =>
									// 	setNewContainerData((prevState) => {
									// 		return { ...prevState, type: e };
									// 	})
									// }
									setSelectedOption={(e) => dispatch(setNewContainerData({ type: 'type', data: e }))}
									onSelectedOptionChange={(value) => handleSelectedOptionChange('type', value)}
									setFocus={setFocus}
									register={register}
									errors={errors}
									setValue={setValue}
									clearErrors={clearErrors}
								/>
								<UniversalSelect
									resetForm={resetForm}
									setResetForm={setResetForm}
									placeholder={'40'}
									nameCreateOrder="size"
									label="Размер"
									options={allSizes}
									selectedOption={newContainerData['size']}
									value={!newContainerData['size'] ? '' : newContainerData['size']}
									setSelectedOption={(e) => dispatch(setNewContainerData({ type: 'size', data: e }))}
									// 	setNewContainerData((prevState) => {
									// 		return { ...prevState, size: e };
									// 	})
									// }
									onSelectedOptionChange={(value) => handleSelectedOptionChange('size', value)}
									setFocus={setFocus}
									register={register}
									errors={errors}
									setValue={setValue}
								/>
							</div>
							{/* <div className={styles.form__switch}>
								<div>
									<p>Контейнер поврежден</p>
									<Switcher externalChecked={newContainerData['is_filled']} onChecked={onCheckedDamage} />
								</div>
								<div>
									<p>Опасный груз</p>
									<Switcher externalChecked={newContainerData['is_dangerous']} onChecked={onCheckedDanger} />
								</div>
							</div> */}
							<div className={styles.form__input}>
								<div key={containerCounter} id={containerCounter}>
									<div>
										<MainInput
											title={'Букинг'}
											errorTitle={'Некорректный текст'}
											name="booking"
											register={register}
											value={containerInputData.booking}
											setValue={(value) => setContainerInputData({ ...containerInputData, booking: value })}
											setFocus={setFocus}
											errors={errors}
											rules={{}}
											placeholder={'Текст'}
											disabled={!newContainerData.line || !newContainerData.size || !newContainerData.type}
										/>
									</div>
									<div className={styles.container_number_wrapper}>
										<div className={styles.container_number_icons_wrapper}>
											<div
												className={
													containerInputData.is_damaged ? `${styles.details__damage}` : `${styles.details__unset}`
												}
											>
												<IconWarningTool
													size={'medium'}
													color={'white'}
													switcherType
													data={{ data: containerInputData, type: 'base_input' }}
													onClick={onCheckedDamage}
												/>
											</div>
											<div
												className={
													containerInputData.is_dangerous
														? `${styles.details__danger}`
														: `${styles.details__unset}`
												}
											>
												<IconDanger
													size={'medium'}
													color={'white'}
													switcherType
													data={{ data: containerInputData, type: 'base_input' }}
													onClick={onCheckedDanger}
												/>
											</div>
										</div>
										<div className={styles.container_number_input_wrapper}>
											<div className={styles.container_number_input_main}>
												<MainInput
													title={`Введите номер контейнера ${containerCounter}`}
													errorTitle="Некорректный формат номера"
													name={`container_number_${0}`}
													setFocus={setFocus}
													setResetForm={setResetForm}
													register={register}
													errors={errors}
													disabled={!newContainerData.line || !newContainerData.size || !newContainerData.type}
													// value={() => showContainerNumberValue(el, index)}
													value={containerInputData.container_number}
													setValue={(value) =>
														setContainerInputData({ ...containerInputData, container_number: value })
													}
													rules={{
														required: 'Введите номер контейнера',
														minLength: { value: 4, message: 'Минимум 4 символа' },
													}}
													mask={'********************'}
													placeholder="Например: FHD 4749164 6"
												/>
											</div>
											<div className={styles.container_number_input_button}>
												<UniversalButton
													typeButton="button"
													color={'primary'}
													type={''}
													icon={<IconPlus color="white" size="medium" />}
													size="small"
													// !newContainersArray[numInputs]?.container_number &&
													// disabled={!newContainerData.container_number}
													disabled={
														!newContainerData.line ||
														!newContainerData.size ||
														!newContainerData.type ||
														containerInputData.container_number.length < 1
													}
													onClick={handleAddInput}
												/>
											</div>
										</div>
									</div>
								</div>
								{temporaryContainersArray?.map((container_number, index) => (
									<div key={index} id={container_number.local_id}>
										<div className={styles.container_number_wrapper}>
											<div className={styles.container_number_icons_wrapper1}>
												<div
													className={
														container_number.is_damaged
															? `${styles.details__damage}`
															: `${styles.details__unset}`
													}
												>
													<IconWarningTool
														size={'medium'}
														color={'white'}
														switcherType
														data={{ data: container_number, type: 'array_input' }}
														onClick={onCheckedDamage}
													/>
												</div>
												<div
													className={
														container_number.is_dangerous
															? `${styles.details__danger}`
															: `${styles.details__unset}`
													}
												>
													<IconDanger
														size={'medium'}
														color={'white'}
														switcherType
														data={{ data: container_number, type: 'array_input' }}
														onClick={onCheckedDanger}
													/>
												</div>
											</div>
											<div className={styles.container_number_input_wrapper}>
												<div className={styles.container_number_input_main}>
													<MainInput
														title={`Контейнер №${index + 1}`}
														errorTitle="Некорректный формат номера"
														name={`container_number_${container_number.local_id}`}
														setFocus={setFocus}
														setResetForm={setResetForm}
														register={register}
														errors={errors}
														disabled={
															!newContainerData.line || !newContainerData.size || !newContainerData.type
														}
														// value={() => showContainerNumberValue(el, index)}
														value={container_number.container_number}
														setValue={(value) => handleContainerNumberChange(container_number, value)}
														rules={{
															required: 'Введите номер контейнера',
															minLength: { value: 4, message: 'Минимум 4 символа' },
														}}
														placeholder="Например: FHD 4749164 6"
													/>
												</div>
												<div className={styles.container_number_input_button}>
													<UniversalButton
														typeButton="button"
														color={'error'}
														type={'with-border'}
														icon={<IconCross color="core-state-error" size="medium" />}
														size="small"
														// !newContainersArray[numInputs]?.container_number &&
														// disabled={!newContainerData.container_number}
														// disabled={
														// 	index === 0 &&
														// 	!temporaryContainersArray[temporaryContainersArray.length - 1]?.container_number
														// }
														onClick={() => handleRemoveInput(container_number)}
													/>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className={styles.addContainers__footer}>
					{mob && (
						<>
							<BtnIcon
								typeButton="button"
								icon={<IconRestart size="large" color="core-state-error" />}
								bg="noneBg"
								size="p-12"
							/>
							<div onClick={() => setViewMobContainers(true)} className={styles.mobContBtn}>
								<p>{data.length}</p>
								<IconContainerCar size="large" color="white" />
							</div>
						</>
					)}
					<UniversalButton
						label="Импорт из EXCEL"
						typeButton="button"
						size="small"
						onClick={() => setIsOpen(true)}
						type={'with-border'}
						color="succesful"
						icon={<IconDownload size="largeS" color="core-state-success" />}
					/>
					<UniversalButton
						label="Добавить"
						typeButton="button"
						onClick={handleAddContainerInOrder}
						size="small"
						color="succesful"
						disabled={!temporaryContainersArray[0]?.container_number || containerInputData.container_number}
						icon={<IconPlus size="largeS" color="white" />}
					/>
				</div>
			</div>

			{mob && <BtnText text="Продолжить" bg="orange" type="button" font="fs-18" size="p-1724" onClick={() => setPage(2)} />}
		</div>
	);
}

export default AddContainers;
