import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../AccountEmpl.module.scss';
import Header from '../components/Header/Header';
import LayoutTerminals from '../components/Layouts/LayoutTerminals';
import SliderForTerminals from '../../../../components/SliderForTerminals/SliderForTerminals';
import { useDispatch, useSelector } from 'react-redux';
import { getTerminalList } from '../../../../store/slices/terminalPageSlice';
import UniversalButton from '../../../../components/Buttons/UniversalButton/UniversalButton';
import { IconDownload, IconPlus } from '../../../../components/Icons';
import { useMediaQuery } from 'react-responsive';
import { Preloader } from '../../../../components';
import RequireUI from '../../../../hoc/RequireUI';
import { postLoginUser } from '../../../../store/slices/loginSlice';
import { getMe } from '../../../../store/slices/meSlice';

function AccountEmplTerminals() {
	const isDesktop = useMediaQuery({ minWidth: 743.9 });
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { terminalList, getTerminalList_pending } = useSelector((state) => state.terminalPage);

	useEffect(() => {
		let requestData;
		requestData = {
			username: 'demo-user',
			password: 'SteizaDemoUser123',
		};
		dispatch(postLoginUser({ requestData }));
		dispatch(getMe());
	}, []);

	const handleCreateTerminalClick = () => {
		navigate('/accountEmpl/createTerminal');
	};

	useEffect(() => {
		dispatch(getTerminalList());
	}, [dispatch]);

	return (
		<div className={styles.account__wrap}>
			<Header
				btnDesktop={
					<div className={styles.header_btns}>
						<RequireUI type={{ name: 'AddTerminals' }}>
							<UniversalButton
								label={isDesktop ? 'Создать терминал' : ''}
								color="primary"
								size="small"
								icon={<IconPlus size="largeS" color="white" />}
								onClick={handleCreateTerminalClick}
							/>
						</RequireUI>
					</div>
				}
				text={'Терминалы'}
			/>

			{getTerminalList_pending ? (
				<div className={styles.account__preloaderTerminals}>
					<Preloader />
				</div>
			) : (
				<SliderForTerminals data={terminalList} />
			)}

			<div className={styles.account__container_withSlider}>
				<div className={styles.account__stack}>
					<LayoutTerminals />
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTerminals;
