import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconUsers2({ onClick, size, color }) {
	return (
		<div className={styles.icon} onClick={onClick}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 22 22" fill="none">
				<g>
					<path
						id="Union"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M18 6C18 6.82843 17.3284 7.5 16.5 7.5C15.6716 7.5 15 6.82843 15 6C15 5.17157 15.6716 4.5 16.5 4.5C17.3284 4.5 18 5.17157 18 6ZM19.5 6C19.5 7.65685 18.1569 9 16.5 9C14.8431 9 13.5 7.65685 13.5 6C13.5 4.34315 14.8431 3 16.5 3C18.1569 3 19.5 4.34315 19.5 6ZM9 12C9 12.8284 8.32843 13.5 7.5 13.5C6.67157 13.5 6 12.8284 6 12C6 11.1716 6.67157 10.5 7.5 10.5C8.32843 10.5 9 11.1716 9 12ZM10.5 12C10.5 13.6569 9.15685 15 7.5 15C5.84315 15 4.5 13.6569 4.5 12C4.5 10.3431 5.84315 9 7.5 9C9.15685 9 10.5 10.3431 10.5 12ZM11.5 21H13C13 17.9624 10.5376 15.5 7.5 15.5C4.46243 15.5 2 17.9624 2 21H3.5C3.5 18.7909 5.29086 17 7.5 17C9.70914 17 11.5 18.7909 11.5 21ZM13.9235 12.276C14.646 11.5535 15.6258 11.1476 16.6475 11.1476C17.6693 11.1476 18.6491 11.5535 19.3716 12.276C20.094 12.9984 20.4999 13.9783 20.4999 15H21.9999C21.9999 13.5805 21.436 12.2191 20.4322 11.2153C19.4285 10.2115 18.0671 9.64763 16.6475 9.64763C15.228 9.64763 13.8666 10.2115 12.8628 11.2153C11.8591 12.2191 11.2952 13.5805 11.2952 15H12.7952C12.7952 13.9783 13.201 12.9984 13.9235 12.276Z"
						fill={IconColor(color)}
					/>
				</g>
			</svg>
		</div>
	);
}

export default IconUsers2;
