import React from 'react';
import styles from './AddInitionalInfo.module.scss';

function AddInitionalInfo() {
	const infoData = [
		{ label: 'Принято', value: 20 },
		{ label: 'В работе', value: 20 },
		{ label: 'Завершено', value: 20 },
		{ label: 'Вымышленный статус', value: 15 },
		{ label: 'Другой статус', value: 15 },
	];

	return (
		<div className={styles.additionalInfo}>
			<div>
				{infoData.map((item, index) => (
					<p key={index}>
						{item.label}: {item.value}
					</p>
				))}
			</div>
			<div>
				<svg width="88" height="88" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
					<circle cx="100" cy="100" r="90" fill="#f0f0f0" />
					<path d="M100,100 L100,10 A90,90 0 0,1 178.28,55.53 Z" fill="#9FC4FF" />
					<path d="M100,100 L178.28,55.53 A90,90 0 0,1 178.28,144.47 Z" fill="#82B3FF" />
					<path d="M100,100 L178.28,144.47 A90,90 0 0,1 100,190 Z" fill="#6699FF" />
					<path d="M100,100 L100,190 A90,90 0 0,1 39.10,166.56 Z" fill="#4D88FF" />
					<path d="M100,100 L39.10,166.56 A90,90 0 0,1 39.10,33.44 Z" fill="#3366FF" />
				</svg>
			</div>
		</div>
	);
}

export default AddInitionalInfo;
