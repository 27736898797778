import React, { useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import LayoutOrders from '../components/Layouts/LayoutOrders';
import Header from '../components/Header/Header';
import { UniversalButton } from '../../../../components';
import { IconContainerCar, IconDownload } from '../../../../components/Icons';
import { NavLink } from 'react-router-dom';
import RequireUI from '../../../../hoc/RequireUI';
import ModalPlug from '../../../../components/Modal/ModalPlug';
// import { allowFetching, setActiveFilter, setCardOrderActive, } from "../../../../store/slices/orderPageSlice";
// import { useDispatch } from "react-redux";

function AccountEmplOrders({ userPermissions }) {
	const [order_group, setOrder_group] = useState('0');

	// Slider для мобильной версии
	// const dispatch = useDispatch();
	// useEffect(() => {
	//   dispatch(allowFetching(true));
	//   dispatch(setCardOrderActive(false));
	//   dispatch(setActiveFilter(order_group));
	// }, [order_group, dispatch]);

	const [isOpenModal, setIsOpenModal] = useState(false);

	const onClose = () => {
		setIsOpenModal(false);
	};

	const handleOpenModalPlug = () => {
		setIsOpenModal(true);
	};

	return (
		<>
			<ModalPlug isOpen={isOpenModal} fullScreen={true} onClose={onClose} page={'Заявки'} />
			<div className={styles.account__wrap}>
				<Header
					text={'Заявки на контейнеры'}
					btnDesktop={
						<div className={styles.header_btns}>
							<UniversalButton
								color={'primary'}
								size={'small'}
								label={'Экспорт данных '}
								icon={<IconDownload color={'white'} />}
								onClick={handleOpenModalPlug}
							/>
							<RequireUI type={{ name: 'AddApplications' }}>
								<NavLink to={'/accountEmpl/createImportOrder'}>
									<UniversalButton
										color={'primary'}
										size={'small'}
										label={'Принять контейнер '}
										icon={<IconContainerCar color={'white'} />}
									/>
								</NavLink>
							</RequireUI>
							<RequireUI type={{ name: 'AddApplications' }}>
								<NavLink to={'/accountEmpl/createExportOrder'}>
									<UniversalButton
										color={'primary'}
										size={'small'}
										label={'Вывезти контейнер '}
										icon={<IconContainerCar color={'white'} />}
									/>
								</NavLink>
							</RequireUI>
						</div>
					}
				/>
				<div className={styles.account__container}>
					<div className={styles.account__stack}>
						<LayoutOrders order_group={order_group} setOrder_group={setOrder_group} handleOpenModalPlug={handleOpenModalPlug} />
					</div>
				</div>
			</div>
		</>
	);
}
export default AccountEmplOrders;
