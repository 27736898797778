import React from 'react';
import styles from './MainOrderInfo.module.scss';
import { LongInfoBlock, MediumInfoBlock, SmallInfoBlock, InfoBlock } from './components';
import StartEndDateComponent from '../../StartEndDateComponent/StartEndDateComponent';

function MainOrderInfo({ handleOpenModalPlug }) {
	// Дополнительные данные для блока "Контейнеры"
	const moreInfoContainer = [
		{ Ph20: '20', Ph40: '6' },
		{ standart: '10', height: '16' },
	];

	return (
		<div className={styles.mainInfo_mainBlock}>
			<div className={styles.mainBlock_row}>
				{/* //! Статус заявки */}
				<InfoBlock children={<LongInfoBlock orderState />} />
				{/* //! Прогресс завершения */}
				<InfoBlock smallLong children={<LongInfoBlock />} />
			</div>
			<div className={styles.mainBlock_row}>
				{/* //! Данные о клиенте */}
				<InfoBlock
					children={
						<MediumInfoBlock
							type={'smallInfo'}
							title={'Данные о клиенте'}
							subTitle={'Сергеев Сергей Сергеевич'}
							contractNumber={'№0011 от 01.02.2023'}
							adress={'г. Москва, ул. Ленина'}
						/>
					}
				/>
				{/* //! Контакты клиента */}
				<InfoBlock
					children={
						<MediumInfoBlock
							title={'Контакты клиента '}
							type={'fullWithoutSubTitle'}
							telegram={'@test222'}
							whatsapp={'+7 (999) 999 -99-99'}
							number={'+7 (999) 999-99-99'}
							mail={'mail@example'}
							noEditBtn
						/>
					}
				/>
				{/* //! Ответственный сотрудник */}
				<InfoBlock
					children={
						<MediumInfoBlock
							type={'full'}
							title={'Ответственный сотрудник'}
							subTitle={'Иванов Иван Иванович'}
							telegram={'@telega'}
							whatsapp={'+7 (999) 999 -99-99'}
							number={'+7 (999) 999-99-99'}
							mail={'mail@example'}
							//*Если нужно состояние просто добавляем текст
							// noOfficerText={'Ответственный сотрудник не назначен'}
							// noOfficerBtnText={'Назначить сотрудника'}
						/>
					}
				/>
			</div>
			<div className={styles.mainBlock_row}>
				{/* //! Данные о терминале */}
				<InfoBlock
					children={
						<MediumInfoBlock type={'smallInfo'} title={'Данные о терминале'} subTitle={'Терминал'} quota={''} adress={''} />
					}
				/>
				{/* //! Даты создания и завершения */}
				<div onClick={handleOpenModalPlug} className={styles.w_100}>
					<InfoBlock
						children={
							<MediumInfoBlock
								noEditBtn
								childrenInputs={<StartEndDateComponent columnType title={'Даты создания заявок'} />}
							/>
						}
					/>
				</div>
				{/* //! Ответственный менеджер */}
				<div onClick={handleOpenModalPlug} className={styles.w_100}>
					<InfoBlock
						children={
							<MediumInfoBlock
								type={'full'}
								title={'Ответственный менеджер'}
								subTitle={'Петров Петр Константинович'}
								telegram={'@23telega'}
								whatsapp={'+7 (923) 699 -94-99'}
								number={'+7 (923) 699 -94-99'}
								mail={'konstMail@example'}
								//*Если нужно состояние просто добавляем текст
								noOfficerText={'Ответственный сотрудник не назначен'}
								noOfficerBtnText={'Назначить сотрудника'}
							/>
						}
					/>
				</div>
			</div>
			<div className={styles.mainBlock_row}>
				{/* //! Линии */}
				<InfoBlock
					children={
						<SmallInfoBlock
							title={'Линии'}
							key1={'Admiral'}
							value1={'26'}
							key2={'Arkas'}
							value2={'26'}
							key3={'ARRC'}
							value3={'26'}
						/>
					}
				/>
				{/* //! Транспорт */}
				<InfoBlock
					children={
						<SmallInfoBlock
							title={'Транспорт'}
							key1={'Автомобиль'}
							value1={'26'}
							key2={'Перевозчики'}
							value2={'26'}
							key3={'Вагоны'}
							value3={'26'}
						/>
					}
				/>
				{/* //! Контейнеры */}
				<InfoBlock
					children={
						<SmallInfoBlock
							title={'Контейнеры'}
							key1={'Общее количество'}
							value1={'26'}
							key2={'Связано с транспортом'}
							value2={'26'}
							moreInfoContainer={moreInfoContainer}
						/>
					}
				/>
			</div>
		</div>
	);
}

export default MainOrderInfo;
