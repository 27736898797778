import { getOneUser } from "../../api/erp/getOneUser";
import { delUser } from "../../api/erp/deleteUser";
import { putUser } from "../../api/erp/putUser";
import { postNewClient } from "../../api/erp/postClient";
import { putClient } from "../../api/erp/putClient";

export const getOneUserApi = (userId) => {
    return async (dispatch) => {
        try {
            const response = await getOneUser(userId);
            dispatch(getOneUserSuccess(response.data));

        } catch (error) {
            throw error;
        }
    }
}

export const postClient = (adressId, requestData) => {
    return async (dispatch) => {
        try {
            const response = await postNewClient(adressId, requestData);
            dispatch(postClientSuccess(response.data));
        } catch (error) {
            throw error;
        }
    };
};

export const deleteUser = (userId) => {
    return async (dispatch) => {
        try {
            const response = await delUser(userId);
            dispatch(deleteUserSuccess(response.data));
        } catch (error) {
            throw error;
        }
    };
};

export const putUserAction = (adressId, requestData) => {
    return async (dispatch) => {
        try {
            const response = await putUser(adressId, requestData);
            dispatch(putUserSuccess(response.data));
        } catch (error) {
            throw error;
        }
    };
};

export const putClientAction = (clientId, requestData) => {
    return async (dispatch) => {
        try {
            const response = await putClient(clientId, requestData);
            dispatch(putClientSuccess(response.data));
        } catch (error) {
            throw error;
        }
    };
};

export const putUserSuccess = (payload) => ({
    type: 'PUT_USER_SUCCESS',
    payload,
});

export const postClientSuccess = (payload) => ({
    type: 'POST_CLIENT_SUCCESS',
    payload,
});

export const deleteUserSuccess = (payload) => ({
    type: 'DELETE_USER_SUCCESS',
    payload,
});

export const getOneUserSuccess = (payload) => ({
    type: 'GET_ONEUSER_SUCCESS',
    payload,
})

export const putClientSuccess = (payload) => ({
    type: 'PUT_CLIENT_SUCCESS',
    payload,
});