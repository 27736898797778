import styles from '../AccountEmpl.module.scss';
import { handleLogOut } from '../../logOut';
import Header from '../components/Header/Header';
import { UniversalButton } from '../../../../components';
import { useEffect } from 'react';
import { postLoginUser } from '../../../../store/slices/loginSlice';
import { useDispatch } from 'react-redux';

function AccountEmplMain({ userPermissions }) {
	const dispatch = useDispatch();

	useEffect(() => {
		let requestData;
		requestData = {
			username: 'demo-user',
			password: 'SteizaDemoUser123',
		};
		dispatch(postLoginUser({ requestData }));
	}, []);

	return (
		<div className={styles.account__wrap}>
			<Header text={'Главная'} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					{/* <UniversalButton label={"Выйти"} color={"primary"} onClick={handleLogOut} size={"large"} typeButton={"button"} /> */}
				</div>
			</div>
		</div>
	);
}

export default AccountEmplMain;
