import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconArchive({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 13.33 12">
				<path
					className="cls-1"
					d="M12.46,4h-8a.34.34,0,0,0-.34.34V5h8.67V4.31A.34.34,0,0,0,12.46,4Zm1.33,1.18V4.31A1.34,1.34,0,0,0,12.46,3h-8A1.34,1.34,0,0,0,3.12,4.31v.84a1.32,1.32,0,0,0-.66,1.16v2A.66.66,0,0,0,1.79,9v4a2,2,0,0,0,2,2h9.33a2,2,0,0,0,2-2V7.64A.67.67,0,0,0,14.46,7V6.31A1.33,1.33,0,0,0,13.79,5.15ZM3.79,6h9.33a.34.34,0,0,1,.34.34V7H8a2,2,0,0,0-1.41.59l-.75.75H3.46v-2A.33.33,0,0,1,3.79,6ZM6.2,9.31,6.5,9l.74-.74A1,1,0,0,1,8,8h6.17v5a1,1,0,0,1-1,1H3.79a1,1,0,0,1-1-1V9.31H6.2Zm3.59,1.16h2.67v-1H9.79Z"
					transform="translate(-1.79 -2.97)"
					fill={IconColor(color)}
				/>
				<path
					className="cls-2"
					d="M2.7,1h7.86a.36.36,0,0,1,.36.36V2a0,0,0,0,1,0,0H2.33a0,0,0,0,1,0,0V1.36A.36.36,0,0,1,2.7,1Z"
					fill="#ffffff"
				/>
			</svg>
		</div>
	);
}

export default IconArchive;
