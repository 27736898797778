import React, { memo, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/grid';

import './SliderForTerminals.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTerminal } from "../../store/slices/terminalPageSlice";
import { IconContainerCar } from "../Icons";

const SliderForTerminals = memo(function SliderForTerminals({
  data,
  // group,
  // setGroup,
  setSliderCont,
}) {
  const [active, setActive] = useState(false);
  const dispatch = useDispatch()
  const { currentTerminalId } = useSelector((state) => state.terminalPage)

  function handleSlide(e) {
    dispatch(setCurrentTerminal(e.currentTarget.id))
    setActive(!active);
    // setGroup(e.currentTarget.id);
    if (setSliderCont) {
      setSliderCont(e.currentTarget.id);
    }
  }



  return (
    <div className="swiper_wrapper">
      <Swiper spaceBetween={8} slidesPerView={'auto'}>
        {data?.map((el, ind) => (
          <SwiperSlide
            key={ind}
            id={el.id}
            onClick={(e) => handleSlide(e)}
            className={currentTerminalId == el.id ? 'active' : ''}
          >
            <div className="sliderForTerminals_wrapper">
              <div className="sliderForTerminals_description">
                <div className="sliderForTerminals_name">{el.name}</div>
                <div className="sliderForTerminals_capacity">
                  <IconContainerCar
                    color={
                      currentTerminalId == el.id ? 'primary_white' : 'black'
                    }
                  />{' '}
                  {`${el.teu_in}/${el.max_teu}`}
                </div>
              </div>
              <div className="sliderForTerminals_notifications">
                <div className="sliderForTerminals_notifications_positive">
                  {/* //TODO - хардкод пока нет данных {el.positiveNotifications} */}
                  20
                </div>
                <div className="sliderForTerminals_notifications_negative">
                  {/* //TODO - хардкод пока нет данных {el.negativeNotifications} */}
                  8
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
});

export default SliderForTerminals;
