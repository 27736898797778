import { Preloader, Search, Slider } from '../../../../../components';
import { useEffect } from 'react';
import styles from './Layouts.module.scss';
import ContentUsers from '../ContentUsers/ContentUsers';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { getGroupsUsers } from '../../../../../store/actions/groupsUsers';
import { RequireUIFunc } from '../../../../../hoc/RequireUI';

function LayoutUsers({
	// users,
	searchValue,
	setSearchValue,
}) {
	const dispatch = useDispatch();
	const { userList, getUsers_pending } = useSelector((state) => state.user);
	const { sideBarActive } = useSelector((state) => state.sideBar);
	// отслеживание ширины окна
	const isDesktop = useMediaQuery({ minWidth: 639.9 });
	const permViewGrops = RequireUIFunc({ name: 'ViewGroups' }); // право на запрос просмотра групп
	//! Получение групп пользователей

	useEffect(() => {
		if (permViewGrops) {
			dispatch(getGroupsUsers()); //запрос отправляется если есть право на просмотр групп пользователей
		}
	}, []);

	// const filteredUsers = users.filter(user => {
	//     return user.full_name.toLowerCase().includes(searchValue.toLowerCase())
	// })

	return (
		<div className={styles.layout}>
			<div className={styles.layout__stack}>
				<div className={`${styles.layout__content} ${sideBarActive ? styles.layout__content_withSidebar : ''}`}>
					<div className={styles.search}>
						<Search searchValue={searchValue} setSearchValue={setSearchValue} />
					</div>

					<div className={styles.content}>
						<ContentUsers type={'users'} items={userList} />
						{getUsers_pending ? (
							<div className={styles.preloader_inwrapper}>
								<Preloader />
							</div>
						) : (
							''
						)}

						{/* // !Не убирать логика модалки с правами */}
						{/* {isDesktop && rights ? (
              <div
                className={`${styles.content__card} ${rights ? styles.showRights : ''
                  }`}
              >
                <ModalRights
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  // flName={flName}
                  newInfo={newInfo}
                  // setFlname={setFlname}
                  userName={userName}
                  setUserName={setUserName}
                  selectedId={selectedId}
                  // setSelectedId={setSelectedId}
                  handlePutUser={handlePutUser}
                  inputChange={inputChange}
                  setInputChange={setInputChange}
                  setRights={setRights}
                  dataUser={dataUser}
                  handleCloseModal={handleCloseModal}
                  // handlePutBaseUser={handlePutBaseUser}
                  rools={rools}
                  setRools={setRools}
                  register={register}
                  setFocus={setFocus}
                  setValue={setValue}
                  handleSubmit={handleSubmit}
                  errors={errors}
                />
              </div>
            ) : null} */}
					</div>
				</div>
			</div>
			<div className={styles.layout__footer}>
				<div className={styles.page__block}>
					<p>На странице:</p>
					<div className={styles.page__items}>{userList.length}</div>
				</div>
			</div>
		</div>
	);
}

export default LayoutUsers;
