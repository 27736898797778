import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import './assets/styles/mainStyles.scss';
import AuthClient from './pages/Login/Auth/AuthClient/AuthClient';
import AuthEmpl from './pages/Login/Auth/AuthEmpl/AuthEmpl';
import Recovery from './pages/Login/Auth/AuthEmpl/Recovery';
import RegClient from './pages/Login/Reg/RegClient/RegClient';
import ManRegClient from './pages/Login/Reg/ManRegClient/ManRegClient';
import AdminReg from './pages/Login/Reg/AdminReg/AdminReg';
import Landing from './pages/Landing/Landing';
import RequireAuth from './hoc/RequireAuth';
import { useDispatch } from 'react-redux';
import { getGroupsUsers } from './store/actions/groupsUsers';
import AccountClientMain from './pages/Account/AccountClient/pages/AccountClientMain';
import AccountClientContainers from './pages/Account/AccountClient/pages/AccountClientContainers';
import AccountEmplMain from './pages/Account/AccountEmpl/pages/AccountEmplMain';
import AccountEmplEmpls from './pages/Account/AccountEmpl/pages/AccountEmplEmpls';
import AccountEmplClients from './pages/Account/AccountEmpl/pages/AccountEmplClients';
import AccountEmplContainers from './pages/Account/AccountEmpl/pages/AccountEmplContainers';
import AccountEmplCreateOrder from './pages/Account/AccountEmpl/pages/AccountEmplCreateOrder';
import AccountEmplAddCar from './pages/Account/AccountEmpl/pages/AccountEmplAddCar';
import RegTransporter from './pages/Login/Reg/RegTransporter/RegTransporter';
import AccountEmplTransporters from './pages/Account/AccountEmpl/pages/AccountEmplTransporters';
import AccountEmplOrders from './pages/Account/AccountEmpl/pages/AccountEmplOrders';
import Layout from './pages/Account/Layout/Layout';
import AccountEmplTerminals from './pages/Account/AccountEmpl/pages/AccountEmplTerminals';
import AccountEmplCreateTerminal from './pages/Account/AccountEmpl/pages/AccountEmplCreateTerminal';
import OrderPage from './pages/Account/AccountEmpl/pages/AccountEmplOrderPage';
import AccountEmplJobTitle from './pages/Account/AccountEmpl/pages/AccountEmplJobTitle';
import AccountEmplContainerAcceptance from './pages/Account/AccountEmpl/pages/AccountEmplContainerAcceptance';
import AccountEmplReceptionTransmission from './pages/Account/AccountEmpl/pages/AccountEmplReceptionTransmission';
import ActComponent from './pages/Account/AccountEmpl/components/ReceptionTransmission/components/ActComponent/ActComponent';
import AccountOneClient from './pages/Account/AccountEmpl/pages/AccountOneClient';
import AccountEmplTableEntries from './pages/Account/AccountEmpl/pages/AccountEmplTableEntries';
import DraftAct from './pages/Account/AccountEmpl/components/ReportContainers/DraftAct';
import Policy from './pages/Landing/Policy';

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}, []);

	useEffect(() => {
		if (
			window.location.href === 'http://localhost:3000/adminReg' ||
			window.location.href === 'http://localhost:3000/accountEmpl/empls'
		) {
			dispatch(getGroupsUsers());
		}
	}, [dispatch]);

	return (
		<Router>
			<div className="App">
				<Routes>
					{/* главная страница */}
					{/* <Route path={'/'} element={<Landing />} /> */}
					<Route path={'/policy'} element={<Policy />} />

					{/* авторизации */}
					{/* <Route path={'/authClient'} element={<AuthClient />} /> */}
					<Route path={'/'} element={<AuthEmpl />} />
					{/* <Route path={'/recoveryEmpl'} element={<Recovery />} /> */}

					{/* регистрации */}
					{/* <Route path={'/regClient'} element={<RegClient />} />
					<Route path={'/regTransporter'} element={<RegTransporter />} />
					<Route path={'/manRegClient'} element={<ManRegClient />} />
					<Route path={'/adminReg'} element={<AdminReg />} /> */}

					{/* личный кабинет клиента */}
					{/* <Route
						path="/accountClient/*"
						element={
							<RequireAuth userType={2} link="/authClient">
								<Layout />
							</RequireAuth>
						}
					>
						<Route index path="main" element={<AccountClientMain />} />
						<Route path="summary" element={<AccountClientContainers />} />
					</Route> */}

					{/* <Route
            path={'/accountClient/main'}
            element={
              <RequireAuth userType={2} link="/authClient">
                <AccountClientMain />
              </RequireAuth>
            }
          />

          <Route
            path={'/accountClient/containers'}
            element={
              <RequireAuth userType={2} link="/authClient">
                <AccountClientContainers />
              </RequireAuth>
            }
          /> */}

					{/* личный кабинет работника */}
					<Route
						path="/accountEmpl/*"
						element={
							<RequireAuth userType={1} link="/accountEmpl/terminals">
								<Layout />
							</RequireAuth>
						}
					>
						{/* <Route index path="main" element={<AccountEmplMain />} /> */}
						<Route path="summary" element={<AccountEmplContainers />} />
						{/* выбор контейнеров */}
						<Route path="containerAcceptance" element={<AccountEmplContainerAcceptance />} />
						<Route path="containerAcceptance">
							<Route path=":reportId" element={<DraftAct />} />
						</Route>
						<Route path="clients" element={<AccountEmplClients />} />
						<Route path="clients">
							<Route path=":clientId" element={<AccountOneClient />} />
						</Route>
						<Route path="empls" element={<AccountEmplEmpls />} />
						<Route path="cars" element={<AccountEmplAddCar />} />
						<Route path="transporters" element={<AccountEmplTransporters />} />
						<Route index path="terminals" element={<AccountEmplTerminals />} />
						<Route path="orders" element={<AccountEmplOrders />} />
						<Route path="orders">
							<Route path=":terminalId">
								<Route path=":orderId" element={<OrderPage />} />
							</Route>
						</Route>
						<Route path="createImportOrder" element={<AccountEmplCreateOrder type={'import'} />} />
						<Route path="createExportOrder" element={<AccountEmplCreateOrder type={'export'} />} />
						<Route path="createTerminal" element={<AccountEmplCreateTerminal />} />
						<Route path="createJob" element={<AccountEmplJobTitle />}></Route>
						<Route path="receptionTransmission/:confirmed" element={<AccountEmplReceptionTransmission />} />
						<Route path="receptionTransmission">
							<Route path="act/:actId" element={<ActComponent />} />
						</Route>
						<Route path="tableEntries" element={<AccountEmplTableEntries />} />
					</Route>
				</Routes>
			</div>
		</Router>
	);
}

export default App;
