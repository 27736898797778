import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import '../../../../../../assets/styles/Tables.scss';
import { MRT_EditActionButtons, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useDispatch, useSelector } from 'react-redux';
import ModalEditEntries from './ModalEditEntries';
import { Modal, Preloader, SeparateModal, UniversalButton } from '../../../../../../components';
import { useMediaQuery } from 'react-responsive';
import {
	IconCross,
	IconEdit,
	IconEyeClose,
	IconFilter,
	IconMaxmize,
	IconSearch,
	IconSettings,
	IconMinimize,
} from '../../../../../../components/Icons';
import {
	getAllEntries,
	setEntriesFetching,
	putEntriesList,
	setOrdering,
	setGlobalSearch,
	setFilters,
} from '../../../../../../store/slices/entriesSlice';
import MainInput from '../../../../../../components/Inputs/MainInput/MainInput';
import TimeDatePicker from '../../../../../../components/Order/PassComponent/TimeDatePicker/TimeDatePicker';
import { useForm } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { Alert, Snackbar } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';
// Иконка календаря для фильтрации по датам
const IconCalendar = createSvgIcon(
	<svg width="14" height="14" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.3333 2.99984V4.33317H11.3333V2.99984H12C12.5523 2.99984 13 3.44755 13 3.99984V5.34359L3 5.34359V3.99984C3 3.44755 3.44772 2.99984 4 2.99984H4.66667V4.33317H5.66667V2.99984L10.3333 2.99984ZM3 6.34359L3 11.9998C3 12.5521 3.44772 12.9998 4 12.9998H12C12.5523 12.9998 13 12.5521 13 11.9998V6.34359L3 6.34359ZM4 1.99984H4.66667V0.666504H5.66667V1.99984L10.3333 1.99984V0.666504H11.3333V1.99984H12C13.1046 1.99984 14 2.89527 14 3.99984V11.9998C14 13.1044 13.1046 13.9998 12 13.9998H4C2.89543 13.9998 2 13.1044 2 11.9998V3.99984C2 2.89527 2.89543 1.99984 4 1.99984Z"
			fill="#ffffff"
		/>
	</svg>,
	'IconCalendar',
);

// иконки
const fontAwesomeIcons = {
	FullscreenIcon: () => <IconMaxmize size={'largeS'} color={'white'} />,
	FullscreenExitIcon: () => <IconMinimize size={'largeS'} color={'white'} />,
	ViewColumnIcon: () => <IconSettings size={'largeS'} color={'white'} />,
	FilterListIcon: () => <IconFilter size={'largeS'} color={'white'} />,
	FilterListOffIcon: () => <IconFilter size={'largeS'} color={'white'} />,
	SearchIcon: () => <IconSearch size={'largeS'} color={'white'} />,
	SearchOffIcon: () => <IconSearch size={'largeS'} color={'white'} />,
	ArrowDropDownIcon: () => <IconSearch size={'largeS'} color={'white'} />,
	ClearAllIcon: () => <IconSearch size={'largeS'} color={'white'} />,
	SortIcon: (props) => <IconSearch size={'largeS'} color={'white'} {...props} />,
	VisibilityOffIcon: () => <IconEyeClose size={'largeS'} />,
	RestartAltIcon: () => <IconSearch size={'largeS'} color={'white'} />,
};

const TableEntries = ({ rowSelection, setRowSelection, columnVisibility, setColumnVisibility }) => {
	const dispatch = useDispatch();
	// отслеживание ширины окна
	const isMobile = useMediaQuery({ maxWidth: 639.9 });
	const isMobilePreloader = useMediaQuery({ maxWidth: 549.9 });
	const {
		entriesList,
		fetching,
		countEntriesList,
		getAllEntries_pending,
		putEntriesList_success,
		firstLoad,
		allStatuses,
		putEntriesList_error,
	} = useSelector((state) => state.entries);

	// прелоадер при бесконечном скролле
	const [isLoadingRows, setIsLoadingRows] = useState(false);
	const [params, setParams] = useState('');

	// бесконечная загрузка
	const tableContainerRef = useRef(null);

	// функция при бесконечной загрузке
	const fetchMoreOnBottomReached = useCallback((containerRefElement) => {
		if (containerRefElement) {
			const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
			if (scrollTop + clientHeight + 2 >= scrollHeight && scrollTop != 0) {
				dispatch(setEntriesFetching(true));
			} else {
				dispatch(setEntriesFetching(false));
			}
		}
	}, []);

	useEffect(() => {
		fetchMoreOnBottomReached(tableContainerRef.current);
	}, [fetchMoreOnBottomReached]);

	// Получение списка пропусков
	useEffect(() => {
		if (fetching) {
			dispatch(getAllEntries());
		}
	}, [fetching]);

	// Прелоадер в футере таблицы при дозагрузке
	useEffect(() => {
		if (getAllEntries_pending) {
			setIsLoadingRows(true);
		} else {
			setIsLoadingRows(false);
		}
	}, [getAllEntries_pending]);

	// колонки
	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => row.name,
				id: 'name',
				header: '№ Пропуска',
				size: 200,

				enableHiding: false,
				muiFilterTextFieldProps: { placeholder: 'Введите №' },

				// Скрыть в меню
				visibleInShowHideMenu: false,

				// Редактирование
				enableEditing: false,
				Edit: () => null,
			},
			{
				accessorFn: (row) => row.status_display,
				id: 'status_display',
				header: 'Статус пропуска',
				size: 240,

				muiFilterTextFieldProps: { placeholder: 'Выберите статус' },
				filterSelectOptions: allStatuses['status']?.map((el) => el.name),
				filterVariant: 'select',
				sortDescFirst: true,

				enableEditing: false,
				Edit: () => null,
			},
			{
				accessorFn: (row) => row.order,
				id: 'order_number',
				header: '№ Заявки',
				size: 192,

				muiFilterTextFieldProps: {
					placeholder: 'Введите №',
				},

				enableEditing: false,
				Edit: () => null,
			},
			{
				accessorFn: (row) => row.container.container_number,
				id: 'container_number',
				header: '№ Контейнера',
				size: 232,

				muiFilterTextFieldProps: {
					placeholder: 'Введите №',
				},

				enableEditing: false,
				Edit: () => null,
			},
			{
				accessorFn: (row) => row.transporter_display,
				id: 'transporter_org',
				header: 'Перевозчик',
				size: 230,

				muiFilterTextFieldProps: {
					placeholder: 'Введите название',
				},

				enableEditing: false,
				Edit: () => null,
			},
			{
				accessorFn: (row) => row.vehicle_display,
				id: 'vehicle_number',
				header: 'Автомобиль',
				size: 200,

				muiFilterTextFieldProps: {
					placeholder: 'Введите №',
				},

				enableEditing: false,
				Edit: () => null,
			},
			{
				accessorFn: (row) => row.vehicle_driver,
				id: 'vehicle_driver',
				header: 'Водитель',
				size: 200,

				muiFilterTextFieldProps: {
					placeholder: 'Введите ФИО',
				},
			},
			{
				accessorFn: (row) => row.vehicle_trailer_number,
				id: 'vehicle_trailer_number',
				header: 'Номер прицепа',
				size: 220,

				muiFilterTextFieldProps: {
					placeholder: 'Введите №',
				},
			},
			{
				accessorFn: (row) => row.seal_number,
				id: 'seal_number',
				header: 'Номер пломбы',
				size: 220,

				muiFilterTextFieldProps: {
					placeholder: 'Введите №',
				},
			},
			// {
			// 	accessorFn: (row) => row.terminal.contact_number,
			// 	id: 'contact_number',
			// 	header: 'Ответственный на терминале',
			// 	size: 350,

			// 	muiFilterTextFieldProps: {
			// 		placeholder: 'Введите',
			// 	},

			// 	enableEditing: false,
			// 	Edit: () => null,
			// },
			{
				accessorFn: (row) => row.eta_checkin && formatInitialDate(row.eta_checkin),
				id: 'eta_checkin',
				header: 'Ожидаемое время проезда',
				size: 340,

				filterVariant: 'date-range',

				Edit: (props) => {
					return <InputDate {...props} />;
				},
			},
		],
		[],
	);

	const [dataEntry, setDataEntry] = useState([]);

	// Отправка редактирования пропуска на сервер
	const handleSaveOneEntry = ({ values, row, table }) => {
		// Формирование requestData при изменении одного пропуска
		const data = { pass_pk: row.original.id };

		// Проверка значений на пустую строку
		function checkValue(el, valuesArr, dataArr) {
			if (valuesArr[el] === '') {
				dataArr[el] = null;
			} else {
				dataArr[el] = valuesArr[el];
			}
		}

		for (const key in values) {
			if (values.hasOwnProperty(key) && row.original.hasOwnProperty(key)) {
				if (key === 'eta_checkin') {
					if (row.original[key]) {
						if (values[key] !== formatInitialDate(row.original[key])) {
							checkValue(key, values, data);
						}
					} else {
						if (values[key] !== row.original[key]) {
							checkValue(key, values, data);
						}
					}
				} else {
					if (values[key] !== row.original[key]) {
						checkValue(key, values, data);
					}
				}
			}
		}

		// Если нет объекта то добавляем, если есть то заменяем
		if (dataEntry.length === 0) {
			dataEntry.push(data);
		} else {
			dataEntry[0] = data;
		}

		dataEntry.forEach((obj) => {
			// Если ничего не изменилось то просто закрываем модальное окно
			if (Object.keys(obj).length === 1) {
				table.setEditingRow(null);
			} else {
				dispatch(
					putEntriesList({
						terminal_id: row.original.terminal.id,
						order_id: row.original.order,
						requestData: dataEntry,
					}),
				);
				setDataEntry([]);
				setOpenToast(true);
				table.setEditingRow(null);
			}
		});
	};

	// Уведомление
	const [openToast, setOpenToast] = useState(false);

	// Логика закрытия тоста
	const handleClose = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenToast(false);
	};

	// выбор пропусков
	const [selectEntries, setSelectEntries] = useState([]);

	// модальное окно для редактирования множества пропусков
	const [showModalEditEntries, setShowModalEditEntries] = useState(false);
	const handleCloseModal = () => {
		setShowModalEditEntries(false);
	};

	// редактирование параметров на запросы
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState();
	const [sorting, setSorting] = useState([]);

	// сортировка
	useEffect(() => {
		if (sorting) {
			dispatch(setOrdering(sorting));
			dispatch(setEntriesFetching(true));
			setSelectEntries([]);
			setRowSelection({});
		}
	}, [sorting]);

	// фильтрация в колонках
	useEffect(() => {
		if (columnFilters) {
			dispatch(setFilters(columnFilters));
			dispatch(setEntriesFetching(true));
			setSelectEntries([]);
			setRowSelection({});
		}
	}, [columnFilters]);

	// поиск
	useEffect(() => {
		dispatch(setGlobalSearch(globalFilter));
		dispatch(setEntriesFetching(true));
		setSelectEntries([]);
		setRowSelection({});
	}, [globalFilter]);

	const table = useMaterialReactTable({
		data: entriesList,
		columns,
		enableSorting: !getAllEntries_pending,
		initialState: {
			showGlobalFilter: true,
			// порядок колонок
			columnOrder: [
				'mrt-row-select',
				'mrt-row-actions',
				'name',
				'status_display',
				'order_number',
				'container_number',
				'transporter_org',
				'vehicle_number',
				'vehicle_driver',
				'vehicle_trailer_number',
				'seal_number',
				// 'contact_number',
				'eta_checkin',
			],
		},

		// сортировка, фильтрация и поиск через сервер
		manualFiltering: true,
		manualSorting: true,
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		state: {
			rowSelection,
			columnFilters,
			globalFilter,
			sorting,
			columnVisibility,
		},

		// Календарь в фильтрации по датам
		muiFilterDatePickerProps: {
			slots: { openPickerIcon: IconCalendar },
		},

		// Отслеживание скрытия колонок
		onColumnVisibilityChange: setColumnVisibility,

		// фильтрация в колонках
		muiFilterTextFieldProps: {
			sx: {
				'> div': {
					fontFamily: 'Montserrat',
				},

				'> div::before': {
					borderBottom: '1px solid #E4DFFF !important',
				},

				'> div::after': {
					borderBottom: '1px solid #E4DFFF !important',
				},

				'> div div': {
					opacity: '1',
					color: '#F3EEFF',
					fontSize: '12px',
					fontWeight: '400',
					lineHeight: '20px',
				},

				input: {
					padding: '2px 0 4px',

					'&::placeholder': {
						opacity: '1',
						color: '#F3EEFF',
						fontSize: '12px',
						fontWeight: '400',
						fontFamily: 'Montserrat',
						lineHeight: '20px',
					},
				},

				'button svg': {
					color: '#AEAEB2',
				},

				'> div > svg': {
					color: '#0053DA',
				},
			},
		},

		// стиль контейнера таблицы
		muiTablePaperProps: {
			sx: {
				backgroundColor: '#ffffff',
				borderRadius: '24px',
			},
		},

		// отключить количество строк на странице
		enablePagination: false,

		// смена порядка колонок
		enableColumnOrdering: true,

		// русский язык
		localization: MRT_Localization_RU,

		// менять размер колонки кнопка в самой колонке
		enableColumnResizing: true,
		columnResizeMode: 'onChange',
		layoutMode: 'grid',

		// поисковая строка
		positionGlobalFilter: 'left',
		muiSearchTextFieldProps: {
			placeholder: 'Начните писать, например: NVNH349912, ECON, FESCO',
		},

		// изменение иконок
		icons: fontAwesomeIcons,

		// убрать кнопку меняющую ширину колонок в хедере
		enableDensityToggle: false,

		// выбор строк
		enableRowSelection: true,
		positionToolbarAlertBanner: 'bottom',
		onRowSelectionChange: setRowSelection,
		getRowId: (row) => row.id,

		// редактирование строк
		enableEditing: true,
		createDisplayMode: 'modal',
		editDisplayMode: 'modal',

		// хедер
		muiTableHeadCellProps: {
			sx: {
				backgroundColor: '#274B7E',
				color: '#fffbff',

				'div > span > span > svg.MuiTableSortLabel-icon': {
					opacity: '1 !important',
					color: '#fffbff !important',
					fill: '#fffbff !important',
				},

				'div span span.MuiTableSortLabel-root': {
					opacity: '1 !important',
				},
			},
		},

		muiTopToolbarProps: {
			sx: {
				backgroundColor: '#fffbff',
				minHeight: '60px',

				'div:first-of-type': {
					width: '100%',
					padding: '0px',
				},

				'div:first-of-type.MuiInputAdornment-root': {
					width: 'auto !important',
					marginLeft: '4px',

					button: {
						padding: '0px',
						color: '#ffffff',
					},
				},

				'div:nth-of-type(2)': {
					alignItems: 'center',
					height: '100%',
				},

				'div:nth-of-type(2) > span': {
					display: 'none',
				},

				'div:nth-of-type(2) > div': {
					gap: '8px',
				},

				'div:nth-of-type(2) > .MuiCollapse-root': {
					width: '100%',
					borderRadius: '8px',
					background: '#0053DA',
					border: 'none',
					padding: '2px 2px 2px 4px',

					svg: {
						background: '#0040A7',
						borderRadius: '8px',
						width: '30px',
						height: '28px',
						marginRight: '4px',
						padding: '6px',
					},

					'& input': {
						borderRadius: '8px',
						background: '#fffbff',
						padding: '6px 12px',
						color: '#000000',
						width: '100%',
						fontFamily: 'Montserrat',
						fontSize: '14px',
						lineHeight: '20px',
					},

					'& fieldset': {
						border: 'none',
					},
				},

				button: {
					borderRadius: '8px',
					background: '#0053DA',
					padding: '8px',

					i: {
						color: '#ffffff',
					},
				},

				'button:focus, button:active, button:hover': {
					background: '#0053DA',
				},
			},
		},

		// липкий хедер
		enableStickyHeader: true,

		// высота тела таблицы
		muiTableContainerProps: {
			ref: tableContainerRef,
			sx: {
				maxHeight: 'calc(100vh - 270px)',
				minHeight: 'calc(100vh - 270px)',

				'@media(min-width: 1279.9px)': {
					maxHeight: 'calc(100vh - 230px)',
					minHeight: 'calc(100vh - 230px)',
				},
			},

			onScroll: (event) => fetchMoreOnBottomReached(event.target),
		},

		// кнопка редактирования
		renderRowActions: ({ row, table }) => (
			<UniversalButton
				icon={<IconEdit size={'medium'} color={'primary-active'} />}
				onClick={() => table.setEditingRow(row)}
				size={'large'}
				type={'with-border'}
				color={'primary'}
			/>
		),

		// модальное окно
		renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
			<div className="modalEditEntries">
				<div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '4px 16px' }}>
						<UniversalButton
							icon={<IconCross size={'large'} color={'black'} />}
							onClick={() => {
								table.setEditingRow(null);
							}}
							size={'large'}
							typeButton={'button'}
						/>
					</div>
					<div className="modalEdit">
						<div className="modalEdit__header">
							<div className="modalEdit__header_info">
								<p>Пропуск {row.original.name}</p>
							</div>
						</div>
						<div className="modalEdit__body">
							<div className="modalEdit__body_inputs">{internalEditComponents}</div>
						</div>
						<div className="modalEdit__footer">
							<MRT_EditActionButtons variant="text" table={table} row={row} />
						</div>
					</div>
				</div>
			</div>
		),

		// функция для сохранения изменений
		onEditingRowSave: handleSaveOneEntry,

		// футер таблицы
		muiBottomToolbarProps: {
			sx: {
				minHeight: 'none',
				background: '#274B7E',

				'div:nth-of-type(2)': {
					padding: '0px',
				},
			},
		},

		renderBottomToolbarCustomActions: ({ table }) => (
			<div className="table__footer">
				<div></div>
				{countEntriesList === 0 ? (
					<div>Нет результатов</div>
				) : isMobilePreloader ? (
					<div className="table__footer_preloader">
						{isLoadingRows ? <Preloader small={true} /> : `	Загружено ${entriesList?.length} из ${countEntriesList}`}
					</div>
				) : (
					<div className="table__footer_preloader">
						{isLoadingRows && <Preloader small={true} />}
						Загружено {entriesList?.length} из {countEntriesList}
					</div>
				)}
			</div>
		),

		// сообщение сколько выбрано строк
		muiToolbarAlertBannerProps: {
			sx: {
				position: 'relative',
				'> div': {
					position: 'absolute',
					left: '1px',
					top: '-1px',
					color: '#fffbff',

					button: {
						display: 'none',
					},
				},

				'@media(max-width: 550px)': {
					'> div': {
						top: '12px',
						left: '17px',
					},

					'> div > div': {
						padding: '8px',
						fontSize: '12px',
					},
				},
			},
		},
	});

	return (
		<>
			{firstLoad ? (
				<>
					{putEntriesList_success && (
						<Snackbar
							open={openToast}
							onClose={handleClose}
							autoHideDuration={3000}
							anchorOrigin={isMobile ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'left' }}
						>
							<Alert severity="success" sx={{ width: '100%' }}>
								Данные пропускa успешно обновлены
							</Alert>
						</Snackbar>
					)}
					{putEntriesList_error && (
						<Snackbar
							open={openToast}
							onClose={handleClose}
							autoHideDuration={3000}
							anchorOrigin={isMobile ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'left' }}
						>
							<Alert severity="error" sx={{ width: '100%' }}>
								Данные пропускa не обновлены
							</Alert>
						</Snackbar>
					)}
					{showModalEditEntries ? (
						isMobile ? (
							<div className="modalEditСontainers__wrapper">
								<Modal
									onClose={handleCloseModal}
									children={<ModalEditEntries handleCloseModal={handleCloseModal} selectEntries={selectEntries} />}
								/>
							</div>
						) : (
							<div className="modalEditСontainers__wrapper">
								<SeparateModal
									btnIcon={
										<UniversalButton
											icon={<IconCross size={'large'} color={'black'} />}
											onClick={handleCloseModal}
											size={'large'}
											typeButton={'button'}
										/>
									}
									onClose={handleCloseModal}
									children={<ModalEditEntries handleCloseModal={handleCloseModal} selectEntries={selectEntries} />}
								/>
							</div>
						)
					) : null}
					<div className="table__container">
						<MaterialReactTable table={table} />
					</div>
				</>
			) : (
				<Preloader bg={'true'} />
			)}
		</>
	);
};

export default TableEntries;

// Инпут даты с календарем
const InputDate = (props) => {
	const { cell, column, row, table } = props;
	const { register, setFocus, formState, setValue } = useForm({});
	const { errors } = formState;
	const [startDate, setStartDate] = useState('');
	const [valueDate, setValueDate] = useState(() => cell.getValue());
	const handleChangeTime = () => {};

	useEffect(() => {
		if (startDate) {
			setValueDate(formatEditedDate(startDate));
			row._valuesCache[column.id] = format(startDate, "yyyy-MM-dd'T'HH:mm:ssXXX");
		}
	}, [startDate]);

	return (
		<div className="timeInput timeInputTable">
			<MainInput
				title={'Ожидаемое время проезда'}
				errorTitle={'Некорректное время проезда'}
				name="time"
				register={register}
				value={valueDate ? valueDate : ''}
				handleFieldChange={handleChangeTime}
				setValue={setValue}
				setFocus={setFocus}
				errors={errors}
				rules={{
					minLength: {
						value: 2,
						message: 'Минимум 2 символа',
					},
				}}
				placeholder={'Выберите ожидаемое время проезда'}
				disabled={true}
			/>
			<TimeDatePicker startDate={startDate} setStartDate={setStartDate} />
		</div>
	);
};

// Форматирование измененной даты для показа в инпуте
function formatEditedDate(dateString) {
	// Создаем объект Date из строки
	const date = new Date(dateString);

	// Форматируем день, месяц и год
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();

	// Форматируем часы и минуты
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');

	// Собираем конечную строку
	const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}`;

	return formattedDate;
}

// Форматирование начальной даты для показа в инпуте
function formatInitialDate(dateString) {
	// Парсинг строки даты в объект Date
	const date = parseISO(dateString);

	// Форматируем дату
	const formattedDate = format(date, 'dd.MM.yyyy HH:mm');

	// Собираем финальную строку
	const result = `${formattedDate}`;

	return result;
}
