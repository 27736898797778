import { useEffect, useState } from 'react';
import '../../../../../../assets/styles/Tables.scss';
import { Switcher, UniversalButton } from '../../../../../../components';
import ModalEditSelect from './ModalEditSelect';
import { useDispatch } from 'react-redux';
import { putBulkContainers } from '../../../../../../store/slices/allContainersSlice';
import ModalEditInput from './ModalEditInput';

function ModalEditContainers({ handleCloseModal, selectContainers, allLines, allSizes, allTypes, allStatuses, setOpenToast }) {
	const dispatch = useDispatch();

	// объекты со значениями выбранных контейнеров
	const [lineArray, setLineArray] = useState();
	const [sizeArray, setSizeArray] = useState();
	const [typeArray, setTypeArray] = useState();
	const [statusArray, setStatusArray] = useState();
	const [damaged, setDamaged] = useState();
	const [dangerous, setDangerous] = useState();
	const [filled, setFilled] = useState();
	const [booking, setBooking] = useState();
	const [bookingArray, setBookingArray] = useState();

	useEffect(() => {
		setLineArray(selectContainers?.map((elem, index) => elem.line_display));
		setSizeArray(selectContainers?.map((elem, index) => elem.size_display));
		setTypeArray(selectContainers?.map((elem, index) => elem.type_display));
		setStatusArray(selectContainers?.map((elem, index) => elem.status_display));
		setBookingArray(selectContainers?.map((elem, index) => elem.booking));
		setFilled(selectContainers?.map((elem, index) => elem.is_filled));
		setDamaged(selectContainers?.map((elem, index) => elem.is_damaged));
		setDangerous(selectContainers?.map((elem, index) => elem.is_dangerous));
	}, [selectContainers]);

	// отправка редактирования контейнеров на сервер
	const [dataContainer, setDataContainer] = useState({});

	const handleSelectedOptionChangeType = (selectedOption) => {
		if (selectedOption === 'Разное' || selectedOption === '') {
		} else {
			if (typeArray.every((element) => element === String(selectedOption))) {
				delete dataContainer.type;
			} else {
				dataContainer['type'] = allTypes.find((item) => item.name == selectedOption)?.id;
			}
		}
	};

	const handleSelectedOptionChangeLine = (selectedOption) => {
		if (selectedOption === 'Разное' || selectedOption === '') {
		} else {
			if (lineArray.every((element) => element === String(selectedOption))) {
				delete dataContainer.line;
			} else {
				if (Array.isArray(allLines)) {
					dataContainer['line'] = allLines.find((item) => item.line_name == selectedOption)?.id;
				}
			}
		}
	};

	const handleSelectedOptionChangeSize = (selectedOption) => {
		if (selectedOption === 'Разное' || selectedOption === '') {
		} else {
			if (sizeArray.every((element) => element === String(selectedOption))) {
				delete dataContainer.size;
			} else {
				dataContainer['size'] = allSizes.find((item) => item.name == selectedOption)?.id;
			}
		}
	};

	const handleSelectedOptionChangeStatus = (selectedOption) => {
		if (selectedOption === 'Разное' || selectedOption === '') {
		} else {
			if (statusArray.every((element) => element === String(selectedOption))) {
				delete dataContainer.status;
			} else {
				dataContainer['status'] = allStatuses.find((item) => item.name == selectedOption)?.id;
			}
		}
	};

	useEffect(() => {
		if (!booking) {
			if (Array.isArray(bookingArray) && bookingArray.every((element) => element === '' || element === null)) {
				delete dataContainer.booking;
			} else {
				dataContainer['booking'] = '';
			}
		} else {
			if (bookingArray.every((element) => element === booking)) {
				delete dataContainer.booking;
			} else {
				dataContainer['booking'] = booking;
			}
		}
	}, [booking]);

	// переключатели
	const [isCheckedDamaged, setCheckedDamaged] = useState(false);
	const [isCheckedDangerous, setCheckedDangerous] = useState(false);
	const [isCheckedFilled, setCheckedFilled] = useState(false);

	const handleCheckedFilled = (newChecked) => {
		dataContainer['is_filled'] = newChecked;
		setCheckedFilled(newChecked);
	};

	const handleCheckedDamaged = (newChecked) => {
		dataContainer['is_damaged'] = newChecked;
		setCheckedDamaged(newChecked);
	};

	const handleCheckedDangerous = (newChecked) => {
		dataContainer['is_dangerous'] = newChecked;
		setCheckedDangerous(newChecked);
	};

	// Отправка запроса редактирования нескольких контейнеров
	const handleSaveContainers = () => {
		let container_ids = selectContainers.map(function (item) {
			return item.id;
		});
		dataContainer['container_ids'] = container_ids;
		if (Object.keys(dataContainer)?.length > 1) {
			dispatch(
				putBulkContainers({
					requestData: dataContainer,
				}),
			);
			setOpenToast(true);
			handleCloseModal();
		} else {
			handleCloseModal();
		}
	};

	return (
		<div className="modalEdit">
			<div className="modalEditСontainers">
				<div className="modalEditСontainers__container">
					<div>
						<div className="modalEditСontainers__header">
							Контейнеры
							<div className="modalEditСontainers__selectContainers">
								{selectContainers?.map((elem, index) => (
									<p key={index}>№{elem.container_number}</p>
								))}
							</div>
						</div>
						<div className="modalEdit__body">
							<form>
								<div className="modalEdit__body_inputs">
									{statusArray && (
										<ModalEditSelect
											name={'statuses'}
											label={'Статус'}
											options={allStatuses}
											values={statusArray}
											onSelectedOptionChange={handleSelectedOptionChangeStatus}
										/>
									)}
									{lineArray && (
										<ModalEditSelect
											name={'lines'}
											label={'Линия'}
											options={allLines}
											values={lineArray}
											elementName={'line_name'}
											onSelectedOptionChange={handleSelectedOptionChangeLine}
										/>
									)}
									{sizeArray && (
										<ModalEditSelect
											name={'sizes'}
											label={'Размер'}
											options={allSizes}
											values={sizeArray}
											onSelectedOptionChange={handleSelectedOptionChangeSize}
										/>
									)}
									{typeArray && (
										<ModalEditSelect
											name={'types'}
											label={'Тип'}
											options={allTypes}
											values={typeArray}
											onSelectedOptionChange={handleSelectedOptionChangeType}
										/>
									)}
									{bookingArray && (
										<ModalEditInput
											name="booking"
											value={booking}
											setValue={setBooking}
											arrayValues={bookingArray}
											label="Букинг"
										/>
									)}
								</div>

								<div className="modalEdit__body_switchers">
									<div className="switchers__item">
										Заполненный
										<Switcher
											onChecked={handleCheckedFilled}
											externalChecked={filled?.every((el) => el === filled[0]) ? filled[0] : false}
										/>
									</div>
									<div className="switchers__item">
										Опасный
										<Switcher
											onChecked={handleCheckedDangerous}
											externalChecked={dangerous?.every((el) => el === dangerous[0]) ? dangerous[0] : false}
										/>
									</div>
									<div className="switchers__item">
										Поврежден
										<Switcher
											onChecked={handleCheckedDamaged}
											externalChecked={damaged?.every((el) => el === damaged[0]) ? damaged[0] : false}
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className="modalEditСontainers__footer">
						<div className="footer__btnsChanges">
							<UniversalButton
								label={'Отменить'}
								onClick={handleCloseModal}
								color={'error'}
								size={'medium'}
								typeButton={'button'}
							/>
							<UniversalButton
								label={'Сохранить'}
								onClick={handleSaveContainers}
								color={'succesful'}
								size={'medium'}
								typeButton={'button'}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ModalEditContainers;
