import styles from './Modals.module.scss';
import { getInitials, generateBackground } from '../../../avatarsUtils';
import {
	IconActionLock,
	IconCross,
	IconDisketa,
	IconFileText,
	IconKey,
	IconRestart,
	IconTrash,
	IconUserCircle,
} from '../../../../../components/Icons';
import { Modal, Select, SelectLaptop } from '../../../../../components';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import MainInput from '../../../../../components/Inputs/MainInput/MainInput';
import {
	setUserModalActive,
	setUserRightsModalActive,
	setSelectedId,
	setSelectedOption,
	setInputChange,
	deleteUser,
	setNewFullName,
	setNewUserName,
	setNewGroup,
	getUser,
	setUserListAfterPut,
	putUserAction,
	postUser,
	setCurrentUser,
	setUserListAfterDelete,
	setNewPassword,
	postTerminalAccessForUser,
	clearAllPermissionArrays,
	setChoosenTerminalUserAddedNowPermissions,
	resetChoosenNowPermissions,
	resetPermissionsChanges,
} from '../../../../../store/slices/userSlice';
import UniversalButton from '../../../../../components/Buttons/UniversalButton/UniversalButton';
import { setTerminalUsersAfterDelete, setTerminalUsersAfterPut } from '../../../../../store/slices/terminalPageSlice';
import { getPermissionList } from '../../../../../store/slices/permissionsSlice';

import { useForm } from 'react-hook-form';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../../store/slices/sideBarSlice';
import ModalUserInfo from './ModalUserInfo';
import ModalAccesses from './ModalAccesses';
import ModalRights from './ModalRights';
import { clearAllGroupArrays } from '../../../../../store/slices/groupSlice';
import RequireUI from '../../../../../hoc/RequireUI';

// property
function ModalProfile({ type }) {
	// Форма для юзеров и юзеров терминала
	const {
		register,
		setFocus,
		setValue,
		formState: { errors },
		clearErrors,
		handleSubmit,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			//   email: localStorage.getItem("email"),
			// FLName: newFullName,
			//   login: localStorage.getItem("login"),
			//   numberContract: localStorage.getItem("numberContract"),
			//   urAdress: localStorage.getItem("urAdress"),
			//   urName: localStorage.getItem("urName"),
			//   autoNum: '',
		},
	});

	// отслеживание ширины окна
	const isDesktop = useMediaQuery({ minWidth: 743.9 });
	const changeBtnText = useMediaQuery({ minWidth: 1024, maxWidth: 1080 });
	const [showGroupModal, setShowGroupModal] = useState(false);
	const dispatch = useDispatch();
	const inputSelectRef = useRef(null);

	const {
		currentUser,
		userGroups,
		selectedId,
		selectedOption,
		inputChange,
		newUserName,
		newFullName,
		newPassword,
		newGroup,
		modalType,
		getUser_pending,
		currentUserId,
		currentUserTerminalsData,
		changeModalTypeForAccesses,
		terminalAccessUserGroupId,
		terminalForAccessId,
		choosenTerminalUserAddedNowPermissions,
		changeModalTypeForChangeUserPermissions,
		currentUserChoosenTerminalData,
		choosenTerminalUserAddedBeforePermissions,
		newGroupWhileChangingPermissions,
		newGroupIdWhileChangingPermissions,
	} = useSelector((state) => state.user);

	const { currentTerminalId } = useSelector((state) => state.terminalPage);

	const { changeModalTypeForGroupChange } = useSelector((state) => state.group);
	// Активное окно
	const [activeTab, setActiveTab] = useState(1);

	//test
	// const [rools, setRools] = useState([1, 2, 3]);
	const [rights, setRights] = useState();

	const handleCloseModal = () => {
		dispatch(setSideBarActive(false));
		dispatch(setUserModalActive(false));
		dispatch(setCurrentElementForSideBar(null));
	};

	const handleOpenTab = (tabToOpen) => {
		switch (tabToOpen) {
			case 1:
				setActiveTab(1);
				dispatch(setUserModalActive(true));
				dispatch(setUserRightsModalActive(false));
				break;

			case 2:
				setActiveTab(2);
				dispatch(getPermissionList());
				dispatch(setUserRightsModalActive(true));
				dispatch(setUserModalActive(false));
				break;
			case 3:
				dispatch(setUserModalActive(false));
				dispatch(setUserRightsModalActive(true));
				setActiveTab(3);
				break;
			case 4:
				setActiveTab(4);
				dispatch(getPermissionList());
				break;
			default:
				break;
		}
	};
	const handleOpenModal = () => {
		setActiveTab(1);
		dispatch(setUserModalActive(true));
		dispatch(setUserRightsModalActive(false));
	};
	const handleOpenRights = () => {
		setActiveTab(2);
		dispatch(getPermissionList());
		dispatch(setUserRightsModalActive(true));
		dispatch(setUserModalActive(false));
	};

	const handleOpenAccesses = () => {
		// dispatch(setUserModalActive(false));
		// dispatch(setUserRightsModalActive(true));
		setActiveTab(3);
	};

	const handleOpenAccessGroupSettings = () => {
		setActiveTab(4);
		dispatch(getPermissionList());
		// dispatch(setUserRightsModalActive(true));
		// dispatch(setUserModalActive(false));
	};
	// useEffect(() => {
	// 	console.log(currentUserTerminalsData, 'CURRENT USER');
	// }, [currentUserTerminalsData]);

	// !Создание пользователя
	const handleCreateUser = () => {
		let requestData = {};
		requestData.full_name = newFullName;
		requestData.username = newUserName;
		requestData.group = selectedId;
		requestData.terminals = [currentTerminalId];
		//TODO доделать пассворд и пермишены
		requestData.password = newPassword;
		requestData.permissions = [];

		dispatch(postUser({ requestData }));
		dispatch(setCurrentUser(null));
		dispatch(setSideBarActive(false));
	};

	// ! Изменение пользователя
	const handlePutUser = () => {
		let requestData = {};

		if (currentUser?.group !== selectedOption) {
			requestData['group'] = selectedId;
		}

		if (currentUser?.full_name !== newFullName) {
			requestData['full_name'] = newFullName;
		}

		if (currentUser?.username !== newUserName) {
			requestData['username'] = newUserName;
		}

		// const updatedRools = rools.filter((user) => !currentUser?.group_permissions_ids?.includes(user));

		// const permissionsChanged = currentUser?.group_permissions_ids?.toString() !== rools.toString();

		// const isEmptyRools = rools.length === 0;

		// if (currentUser?.group_permissions_ids) {
		// 	if (permissionsChanged) {
		// 		requestData.permissions = isEmptyRools ? [0] : updatedRools;
		// 	} else if (isEmptyRools) {
		// 		requestData.permissions = [0];
		// 	}
		// } else if (permissionsChanged) {
		// 	requestData.permissions = isEmptyRools ? [0] : updatedRools;
		// } else {
		// 	requestData.permissions = updatedRools;
		// }
		// const formattedPermissionArray = choosenTerminalUserAddedNowPermissions.map((el) => (el = el.id));
		// requestData.permissions = formattedPermissionArray;

		dispatch(putUserAction({ user_id: currentUser.id, requestData }));
		dispatch(getUser({ user_id: currentUser.id }));
		dispatch(setInputChange(false));
		dispatch(setSideBarActive(false));

		if (type === 'terminalUsers') {
			dispatch(
				setTerminalUsersAfterPut({
					updatedUser: {
						full_name: newFullName,
						username: newUserName,
						group: selectedOption,
						password: newPassword,
						permissions: [0],
					},
					user_id: currentUser.id,
				}),
			);
		}
		if (type === 'users') {
			dispatch(
				setUserListAfterPut({
					updatedUser: {
						full_name: newFullName,
						username: newUserName,
						group: selectedOption,
						password: newPassword,
						//TODO переделать с пермишенами паролем и терминалом
						// password:
						//terminal
						group_permissions_ids: currentUser?.group_permissions_ids,
					},
					user_id: currentUser.id,
				}),
			);
		}
	};

	// !Удаление пользователя (для базы данных и терминалов)
	const handleDeleteUser = () => {
		//База данных
		if (type === 'users') {
			dispatch(deleteUser({ user_id: currentUser.id }));
			dispatch(setUserListAfterDelete({ user_id: currentUser.id }));
		}
		//Терминал
		else if (type === 'terminalUsers') {
			dispatch(deleteUser({ user_id: currentUser.id }));
			dispatch(setTerminalUsersAfterDelete({ user_id: currentUser.id }));
		}
		dispatch(setSideBarActive(false));
	};

	const handleAddGroupForAccess = () => {
		const formattedPermissionArray = choosenTerminalUserAddedNowPermissions
			? choosenTerminalUserAddedNowPermissions.map((el) => (el = el.id))
			: [];
		dispatch(
			postTerminalAccessForUser({
				requestData: {
					group: terminalAccessUserGroupId,
					terminal: terminalForAccessId,
					permissions: [...formattedPermissionArray],
				},
				user_id: currentUser.id,
			}),
		);
		dispatch(clearAllGroupArrays());
		dispatch(clearAllPermissionArrays());
		dispatch(setCurrentElementForSideBar(null));
		dispatch(setSideBarActive(false));
	};

	//! Изменение дополнительных прав пользователя на ранее открытом терминале
	const handleChangeUserPermissions = () => {
		let formattedArrayСhoosenTerminalUserAddedBeforePermissions;
		if (choosenTerminalUserAddedBeforePermissions) {
			formattedArrayСhoosenTerminalUserAddedBeforePermissions = choosenTerminalUserAddedBeforePermissions.map((el) => (el = el.id));
		} else {
			formattedArrayСhoosenTerminalUserAddedBeforePermissions = [];
		}
		const formattedChoosenTerminalUserAddedNowPermissionsArray = choosenTerminalUserAddedNowPermissions.map((el) => (el = el.id));
		const currentGroup = newGroupIdWhileChangingPermissions
			? newGroupIdWhileChangingPermissions
			: currentUserChoosenTerminalData.group.id;
		console.log(formattedArrayСhoosenTerminalUserAddedBeforePermissions, 'BEFORE');
		console.log(formattedChoosenTerminalUserAddedNowPermissionsArray, 'now');
		const requestData = {
			group: currentGroup,
			terminal: currentUserChoosenTerminalData.id,
			permissions: [
				...formattedArrayСhoosenTerminalUserAddedBeforePermissions,
				...formattedChoosenTerminalUserAddedNowPermissionsArray,
			],
		};
		console.log(requestData, 'РЕКВЕТи ДАТА');
		console.log('ЭТО ТУТ', currentGroup);
		dispatch(postTerminalAccessForUser({ requestData: requestData, user_id: currentUser.id }));
		dispatch(clearAllGroupArrays());
		dispatch(clearAllPermissionArrays());
		dispatch(setCurrentElementForSideBar(null));
		dispatch(setSideBarActive(false));
	};

	// !Сброс изменений в редаксе при выходе или изменении группы
	useEffect(() => {
		dispatch(setNewFullName(currentUser?.full_name));
		dispatch(setNewUserName(currentUser?.username));
		dispatch(setNewGroup(currentUser?.group));
		dispatch(setNewPassword(currentUser?.password));
		const currentGroupId = userGroups?.find((el) => el.name === currentUser?.group);
		dispatch(setSelectedId(currentGroupId?.id));
	}, [currentUser, dispatch, userGroups]);

	//TODO ???
	// useEffect(() => {
	//   setFlname(currentUser?.full_name);
	//   dispatch(setSelectedOption(currentUser?.group));
	//   setUserName(currentUser?.username);
	//   setSelectedId(currentUser?.id);
	// }, [oneUser, currentUser]);

	//TODO
	// useEffect(() => {
	//   if (
	//     inputChange &&
	//     (newFullName !== currentUser?.full_name ||
	//       newGroup !== currentUser?.group ||
	//       newUserName !== currentUser?.username)
	//   ) {
	//     setNewInfo(true);
	//   } else {
	//     setNewInfo(false);
	//   }
	// }, [currentUser, newUserName, newGroup, selectedOption, newFullName]);

	// TODO это с рулсами
	// useEffect(() => {
	//   setRools(
	//     currentUser?.user_permissions_ids ||
	//       currentUser?.group_permissions_ids ||
	//       []
	//   );
	// }, [currentUser]);

	// С раскрывающимся списком
	const closeDropdown = (event) => {
		if (inputSelectRef.current && !inputSelectRef.current.contains(event.target)) {
			setShowGroupModal(false);
		}
	};

	const handleResetChanges = ({ type }) => {
		dispatch(resetPermissionsChanges());
	};

	useEffect(() => {
		document.addEventListener('mousedown', closeDropdown);

		return () => {
			document.removeEventListener('mousedown', closeDropdown);
		};
	}, []);

	// !Подгрузка данных в поля формы при переключении вкладок
	// useEffect(() => {
	// 	console.log(modalType, 'asdfghjklkjhgfd');
	// 	console.log(changeModalTypeForAccesses, 'CHANGEACCESSS');
	// 	// dispatch(setCurrentUser({
	// 	//   id: currentUserId,
	// 	//   full_name: newFullName,
	// 	//   username: newUserName
	// 	// }));
	// 	// dispatch(setNewUserName(currentUser?.username));
	// 	// dispatch(setNewGroup(currentUser?.group));
	// 	// dispatch(setNewPassword(currentUser?.password));
	// 	// const currentGroupId = userGroups?.find(
	// 	//   (el) => el.name === currentUser?.group
	// 	// );
	// 	// dispatch(setSelectedId(currentGroupId?.id));
	// }, [changeModalTypeForAccesses]);

	return (
		<div className={styles.modal__card}>
			<div className={styles.MainCardInfo}>
				<div className={styles.card__user}>
					{modalType === 'update' ? (
						<div className={styles.card__userName}>
							<div
								className={styles.card__img}
								style={{
									background: generateBackground(currentUser?.full_name),
								}}
							>
								{getInitials(currentUser?.full_name)}
							</div>
							<div className={styles.card__info}>
								<span>{currentUser?.full_name}</span>
								<p>{currentUser?.group}</p>
							</div>
						</div>
					) : (
						<p>Новый сотрудник</p>
					)}
				</div>
				<div className={styles.card__chooseModalsBtns}>
					<UniversalButton
						icon={<IconUserCircle color={'primary_white'} />}
						color={activeTab === 1 ? 'accent' : 'primary'}
						size={'medium'}
						label={'Основное'}
						onClick={() => handleOpenTab(1)}
					/>
					{/* <UniversalButton
						icon={<IconKey color={'primary_white'} />}
						color={activeTab === 2 ? 'accent' : 'primary'}
						size={'medium'}
						label={'Права'}
						onClick={handleOpenRights}
					/> */}
					<RequireUI type={{ name: 'ChangeEmpls', terminal_id: currentTerminalId }}>
						<UniversalButton
							icon={<IconActionLock color={'primary_white'} size={'largeS'} />}
							color={activeTab === 3 ? 'accent' : 'primary'}
							size={'medium'}
							label={activeTab === 2 || activeTab === 4 ? 'Терминалы' : 'Права'}
							onClick={handleOpenAccesses}
						/>
					</RequireUI>
				</div>
				{activeTab == 1 && (
					<ModalUserInfo
						handleCreateUser={handleCreateUser}
						handleCloseModal={handleCloseModal}
						handleOpenModal={handleOpenModal}
						handlePutUser={handlePutUser}
						register={register}
						setFocus={setFocus}
						setValue={setValue}
						errors={errors}
						clearErrors={clearErrors}
						handleSubmit={handleSubmit}
					/>
				)}

				{activeTab == 2 && <ModalRights type={'changePermissions'} />}

				{activeTab == 3 && (
					<div className={styles.card__userInfo}>
						<ModalAccesses
							modalProfileType={type}
							handleOpenAccessGroupSettings={handleOpenAccessGroupSettings}
							activeTab={activeTab}
							setActiveTab={setActiveTab}
						/>
					</div>
				)}
				{activeTab == 4 && (
					// <div className={styles.card__userInfo}>
					<ModalRights type={'accessTerminal'} />
				)}
			</div>
			<div className={styles.MainCardBtns}>
				<div className={styles.card__btns}>
					{activeTab !== 3 && (
						<>
							{modalType === 'update' ? (
								<RequireUI type={{ name: 'DelEmpls', terminal_id: currentTerminalId }}>
									<UniversalButton
										icon={<IconTrash color={'white'} />}
										color={'error'}
										size={'small'}
										onClick={() => handleDeleteUser()}
									/>
								</RequireUI>
							) : null}
							<div className={styles.card__btns_wrapper}>
								{changeModalTypeForChangeUserPermissions && (
									<UniversalButton
										// icon={modalType === 'update' ? <IconRestart color={'white'} size={'medium'} /> : null}
										label={
											modalType === 'update'
												? 'Отменить изменения'
												: modalType === 'create'
													? 'Отменить изменения'
													: null
										}
										color={'primary'}
										size={'small'}
										disabled={inputChange ? false : true}
										onClick={
											modalType === 'update' && changeModalTypeForAccesses && !changeModalTypeForChangeUserPermissions
												? handleSubmit(() => handleResetChanges({ type: 'accessTerminal' }))
												: modalType === 'update' && changeModalTypeForChangeUserPermissions
													? handleSubmit(() => handleResetChanges({ type: 'changePermissions' }))
													: modalType === 'create'
														? handleSubmit(handleCreateUser)
														: modalType === 'update'
															? handleSubmit(handlePutUser)
															: null
										}
									/>
								)}

								<UniversalButton
									// icon={modalType === 'update' ? <IconDisketa color={'white'} /> : null}
									label={modalType === 'update' ? 'Сохранить изменения' : modalType === 'create' ? 'Добавить' : null}
									color={'succesful'}
									size={'small'}
									disabled={inputChange ? false : true}
									onClick={
										modalType === 'update' && changeModalTypeForAccesses && !changeModalTypeForChangeUserPermissions
											? handleSubmit(handleAddGroupForAccess)
											: modalType === 'update' && changeModalTypeForChangeUserPermissions
												? handleSubmit(handleChangeUserPermissions)
												: modalType === 'create'
													? handleSubmit(handleCreateUser)
													: modalType === 'update'
														? handleSubmit(handlePutUser)
														: null
									}
								/>
							</div>
							{/* <pre>
								{JSON.stringify(changeModalTypeForAccesses, 2, null)} - changeModalTypeForAccesses,{' '}
								{JSON.stringify(changeModalTypeForChangeUserPermissions, 2, null)} - changeModalTypeForChangeUserPermissions
							</pre> */}
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default ModalProfile;
