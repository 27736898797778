import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconMinimize({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M21.0018 8.49816H16.5627L22 3.0609L20.9393 2.00024L15.5021 7.4375V2.99843H14.0021V8.99816C14.0021 9.55044 14.4498 9.99816 15.0021 9.99816H21.0018V8.49816ZM3.00017 15.5002H7.43922L2.00195 20.9375L3.06261 21.9981L8.49989 16.5609V21H9.99989V15.0002C9.99989 14.4479 9.55217 14.0002 8.99989 14.0002H3.00017V15.5002Z"
					fill={IconColor(color)}
				/>
			</svg>
		</div>
	);
}

export default IconMinimize;
