import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconCompareAdd({ size, color }) {
  return (
    <div className={styles.icon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={IconSize(size)}
        height={IconSize(size)}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M15.4375 15.9375V18.25H14.5625V15.9375V15.4375H14.0625H11.75V14.5625H14.0625H14.5625V14.0625V11.75H15.4375V14.0625V14.5625H15.9375H18.25V15.4375H15.9375H15.4375V15.9375ZM1.75 4.5625H15.75V5.4375H1.75V4.5625ZM1.75 10.4375V9.5625H10.75V10.4375H1.75ZM1.75 15.4375V14.5625H5.75V15.4375H1.75Z"
          stroke={IconColor(color)}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default IconCompareAdd;
