import styles from './ContentUsers.module.scss';
import { getInitials, generateBackground, getInitialsCLient } from '../../../avatarsUtils';
import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ModalProfile from '../Modals/ModalProfile';
import {
	getUser,
	getUserGroups,
	getUsers,
	setChoosenTerminalUserAddedNowPermissions,
	setUserFetching,
} from '../../../../../store/slices/userSlice';
import { getClient, setClientsFetching, setInputChange } from '../../../../../store/slices/clientSlice';
import { setCurrentUserId, setModalType as setModalTypeUser, setUserChoosenNowPermissions } from '../../../../../store/slices/userSlice';
import { setModalType as setModalTypeClient } from '../../../../../store/slices/clientSlice';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../../store/slices/sideBarSlice';
import { useForm } from 'react-hook-form';
import ModalClient from '../Modals/ModalsCllient/ModalClient';
function ContentUsers({ type, items }) {
	// ! props.type:
	// terminalUsers
	// terminalClients
	// users
	// clients
	//addUsersForTerminal

	const dispatch = useDispatch();

	const { sideBarActive } = useSelector((state) => state.sideBar);

	const isDesktop = useMediaQuery({ minWidth: 639.9 });

	useEffect(() => {
		type === 'addUsersForTerminal' && dispatch(getUsers());
	}, []);

	//! Действия при нажатии на карточку сотрудника/клиента
	const handleUser = (event, el) => {
		switch (type) {
			case 'addUsersForTerminal':
				// dispatch(getUser({ user_id: event.currentTarget.id }));
				// dispatch(setModalTypeUser("update"));
				// dispatch(getUserGroups());
				// dispatch(setCurrentUserId(event.currentTarget.id))
				// dispatch(setCurrentElementForSideBar(<ModalProfile type={"users"}/>));
				// dispatch(setSideBarActive(event.currentTarget.id));
				break;
			case 'users':
				dispatch(setChoosenTerminalUserAddedNowPermissions({ type: 'clearAll' }));
				dispatch(getUser({ user_id: event.currentTarget.id }));
				dispatch(setModalTypeUser('update'));
				dispatch(getUserGroups());
				dispatch(setCurrentUserId(event.currentTarget.id));
				dispatch(setCurrentElementForSideBar(<ModalProfile type={'users'} />));
				dispatch(setSideBarActive(event.currentTarget.id));
				break;
			case 'clients':
				dispatch(setModalTypeClient('update'));
				dispatch(getClient({ user_id: event.currentTarget.id }));
				// dispatch(setCurrentElementForSideBar(<ModalClient type={'clients'} />));
				// dispatch(setSideBarActive(event.currentTarget.id));
				break;
			case 'terminalUsers':
				dispatch(setChoosenTerminalUserAddedNowPermissions({ type: 'clearAll' }));
				dispatch(setModalTypeUser('update'));
				dispatch(getUser({ user_id: event.currentTarget.id }));
				dispatch(setCurrentUserId(event.currentTarget.id));
				dispatch(getUserGroups());
				dispatch(setCurrentElementForSideBar(<ModalProfile type={'terminalUsers'} />));
				dispatch(setSideBarActive(event.currentTarget.id));
				break;
			case 'terminalClients':
				dispatch(setModalTypeClient('update'));
				dispatch(getClient({ user_id: event.currentTarget?.id }));
				dispatch(setCurrentElementForSideBar(<ModalClient type={'terminalClients'} />));
				dispatch(setSideBarActive(event.currentTarget.id));
				dispatch(setInputChange(false));
				break;
			default:
				break;
		}
	};

	//! Закрытие сайдбара при уходе со страницы
	useEffect(() => {
		return () => {
			dispatch(setSideBarActive(false));
		};
	}, []);

	const scrollHandler = (e) => {
		if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100) {
			if (type == 'clients') {
				dispatch(setClientsFetching(true));
			}
			if (type == 'users') {
				dispatch(setUserFetching(true));
			}
		} else {
			if (type == 'clients') {
				dispatch(setClientsFetching(false));
			}
			if (type == 'users') {
				dispatch(setUserFetching(false));
			}
		}
	};
	useEffect(() => {
		const scrollContainer = document.getElementById('content__users');
		scrollContainer.addEventListener('scroll', scrollHandler);
		return function () {
			scrollContainer.removeEventListener('scroll', scrollHandler);
		};
	}, []);
	return (
		<div
			className={`${styles.content__users} ${
				// userModalActive == el.id || clientModalActive == el.id ||
				sideBarActive && type === 'addUsersForTerminal'
					? styles.content__users_inSideBar
					: sideBarActive && type !== 'addUsersForTerminal'
						? styles.content__users_withSideBar
						: ''
			}`}
			id="content__users"
		>
			{items?.map((el, index) => (
				<div
					key={index}
					id={el.id}
					className={`${styles.content__user} ${
						// userModalActive == el.id || clientModalActive == el.id ||
						sideBarActive == el.id
							? styles.show
							: // : userModalActive || userRightsModalActive || clientModalActive
								// ? styles.w_100
								sideBarActive
								? styles.with_sidebar
								: ''
					} ${sideBarActive && type === 'addUsersForTerminal' ? styles.newList_forTerminal : ''}`}
					onClick={(event) => {
						handleUser(event, el);
					}}
				>
					{(type === 'clients' || type === 'terminalClients') && type !== 'users' && type !== 'terminalUsers' ? (
						<div className={styles.user__img} style={{ background: generateBackground(el.organization_name) }}>
							{getInitialsCLient(el.organization_name)}
						</div>
					) : (
						<div className={styles.user__img} style={{ background: generateBackground(el.full_name) }}>
							{getInitials(el.full_name)}
						</div>
					)}
					<div className={styles.user__name}>
						{(type === 'clients' || type === 'terminalClients') && type !== 'users' && type !== 'terminalUsers' ? (
							<>
								<span>{el.organization_name}</span>
								{type === 'clients' && (
									<p
										className={`${
											el.is_manager_confirmed === 'Одобрен'
												? styles.approved
												: el.is_manager_confirmed === 'Ожидает одобрения'
													? styles.awaiting
													: el.is_manager_confirmed === 'Отклонен'
														? styles.rejected
														: ''
										}`}
									>
										{el.is_manager_confirmed}
									</p>
								)}
							</>
						) : (
							<>
								<span>{el?.full_name}</span>
								<p>{el?.terminals[0].group.name}</p>
							</>
						)}
					</div>
				</div>
			))}
			{/* //! Не убирать - логика modalRights */}
			{/* {isDesktop && userModalActive */}
			{/* {isDesktop
        ? ""
        : userRightsModalActive && (
            <Modal
              onClose={handleCloseModal}
              noCross
              children={
                <ModalRights
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  flName={flName}
                  newInfo={newInfo}
                  setFlname={setFlname}
                  userName={userName}
                  setUserName={setUserName}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  // handlePutUser={handlePutUser}
                  inputChange={inputChange}
                  setInputChange={setInputChange}
                  // setRights={setRights}
                  dataUser={dataUser}
                  handleCloseModal={handleCloseModal}
                  // handlePutBaseUser={handlePutBaseUser}
                  rools={rools}
                  setRools={setRools}
                />
              }
            />
          )} */}
		</div>
	);
}

export default ContentUsers;
