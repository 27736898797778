import styles from './Layouts.module.scss';
import { Search } from '../../../../../components';
import { IconSort } from '../../../../../components/Icons';
import { BtnIcon } from '../../../../../components/Buttons';
import OrderList from '../../../../../components/Order/OrderList/OrderList';
import AllOrdersInfo from '../../../../../components/Order/AllOrdersInfo/AllOrdersInfo';
import AllOrdersFilterItems from '../../../../../components/Order/AllOrdersFilterItems/AllOrdersFilterItems';
import { useEffect, useState } from 'react';
import AllOrdersFilter from '../../../../../components/Order/AllOrdersFilter/AllOrdersFilter';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders, setOrdersFetching } from '../../../../../store/slices/orderSlice';
import ModalPlug from '../../../../../components/Modal/ModalPlug';
// import { useSelector } from "react-redux";
// import { allowFetching, setActiveFilter, setCardOrderActive, } from "../../../../../store/slices/orderPageSlice";
// import OrderDetails from "../../../../../components/Order/OrderDetails/OrderDetails";

function LayoutOrders({ order_group, setOrder_group, handleOpenModalPlug }) {
	// const { activeCardOrder } = useSelector((state) => state.orderPage);
	const [filterItemes, setFilterItems] = useState([]);
	const [importOrderSwitch, setImportOrderSwitch] = useState(false);
	const [exportOrderSwitch, setExportOrderSwitch] = useState(false);
	const [noManager, setNoManger] = useState(false);
	const [additionalInfo, setAdditionalInfo] = useState(false);
	//! - Если понадобится - НУЖНО ВНЕДРИТЬ при нажатии на заявку открывается данный компонент
	//* {activeCardOrder && <OrderDetails />} около OrderList

	const dispatch = useDispatch();
	const { orderList, ordersCount, orderFilters } = useSelector((state) => state.order);
	const removeFilterItem = (index, text) => {
		if (importOrderSwitch && text === 'Заявки на прием') {
			setFilterItems(filterItemes.filter((_, i) => i !== index));
			setImportOrderSwitch(false);
		}
		if (exportOrderSwitch && text === 'Заявки на вывоз') {
			setFilterItems(filterItemes.filter((_, i) => i !== index));
			setExportOrderSwitch(false);
		}
		if (noManager && text === 'Нет ответственного менеджера') {
			setFilterItems(filterItemes.filter((_, i) => i !== index));
			setNoManger(false);
		}
	};

	//! Получение всех заявок
	// useEffect(() => {
	// 	dispatch(getOrders());
	// }, []);

	//TODO Подключить фильтры не удалять!!!!!!!!!!!!!!!!!!!!!!
	//! Получение всех заявок при изменении фильтра
	// useEffect(() => {
	// 	dispatch(getOrders());
	// }, [orderFilters.typeImport, orderFilters.typeExport, orderFilters.dateStart, orderFilters.dateEnd]);

	return (
		<div className={styles.layoutOrders}>
			<div className={styles.orders_filtrBlock}>
				<AllOrdersInfo
					orderLength={ordersCount}
					additionalInfo={additionalInfo}
					setAdditionalInfo={setAdditionalInfo}
					handleOpenModalPlug={handleOpenModalPlug}
				/>
				<AllOrdersFilter
					importOrderSwitch={importOrderSwitch}
					setImportOrderSwitch={setImportOrderSwitch}
					exportOrderSwitch={exportOrderSwitch}
					setExportOrderSwitch={setExportOrderSwitch}
					filterItemes={filterItemes}
					setFilterItems={setFilterItems}
					setNoManger={setNoManger}
					noManager={noManager}
					additionalInfo={additionalInfo}
					handleOpenModalPlug={handleOpenModalPlug}
				/>
			</div>
			<div className={styles.ordersList_search}>
				<div className={styles.searchLine}>
					<Search disabled={true} handleOpenModalPlug={handleOpenModalPlug} />
					{/* <BtnIcon type="button" icon={<IconSort size="large" color="primary_white" />} size="p-6" /> */}
				</div>
				<AllOrdersFilterItems
					setNoManger={setNoManger}
					setImportOrderSwitch={setImportOrderSwitch}
					setExportOrderSwitch={setExportOrderSwitch}
					setFilterItems={setFilterItems}
					filterItems={filterItemes}
					removeFilterItem={removeFilterItem}
				/>
				{/* // TODO реализовать preloader */}
				<OrderList orderList={orderList} />
			</div>
		</div>
	);
}

export default LayoutOrders;
