import { useEffect, useState } from 'react';
import UniversalButton from '../../../../../components/Buttons/UniversalButton/UniversalButton';
import styles from './MenuDesktop.module.scss';
import ImageUploading from 'react-images-uploading';
import { IconPlus, IconTrash } from '../../../../../components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { sendError, setInitialSendError } from '../../../../../store/slices/publicFormSlice';
import RViewerJS from 'viewerjs-react';
import S3Manager from '../../../../../helpers/s3storage';
import { Notification } from '../../../../../components';

function ProblemReport() {
	const dispatch = useDispatch();
	const { sendError_success, sendError_error } = useSelector((state) => state.publicForm);

	// уведомления
	const [isActive, setIsActive] = useState(false);
	const [text, setText] = useState('');
	const [descr, setDescr] = useState('');
	const [status, setStatus] = useState('');
	const [imagesKeys, setImagesKeys] = useState([]);
	const onClose = () => {
		setIsActive(false);
	};

	// Фото
	const [images, setImages] = useState(() => {
		const savedImages = localStorage.getItem('uploadedImages');
		return savedImages ? JSON.parse(savedImages) : [];
	});

	const imageUploader = new S3Manager('errors');

	const onChange = async (imageList, addUpdateIndex) => {
		setImages(imageList);
		const newImage = imageList[addUpdateIndex];
		if (newImage) {
			try {
				const result = await imageUploader.uploadImage({
					actId: '2',
					file: { name: 'expample.png', data_url: newImage.data_url },
				});
				setImagesKeys([...imagesKeys, `errors/${result.Key}`]);
			} catch (error) {
				console.error('Ошибка загрузки изображения:', error);
			}
		}
	};

	const handleDeleteImages = async (image, ind) => {
		try {
			await imageUploader.deleteImage(imagesKeys[ind]);
			const indexToDelete = images.findIndex((img) => img.data_url === image.data_url);
			if (indexToDelete !== -1) {
				const updatedImages = [...images.slice(0, indexToDelete), ...images.slice(indexToDelete + 1)];
				setImages(updatedImages);
				localStorage.setItem('uploadedImages', JSON.stringify(updatedImages));
			}
		} catch (error) {
			console.error('Ошибка загрузки изображения:', error);
		}
	};

	const [problemDescription, setProblemDescription] = useState('');

	const handleInputChange = (e) => {
		setProblemDescription(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const fileNames = images
			.filter((item) => item.file && item.file.name) // Проверка что у объекта есть file и name
			.map((item) => item.file.name); // Извлечь name из объекта file
		let requestData = {
			error: problemDescription == '' ? null : problemDescription,
			images: imagesKeys,
		};
		dispatch(sendError({ requestData }));
	};

	useEffect(() => {
		if (sendError_success) {
			setImages([]);
			setImagesKeys([]);
			localStorage.removeItem('uploadedImages');
			setProblemDescription('');
			setIsActive(true);
			setText('Сообщение отправлено');
			setDescr('Спасибо!');
			setStatus('success');
		} else if (sendError_error) {
			setIsActive(true);
			setText('Сообщение не отправлено');
			setDescr('Попробуйте еще раз');
			setStatus('error');
		}
		dispatch(setInitialSendError());
	}, [sendError_success, sendError_error]);

	return (
		<>
			<div className={styles.problem__notification}>
				<Notification text={text} descr={descr} onClose={onClose} isActive={isActive} isStatus={status} />
			</div>

			<form onSubmit={handleSubmit} className={styles.modalSupport}>
				<label htmlFor="problem-description">Опишите проблему и прикрепите скриншот</label>
				<textarea
					required
					id="problem-description"
					value={problemDescription}
					onChange={handleInputChange}
					placeholder="Опишите проблему"
				/>
				<div className={styles.dropzone}>
					<ImageUploading
						multiple
						value={images}
						onChange={onChange}
						maxNumber={10}
						dataURLKey="data_url"
						acceptType={['jpg', 'png', 'jpeg']}
					>
						{({ imageList, onImageUpload, isDragging, dragProps }) => (
							<>
								<RViewerJS className={styles.viewer}>
									{imageList.map((image, index) => (
										<div key={`image_wrapper_${index}`} className={styles.onePhotoCard}>
											<img src={image.data_url} alt="example" />

											<UniversalButton
												icon={<IconTrash size={'medium'} color={'white'} />}
												typeButton={'button'}
												color={'error'}
												onClick={() => handleDeleteImages(image, index)}
												size={'small'}
											/>
										</div>
									))}
								</RViewerJS>

								<div
									className={`${styles.uploadImageWrapper} ${styles.photoContainer} ${isDragging ? styles.dragging : ''} ${images?.length > 0 ? styles.miniUploadImageWrapper : ''}`}
									{...dragProps}
								>
									<button className={styles.uploadButton} onClick={onImageUpload} type="button">
										{images?.length > 0 ? '' : 'Загрузить фото'}
										<IconPlus color={'black'} size={'large'} />
									</button>
								</div>
							</>
						)}
					</ImageUploading>
				</div>
				<div className={styles.modalSupport__btns}>
					<UniversalButton typeButton={'submit'} label={'Отправить'} size={'large'} color={'accent'} />
				</div>
			</form>
		</>
	);
}

export default ProblemReport;
