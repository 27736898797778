import React, { useEffect, useState } from 'react';
import { allPermissions } from './allPermissions';
import { useSelector } from 'react-redux';
import { useStore } from 'react-redux';

// const fakeData = {
// 	id: 2,
// 	full_name: 'Анатолий Георгиевич Уваров',
// 	user_type: 'Работник',
// 	is_confirmed: true,
// 	username: 'muravevairina',
// 	terminals: [
// 		{
// 			id: 2,
// 			name: 'SEVER',
// 			group: {
// 				id: 9,
// 				name: 'Бухгалтер',
// 				permissions: [
// 					{
// 						id: 48,
// 						name: 'Просмотр контейнеров',
// 						type: 'view',
// 					},
// 					{
// 						id: 64,
// 						name: 'Просмотр услуг',
// 						type: 'view',
// 					},
// 				],
// 				users_count: 24,
// 			},
// 			permissions: [],
// 		},
// 		{
// 			id: 7,
// 			name: 'Test',
// 			group: {
// 				id: 1,
// 				name: 'Владелец',
// 				permissions: [
// 					{
// 						id: 1,
// 						name: 'Создание пользователей',
// 						type: 'add',
// 					},
// 					{
// 						id: 2,
// 						name: 'Изменение пользователей',
// 						type: 'change',
// 					},
// 					{
// 						id: 3,
// 						name: 'Удаление пользователей',
// 						type: 'delete',
// 					},
// 					{
// 						id: 4,
// 						name: 'Просмотр пользователей',
// 						type: 'view',
// 					},
// 					{
// 						id: 13,
// 						name: 'Создание групп',
// 						type: 'add',
// 					},
// 					{
// 						id: 14,
// 						name: 'Изменение групп',
// 						type: 'change',
// 					},
// 					{
// 						id: 15,
// 						name: 'Удаление групп',
// 						type: 'delete',
// 					},
// 					{
// 						id: 16,
// 						name: 'Просмотр групп',
// 						type: 'view',
// 					},
// 					{
// 						id: 9,
// 						name: 'Создание прав',
// 						type: 'add',
// 					},
// 					{
// 						id: 10,
// 						name: 'Изменение прав',
// 						type: 'change',
// 					},
// 					{
// 						id: 11,
// 						name: 'Удаление прав',
// 						type: 'delete',
// 					},
// 					{
// 						id: 12,
// 						name: 'Просмотр прав',
// 						type: 'view',
// 					},
// 					{
// 						id: 45,
// 						name: 'Создание контейнеров',
// 						type: 'add',
// 					},
// 					{
// 						id: 46,
// 						name: 'Изменение контейнеров',
// 						type: 'change',
// 					},
// 					{
// 						id: 47,
// 						name: 'Удаление контейнеров',
// 						type: 'delete',
// 					},
// 					{
// 						id: 48,
// 						name: 'Просмотр контейнеров',
// 						type: 'view',
// 					},
// 					{
// 						id: 37,
// 						name: 'Создание линий',
// 						type: 'add',
// 					},
// 					{
// 						id: 38,
// 						name: 'Изменение линий',
// 						type: 'change',
// 					},
// 					{
// 						id: 39,
// 						name: 'Удаление линий',
// 						type: 'delete',
// 					},
// 					{
// 						id: 40,
// 						name: 'Просмотр линий',
// 						type: 'view',
// 					},
// 					{
// 						id: 49,
// 						name: 'Создание номеров сообщений',
// 						type: 'add',
// 					},
// 					{
// 						id: 50,
// 						name: 'Изменение номеров сообщений',
// 						type: 'change',
// 					},
// 					{
// 						id: 51,
// 						name: 'Удаление номеров сообщений',
// 						type: 'delete',
// 					},
// 					{
// 						id: 52,
// 						name: 'Просмотр номеров сообщений',
// 						type: 'view',
// 					},
// 					{
// 						id: 65,
// 						name: 'Создание водителей',
// 						type: 'add',
// 					},
// 					{
// 						id: 66,
// 						name: 'Изменение водителей',
// 						type: 'change',
// 					},
// 					{
// 						id: 67,
// 						name: 'Удаление водителей',
// 						type: 'delete',
// 					},
// 					{
// 						id: 68,
// 						name: 'Просмотр водителей',
// 						type: 'view',
// 					},
// 					{
// 						id: 53,
// 						name: 'Создание заявок',
// 						type: 'add',
// 					},
// 					{
// 						id: 54,
// 						name: 'Изменение заявок',
// 						type: 'change',
// 					},
// 					{
// 						id: 55,
// 						name: 'Удаление заявок',
// 						type: 'delete',
// 					},
// 					{
// 						id: 56,
// 						name: 'Просмотр заявок',
// 						type: 'view',
// 					},
// 					{
// 						id: 33,
// 						name: 'Создание договоров',
// 						type: 'add',
// 					},
// 					{
// 						id: 34,
// 						name: 'Изменение договоров',
// 						type: 'change',
// 					},
// 					{
// 						id: 35,
// 						name: 'Удаление договоров',
// 						type: 'delete',
// 					},
// 					{
// 						id: 36,
// 						name: 'Просмотр договоров',
// 						type: 'view',
// 					},
// 					{
// 						id: 25,
// 						name: 'Can add Организация',
// 						type: 'add',
// 					},
// 					{
// 						id: 27,
// 						name: 'Can delete Организация',
// 						type: 'delete',
// 					},
// 					{
// 						id: 32,
// 						name: 'Can view user to org',
// 						type: 'view',
// 					},
// 					{
// 						id: 61,
// 						name: 'Создание услуг',
// 						type: 'add',
// 					},
// 					{
// 						id: 62,
// 						name: 'Изменение услуг',
// 						type: 'change',
// 					},
// 					{
// 						id: 63,
// 						name: 'Удаление услуг',
// 						type: 'delete',
// 					},
// 					{
// 						id: 64,
// 						name: 'Просмотр услуг',
// 						type: 'view',
// 					},
// 					{
// 						id: 69,
// 						name: 'Создание терминалов',
// 						type: 'add',
// 					},
// 					{
// 						id: 70,
// 						name: 'Изменение терминалов',
// 						type: 'change',
// 					},
// 					{
// 						id: 71,
// 						name: 'Удаление терминалов',
// 						type: 'delete',
// 					},
// 					{
// 						id: 72,
// 						name: 'Просмотр терминалов',
// 						type: 'view',
// 					},
// 				],
// 				users_count: 20,
// 			},
// 			permissions: [],
// 		},
// 		{
// 			id: 3,
// 			name: 'NORD',
// 			group: {
// 				id: 9,
// 				name: 'Бухгалтер',
// 				permissions: [
// 					{
// 						id: 48,
// 						name: 'Просмотр контейнеров',
// 						type: 'view',
// 					},
// 					{
// 						id: 64,
// 						name: 'Просмотр услуг',
// 						type: 'view',
// 					},
// 				],
// 				users_count: 24,
// 			},
// 			permissions: [],
// 		},
// 		{
// 			id: 1,
// 			name: 'TETRIS',
// 			group: {
// 				id: 3,
// 				name: 'Менеджер',
// 				permissions: [
// 					{
// 						id: 45,
// 						name: 'Создание контейнеров',
// 						type: 'add',
// 					},
// 					{
// 						id: 46,
// 						name: 'Изменение контейнеров',
// 						type: 'change',
// 					},
// 					{
// 						id: 48,
// 						name: 'Просмотр контейнеров',
// 						type: 'view',
// 					},
// 					{
// 						id: 65,
// 						name: 'Создание водителей',
// 						type: 'add',
// 					},
// 					{
// 						id: 66,
// 						name: 'Изменение водителей',
// 						type: 'change',
// 					},
// 					{
// 						id: 68,
// 						name: 'Просмотр водителей',
// 						type: 'view',
// 					},
// 					{
// 						id: 53,
// 						name: 'Создание заявок',
// 						type: 'add',
// 					},
// 					{
// 						id: 54,
// 						name: 'Изменение заявок',
// 						type: 'change',
// 					},
// 					{
// 						id: 56,
// 						name: 'Просмотр заявок',
// 						type: 'view',
// 					},
// 					{
// 						id: 61,
// 						name: 'Создание услуг',
// 						type: 'add',
// 					},
// 					{
// 						id: 62,
// 						name: 'Изменение услуг',
// 						type: 'change',
// 					},
// 					{
// 						id: 64,
// 						name: 'Просмотр услуг',
// 						type: 'view',
// 					},
// 					{
// 						id: 69,
// 						name: 'Создание терминалов',
// 						type: 'add',
// 					},
// 					{
// 						id: 70,
// 						name: 'Изменение терминалов',
// 						type: 'change',
// 					},
// 					{
// 						id: 71,
// 						name: 'Удаление терминалов',
// 						type: 'delete',
// 					},
// 					{
// 						id: 72,
// 						name: 'Просмотр терминалов',
// 						type: 'view',
// 					},
// 				],
// 				users_count: 32,
// 			},
// 			permissions: [
// 				{
// 					id: 1,
// 					name: 'Создание пользователей',
// 					type: 'add',
// 				},
// 				{
// 					id: 2,
// 					name: 'Изменение пользователей',
// 					type: 'change',
// 				},
// 				{
// 					id: 3,
// 					name: 'Удаление пользователей',
// 					type: 'delete',
// 				},
// 				{
// 					id: 4,
// 					name: 'Просмотр пользователей',
// 					type: 'view',
// 				},
// 			],
// 		},
// 	],
// };

function RequireUI({ type, children, swap }) {
	const fakeData = useSelector((state) => state.me.userData);
	const checkPermissions = () => {
		if (type.terminal_id) {
			const currentTerminalUserData = fakeData?.terminals?.find((el) => el.id == type.terminal_id);
			const terminalGroupPermissions = currentTerminalUserData?.group?.permissions.map((el) => (el = el.id));
			const terminalCustomPermissions = currentTerminalUserData?.permissions.map((elt) => (elt = elt.id));
			const currentPermissonsList = allPermissions?.find((el) => el.name === type.name);

			const concat = terminalGroupPermissions?.concat(terminalCustomPermissions);
			const isInOne = currentPermissonsList?.permissions.every((val) => concat?.includes(val));
			return isInOne;
		}
		if (!type.terminal_id) {
			const getUniqueIds = (data) => {
				const uniqueIds = new Set();
				data.terminals?.forEach((terminal) => {
					const ids = [
						...terminal.group.permissions.map((permission) => permission.id),
						...terminal.permissions.map((permission) => permission.id),
					];
					ids.forEach((id) => uniqueIds.add(id));
				});
				return Array.from(uniqueIds);
			};

			const uniqueIds = getUniqueIds(fakeData);
			const currentPermissonsList = allPermissions?.find((el) => el.name === type.name);
			const isInOne = currentPermissonsList?.permissions?.every((val) => uniqueIds?.includes(val));
			return isInOne;
		}
	};
	const show = checkPermissions();
	return <>{show ? children : swap ? swap : null}</>;
}

export default RequireUI;

export const RequireUIFunc = (type) => {
	const fakeData = useSelector((state) => state.me.userData);
	if (type.terminal_id) {
		const currentTerminalUserData = fakeData?.terminals?.find((el) => el.id == type.terminal_id);
		const terminalGroupPermissions = currentTerminalUserData?.group?.permissions.map((el) => (el = el.id));
		const terminalCustomPermissions = currentTerminalUserData?.permissions.map((elt) => (elt = elt.id));
		const currentPermissonsList = allPermissions?.find((el) => el.name === type.name);

		const concat = terminalGroupPermissions?.concat(terminalCustomPermissions);
		const isInOne = currentPermissonsList?.permissions.every((val) => concat?.includes(val));
		return isInOne;
	}

	if (!type.terminal_id) {
		const getUniqueIds = (data) => {
			const uniqueIds = new Set();
			data.terminals?.forEach((terminal) => {
				const ids = [
					...terminal.group.permissions.map((permission) => permission.id),
					...terminal.permissions.map((permission) => permission.id),
				];
				ids.forEach((id) => uniqueIds.add(id));
			});
			return Array.from(uniqueIds);
		};

		const uniqueIds = getUniqueIds(fakeData);
		const currentPermissonsList = allPermissions?.find((el) => el.name === type.name);
		const isInOne = currentPermissonsList?.permissions?.every((val) => uniqueIds?.includes(val));
		return isInOne;
	}
};
