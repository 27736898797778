import { useState } from 'react';
import { UniversalButton } from '../../../../components';
import styles from '../AccountEmpl.module.scss';
import Header from '../components/Header/Header';
import TableContainers from '../components/Tables/TableContainers/TableContainers';
import { getExportExcel } from '../../../../store/slices/allContainersSlice';
import { useDispatch, useSelector } from 'react-redux';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function AccountEmplContainers() {
	const dispatch = useDispatch();

	const { getExportExcel_pending } = useSelector((state) => state.allContainers);

	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});

	// Видимость столбцов
	const [columnVisibility, setColumnVisibility] = useState({
		status: true,
		line: true,
		container_number: true,
		size: true,
		type: true,
		org: true,
		booking: true,
		is_filled: true,
		is_dangerous: true,
		is_damaged: true,
		terminal: false,
		order_in: false,
		entrypass_in_status: false,
		vehicle_in: false,
		driver_in: false,
		checkin_at: false,
		order_out: false,
		entrypass_out_status: false,
		vehicle_out: false,
		driver_out: false,
		checkout_at: false,
	});

	const handleExportExcel = () => {
		let viewCols;
		if (Object.values(columnVisibility).every((value) => value === true)) {
			viewCols = '';
		} else {
			viewCols =
				'exclude=' +
				Object.keys(columnVisibility)
					.filter((key) => !columnVisibility[key])
					.join(',');
		}
		if (Object.keys(rowSelection).length !== 0) {
			if (viewCols === '') {
				viewCols += 'container_ids_in=' + Object.keys(rowSelection).join(',');
			} else {
				viewCols += '&container_ids_in=' + Object.keys(rowSelection).join(',');
			}
		}
		dispatch(getExportExcel(viewCols));
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Сводная таблица'}
				btnDesktop={
					getExportExcel_pending ? (
						<div className={styles.btnExport}>
							<UniversalButton color={'primary'} size={'medium'} label={'. . .'} />
						</div>
					) : (
						<UniversalButton color={'primary'} size={'medium'} label={'Экспортировать данные'} onClick={handleExportExcel} />
					)
				}
				btnMobile={
					getExportExcel_pending ? (
						<div className={styles.btnMobileExport}>
							<UniversalButton color={'primary'} size={'medium'} label={'. . .'} />
						</div>
					) : (
						<UniversalButton color={'primary'} size={'medium'} label={'Экспорт'} onClick={handleExportExcel} />
					)
				}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale="ru"
						// Placeholder
						localeText={{
							fieldDayPlaceholder: () => '01',
							fieldMonthPlaceholder: () => '01',
							fieldYearPlaceholder: () => '2024',
						}}
					>
						<TableContainers
							columnVisibility={columnVisibility}
							setColumnVisibility={setColumnVisibility}
							rowSelection={rowSelection}
							setRowSelection={setRowSelection}
						/>
					</LocalizationProvider>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplContainers;
