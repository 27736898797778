import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconUserCircle({ size, onClick, color, className }) {
  return (
    <div onClick={onClick} className={`${styles.icon} ${className}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 21.25 21.25">
        <path fill={IconColor(color)} d="M17.21,19.5a.63.63,0,1,0,1.25,0Zm-2.29-4.44v0Zm-5.84,0v0ZM6.17,18h0ZM5.54,19.5a.63.63,0,1,0,1.25,0Zm12.92,0V18H17.21V19.5Zm0-1.48a3.61,3.61,0,0,0-1-2.54l-.89.88A2.39,2.39,0,0,1,17.21,18Zm-1-2.54a3.51,3.51,0,0,0-2.5-1.05v1.25a2.25,2.25,0,0,1,1.61.68Zm-2.5-1.05H9.08v1.25h5.84Zm-5.84,0a3.51,3.51,0,0,0-2.5,1.05l.89.88a2.25,2.25,0,0,1,1.61-.68Zm-2.5,1.05a3.61,3.61,0,0,0-1,2.54H6.79a2.39,2.39,0,0,1,.68-1.66ZM5.54,18V19.5H6.79V18Zm8.75-8.89A2.31,2.31,0,0,1,12,11.47v1.25a3.56,3.56,0,0,0,3.54-3.59ZM12,11.47A2.31,2.31,0,0,1,9.71,9.13H8.46A3.56,3.56,0,0,0,12,12.72ZM9.71,9.13A2.31,2.31,0,0,1,12,6.79V5.54A3.56,3.56,0,0,0,8.46,9.13ZM12,6.79a2.31,2.31,0,0,1,2.29,2.34h1.25A3.56,3.56,0,0,0,12,5.54ZM21.38,12A9.38,9.38,0,0,1,12,21.38v1.25A10.63,10.63,0,0,0,22.63,12ZM12,21.38A9.37,9.37,0,0,1,2.63,12H1.38A10.62,10.62,0,0,0,12,22.63ZM2.63,12A9.36,9.36,0,0,1,12,2.63V1.38A10.61,10.61,0,0,0,1.38,12ZM12,2.63A9.37,9.37,0,0,1,21.38,12h1.25A10.62,10.62,0,0,0,12,1.38Z" transform="translate(-1.38 -1.38)" />
      </svg>
    </div>
  );
}

export default IconUserCircle;


