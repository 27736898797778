import { NavLink } from 'react-router-dom';
import styles from './MenuDesktop.module.scss';
import { useState } from 'react';
import { IconArrowDown } from '../../../../../components/Icons';
import ModalPlug from '../../../../../components/Modal/ModalPlug';

// подпункты пункта меню
function SubMenu({ item, open }) {
	// url страницы
	const currentPath = window.location.pathname;
	// если подменю открыто, после перезагрузки остается открытым
	// const [isShow, setShow] = useState(
	// 	currentPath === '/accountEmpl/clients' ||
	// 		currentPath === '/accountEmpl/empls' ||
	// 		currentPath === '/accountEmpl/createJob' ||
	// 		currentPath === '/accountEmpl/summary' ||
	// 		currentPath === '/accountEmpl/tableEntries' ||
	// 		currentPath === '/accountEmpl/orders' ||
	// 		currentPath === '/accountEmpl/createImportOrder' ||
	// 		currentPath === '/accountEmpl/createExportOrder'
	// 		? true
	// 		: false,
	// );

	const [isShow, setShow] = useState(false);

	const handleSubMenu = () => {
		setShow(!isShow);
	};

	const [isOpenModal, setIsOpenModal] = useState(false);

	const onClose = () => {
		setIsOpenModal(false);
	};

	return (
		<>
			<ModalPlug isOpen={isOpenModal} fullScreen={true} onClose={onClose} page={item?.label} />
			<div className={styles.subMenu__title} onClick={handleSubMenu}>
				<div>
					{item?.icon}
					<span> {open ? item?.label : ''}</span>
				</div>
				<IconArrowDown color={'black'} size={'medium'} className={!isShow ? '' : `${styles.arrowUp}`} />
			</div>
			{isShow ? (
				<ul className={styles.subMenu__list}>
					{item.subMenu.map((item, index) => (
						<li
							key={index}
							className={`${item ? styles.menuDesktop__item : styles.menuDesktop__item_dnone}`}
							style={open ? null : { display: 'flex', justifyContent: 'flex-start' }}
						>
							{item?.path ? (
								<NavLink to={`${item?.path}`} className={({ isActive }) => (isActive ? styles.activeLink : '')}>
									{item?.icon}
									{open ? item?.label : ''}
								</NavLink>
							) : (
								<div onClick={() => setIsOpenModal(true)} className={styles.menuDesktop__item_openModal}>
									{item?.icon}
									{open ? item?.label : ''}
								</div>
							)}
						</li>
					))}
				</ul>
			) : null}
		</>
	);
}

export default SubMenu;
