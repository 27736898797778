import React, { useState } from 'react';
import styles from './AllOrdersInfo.module.scss';
import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import { IconArrowDown } from '../../Icons';
import AddInitionalInfo from './components/AddInitionalInfo/AddInitionalInfo';

function AllOrdersInfo({ orderLength, additionalInfo, setAdditionalInfo, handleOpenModalPlug }) {
	return (
		<div className={styles.allOrders_info}>
			<div className={styles.topLine}>
				<p>Заявки: {orderLength}</p>
				<UniversalButton color={'primary'} size={'small'} label={'Сформировать отчёт'} onClick={handleOpenModalPlug} />
			</div>
			{additionalInfo && <AddInitionalInfo />}
			<div
				onClick={() => setAdditionalInfo(!additionalInfo)}
				className={`${styles.btnInfo} ${additionalInfo ? styles.btnInfo_arrowTop : ''}`}
			>
				{additionalInfo ? 'Скрыть доп информацию' : 'Показать доп информацию'}
				<IconArrowDown color={'black'} />
			</div>
		</div>
	);
}

export default AllOrdersInfo;
