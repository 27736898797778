import { useEffect, useState } from 'react';
import styles from './Layouts.module.scss';
import { Preloader, Slider } from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import TerminalMainInfo from '../Terminals/TerminalMainInfo';
import ModulesSettings from '../Terminals/ModulesSettings';
import {
	getTerminalClients,
	getTerminalContainers,
	getTerminalUsers,
	setCurrentJsxComponent,
	setCurrentTerminalDataType,
} from '../../../../../store/slices/terminalPageSlice';
import {
	IconContainerCar,
	IconTradingUp,
	IconUser,
	IconSettings,
	IconContainer,
	IconEmpls,
	IconLock,
} from '../../../../../components/Icons';
import TerminalUsers from '../Terminals/TerminalUsers';
import TerminalClients from '../Terminals/TerminalClients';
import { RequireUIFunc } from '../../../../../hoc/RequireUI';
import ModalPlug from '../../../../../components/Modal/ModalPlug';
export default function LayoutTerminals() {
	const dispatch = useDispatch();

	const {
		terminalUserList,
		currentTerminalId,
		currentTerminalDataType,
		currentJsxComponent,
		terminalContainerList,
		terminalClientList,
		getTerminalClients_pending,
		getTerminalUsers_pending,
		getTerminalContainers_pending,
	} = useSelector((state) => state.terminalPage);

	const dataUser = [
		{
			name: 'Настройки',
			id: 1,
			icon: <IconSettings size={'largeS'} color={'black'} />,
		},
		RequireUIFunc({ name: 'ViewEmpls', terminal_id: currentTerminalId })
			? {
					name: 'Сотрудники',
					id: 2,
					icon: <IconEmpls color={'black'} size={'medium'} />,
				}
			: null,
		{
			name: 'Клиенты',
			id: 3,
			icon: <IconUser size={'largeS'} color={'black'} />,
		},
		{
			name: 'Контейнеры',
			id: 4,
			icon: <IconLock size={'largeS'} color={'black'} />,
		},
		{
			name: 'Заявки',
			id: 5,
			icon: <IconLock size={'largeS'} color={'black'} />,
		},
		{
			name: 'Аналитика',
			id: 6,
			icon: <IconLock size={'largeS'} color={'black'} />,
		},
	]; // Кнопки нижнего слайдера!

	const dataUserFiltered = dataUser.filter((item) => item !== null);

	const setCurrentDataType = (typeData) => {
		return dispatch(setCurrentTerminalDataType(typeData));
	};

	const setCurrentElement = (currentTerminalDataType) => {
		switch (currentTerminalDataType) {
			case '0':
				dispatch(setCurrentJsxComponent(<TerminalMainInfo key={66} />));
				break;
			case '1':
				dispatch(setCurrentJsxComponent(<ModulesSettings change={true} />));
				break;
			case '2':
				dispatch(setCurrentJsxComponent(<TerminalUsers />));
				break;
			case '3':
				dispatch(setCurrentJsxComponent(<TerminalClients />));
				break;
			case '4':
				dispatch(setCurrentJsxComponent(<ModalPlug isOpen={true} fullScreen={false} page={'Терминалы-контейнеры'} />));
				break;
			case '5':
				dispatch(setCurrentJsxComponent(<ModalPlug isOpen={true} fullScreen={false} page={'Терминалы-заявки'} />));
				break;
			case '6':
				dispatch(setCurrentJsxComponent(<ModalPlug isOpen={true} fullScreen={false} page={'Терминалы-аналитика'} />));
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		setCurrentElement(currentTerminalDataType);
	}, [currentTerminalDataType, currentTerminalId, terminalUserList, terminalClientList, dispatch]);

	useEffect(() => {
		if (currentTerminalDataType === '2') {
			dispatch(getTerminalUsers({ terminal_id: currentTerminalId }));
		}
		if (currentTerminalDataType === '3') {
			dispatch(getTerminalClients({ terminal_id: currentTerminalId }));
		}
		if (currentTerminalDataType === '4') {
			dispatch(getTerminalContainers({ terminal_id: currentTerminalId }));
		}
	}, [currentTerminalId, currentTerminalDataType, dispatch]);

	return (
		<div className={styles.layout}>
			<div className={styles.layout__stack}>
				<div className={styles.layout__content}>
					<div className={styles.inner_slider}>
						<Slider
							// setSliderCont={setSliderCont}
							allName={'Основное'}
							data={dataUserFiltered}
							user_group={currentTerminalDataType}
							setUser_group={setCurrentDataType}
						/>
					</div>
					<div className={styles.terminal__content}>
						{getTerminalClients_pending || getTerminalUsers_pending ? (
							<div className={styles.preloader_wrapper}>
								<Preloader />
							</div>
						) : (
							currentJsxComponent
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
