import React from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../components/Header/Header';
import { UniversalButton } from '../../../../components';
import { IconDownload, IconPlus } from '../../../../components/Icons';
import { useMediaQuery } from 'react-responsive';
import LayoutJobs from '../components/Layouts/LayoutJobs';
import { useDispatch } from 'react-redux';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../store/slices/sideBarSlice';
import ModalRights from '../components/Modals/ModalRights';
import { getPermissionList } from '../../../../store/slices/permissionsSlice';
import RequireUI from '../../../../hoc/RequireUI';

function AccountEmplJobTitle() {
	const dispatch = useDispatch();
	const isDesktop = useMediaQuery({ minWidth: 1279.9 });

	const handleNewTitle = (el) => {
		// handleGetClient(el.id);
		// localStorage.setItem('chosenClientId', `${el.id}`);
		dispatch(getPermissionList());
		dispatch(setSideBarActive(true));
		dispatch(setCurrentElementForSideBar(<ModalRights type="createGroup" />));
	};
	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Должности'}
				btnDesktop={
					<div className={styles.header_btns}>
						{/* {['Экспорт данных', 'Добавить должность'].map((label, index) => (
							<UniversalButton
								key={index}
								label={isDesktop ? label : ''}
								color="primary"
								size="medium"
								icon={
									index === 0 ? <IconDownload size="largeS" color="white" /> : <IconPlus size="largeS" color="white" />
								}
								onClick={index === 1 ? handleNewTitle : undefined}
							/>
						))} */}

						{/* {Право на добавление новой должности} */}
						<RequireUI type={{ name: 'AddGroups' }}>
							<UniversalButton
								label={isDesktop ? 'Добавить должность' : ''}
								color="primary"
								size="medium"
								icon={<IconPlus size="largeS" color="white" />}
								onClick={handleNewTitle}
							/>
						</RequireUI>
					</div>
				}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<LayoutJobs />
				</div>
			</div>
		</div>
	);
}

export default AccountEmplJobTitle;
