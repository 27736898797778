import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconStatisCircle({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 17 17" fill="none">
				<g>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.45573 14.6398C11.5853 14.6398 14.1224 12.1027 14.1224 8.97312C14.1224 6.012 11.8512 3.58134 8.95573 3.32821V9.03032C8.95573 9.33974 8.83281 9.63648 8.61402 9.85527L5.00272 13.4666C5.95872 14.2023 7.15613 14.6398 8.45573 14.6398ZM4.46906 4.94602C5.38192 4.04227 6.60045 3.4467 7.95573 3.32821V8.43268L4.46906 4.94602ZM3.82088 5.71205C3.17084 6.63423 2.78906 7.7591 2.78906 8.97312C2.78906 10.4428 3.34858 11.7819 4.26624 12.7888L7.58196 9.47313L3.82088 5.71205ZM8.45573 15.6398C12.1376 15.6398 15.1224 12.655 15.1224 8.97312C15.1224 5.29123 12.1376 2.30646 8.45573 2.30646C4.77383 2.30646 1.78906 5.29123 1.78906 8.97312C1.78906 12.655 4.77383 15.6398 8.45573 15.6398Z"
						fill={IconColor(color)}
					/>
				</g>
			</svg>
		</div>
	);
}

export default IconStatisCircle;
