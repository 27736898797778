import styles from './Landing.module.scss';

function Policy() {
	return (
		<>
			<div>
				<title>STEIZA - Политика конфиденциальности</title>
				<meta name="description" content="STEIZA - Политика конфиденциальности" />
			</div>
			<div className={styles.landing__policy}>
				<h1>Политика в отношении обработки персональных данных</h1>
				<p>
					<span>1. Общие положения</span> <br />
					Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от
					27.07.2006. №152-ФЗ «О персональных данных» (далее - Закон о персональных данных) и определяет порядок обработки
					персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ИП Стефан Кобец (далее –
					Оператор). <br />
					1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека
					и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную
					и семейную тайну. <br />
					1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей
					информации, которую Оператор может получить о посетителях веб-сайта https://steiza.com.
				</p>
				<p>
					<span>2. Основные понятия, используемые в Политике</span> <br />
					2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной
					техники. <br />
					2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев,
					если обработка необходима для уточнения персональных данных). <br />
					2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных,
					обеспечивающих их доступность в сети интернет по сетевому адресу https://steiza.com. <br />
					2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и
					обеспечивающих их обработку информационных технологий и технических средств. <br /> 2.5. Обезличивание персональных
					данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность
					персональных данных конкретному Пользователю или иному субъекту персональных данных. <br />
					2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с
					использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись,
					систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
					(распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.{' '}
					<br />
					2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или
					совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели
					обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с
					персональными данными. <br /> 2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к
					определенному или определяемому Пользователю веб-сайта https://steiza.com. <br /> 2.9. Персональные данные, разрешенные
					субъектом персональных данных для распространения, - персональные данные, доступ неограниченного круга лиц к которым
					предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом
					персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее - персональные
					данные, разрешенные для распространения). <br /> 2.10. Пользователь – любой посетитель веб-сайта https://steiza.com.
					<br />
					2.11. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу
					или определенному кругу лиц. <br />
					2.12. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных
					неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного
					круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в
					информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.
					<br />
					2.13. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства
					органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу. <br />
					2.14. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются
					безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе
					персональных данных и (или) уничтожаются материальные носители персональных данных.
				</p>{' '}
				<p>
					<span>3. Основные права и обязанности Оператора</span> <br />
					3.1. Оператор имеет право: – получать от субъекта персональных данных достоверные информацию и/или документы, содержащие
					персональные данные; – в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор
					вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований,
					указанных в Законе о персональных данных; – самостоятельно определять состав и перечень мер, необходимых и достаточных
					для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним
					нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными
					законами. <br />
					3.2. Оператор обязан: – предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его
					персональных данных; – организовывать обработку персональных данных в порядке, установленном действующим
					законодательством РФ; – отвечать на обращения и запросы субъектов персональных данных и их законных представителей в
					соответствии с требованиями Закона о персональных данных; – сообщать в уполномоченный орган по защите прав субъектов
					персональных данных по запросу этого органа необходимую информацию в течение 30 дней с даты получения такого запроса; –
					публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки персональных
					данных; – принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или
					случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения
					персональных данных, а также от иных неправомерных действий в отношении персональных данных; – прекратить передачу
					(распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в
					порядке и случаях, предусмотренных Законом о персональных данных; – исполнять иные обязанности, предусмотренные Законом
					о персональных данных.
				</p>
				<p>
					<span>4. Основные права и обязанности субъектов персональных данных</span> <br />
					4.1. Субъекты персональных данных имеют право: – получать информацию, касающуюся обработки его персональных данных, за
					исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных
					Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам
					персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных.
					Перечень информации и порядок ее получения установлен Законом о персональных данных; – требовать от оператора уточнения
					его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными,
					устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также
					принимать предусмотренные законом меры по защите своих прав; – выдвигать условие предварительного согласия при обработке
					персональных данных в целях продвижения на рынке товаров, работ и услуг; – на отзыв согласия на обработку персональных
					данных; – обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке
					неправомерные действия или бездействие Оператора при обработке его персональных данных; – на осуществление иных прав,
					предусмотренных законодательством РФ.
					<br /> 4.2. Субъекты персональных данных обязаны: – предоставлять Оператору достоверные данные о себе; – сообщать
					Оператору об уточнении (обновлении, изменении) своих персональных данных. 4.3. Лица, передавшие Оператору недостоверные
					сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в
					соответствии с законодательством РФ.
				</p>
				<p>
					<span>5. Оператор может обрабатывать следующие персональные данные Пользователя</span> <br />
					5.1. Фамилия, имя, отчество. <br />
					5.2. Электронный адрес.
					<br /> 5.3. Номера телефонов. <br />
					5.4. Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью
					сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
					<br /> 5.5. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные. <br />
					5.6. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических
					взглядов, религиозных или философских убеждений, интимной жизни, Оператором не осуществляется. <br />
					5.7. Обработка персональных данных, разрешенных для распространения, из числа специальных категорий персональных данных,
					указанных в ч. 1 ст. 10 Закона о персональных данных, допускается, если соблюдаются запреты и условия, предусмотренные
					ст. 10.1 Закона о персональных данных.
					<br /> 5.8. Согласие Пользователя на обработку персональных данных, разрешенных для распространения, оформляется
					отдельно от других согласий на обработку его персональных данных. При этом соблюдаются условия, предусмотренные, в
					частности, ст. 10.1 Закона о персональных данных. Требования к содержанию такого согласия устанавливаются уполномоченным
					органом по защите прав субъектов персональных данных. <br />
					5.8.1 Согласие на обработку персональных данных, разрешенных для распространения, Пользователь предоставляет Оператору
					непосредственно.
					<br /> 5.8.2 Оператор обязан в срок не позднее трех рабочих дней с момента получения указанного согласия Пользователя
					опубликовать информацию об условиях обработки, о наличии запретов и условий на обработку неограниченным кругом лиц
					персональных данных, разрешенных для распространения.
					<br /> 5.8.3 Передача (распространение, предоставление, доступ) персональных данных, разрешенных субъектом персональных
					данных для распространения, должна быть прекращена в любое время по требованию субъекта персональных данных. Данное
					требование должно включать в себя фамилию, имя, отчество (при наличии), контактную информацию (номер телефона, адрес
					электронной почты или почтовый адрес) субъекта персональных данных, а также перечень персональных данных, обработка
					которых подлежит прекращению. Указанные в данном требовании персональные данные могут обрабатываться только Оператором,
					которому оно направлено. <br />
					5.8.4 Согласие на обработку персональных данных, разрешенных для распространения, прекращает свое действие с момента
					поступления Оператору требования, указанного в п. 5.8.3 настоящей Политики в отношении обработки персональных данных.
				</p>
				<p>
					<span>6. Принципы обработки персональных данных</span> <br />
					6.1. Обработка персональных данных осуществляется на законной и справедливой основе.
					<br /> 6.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей.
					Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных. <br />
					6.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях,
					несовместимых между собой. <br />
					6.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.
					<br /> 6.5. Содержание и объем обрабатываемых персон.
				</p>
			</div>
		</>
	);
}

export default Policy;
