import React from 'react';
import style from './TerminalSmallCardOrder.module.scss';
import { generateBackground, getInitials } from '../../../pages/Account/avatarsUtils';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { setNewOrderTerminalId } from '../../../store/slices/orderSlice';

function TerminalSmallCardOrder({ el, index, active, setActive }) {
	const isDesktop = useMediaQuery({ minWidth: 1550 });
	const dispatch = useDispatch();
	const handleActive = () => {
		active !== index ? setActive(index) : setActive(false);
		dispatch(setNewOrderTerminalId(el.id));
	};
	return (
		<div
			onClick={handleActive}
			className={`${style.mainCardBlock} ${active === index ? style.active_mainCardBlock : style.noActive_mainCardBlock}`}
		>
			<div
				className={`${style.mainCardBlock_leftLine} ${
					active === index ? style.activeMainCardBlock_leftLine : style.noActiveMainCardBlock_leftLine
				}`}
			/>
			<div className={style.mainCardBlock_IconInfo}>
				<div style={{ background: generateBackground(el.name) }} className={style.mainCardBlock_nameIcon}>
					{getInitials(el.name)}
				</div>
				<div className={style.mainCardBlock_info}>
					<p>{el.name}</p>

					<p>{el?.address ? (!isDesktop ? el?.address?.slice(0, 30) + '...' : el.address) : 'не указан'}</p>
				</div>
			</div>
		</div>
	);
}

export default TerminalSmallCardOrder;
