import React, { useEffect, useRef, useState } from 'react';
import styles from './AllOrdersFilter.module.scss';
import CheckBox from '../../CheckBox/CheckBox';
import Switcher from '../../Switcher/Switcher';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderFilters } from '../../../store/slices/orderSlice';
import { getTerminalList } from '../../../store/slices/terminalPageSlice';
import { DatePickerRange } from '../PassComponent/RangeDatePicker/DatePicker';
import { clearClientDataWhileUnmounting, getClients, setClientUrl } from '../../../store/slices/clientSlice';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useUpdateEffect } from '../../../hooks/useUpdateEffect';

function AllOrdersFilter({
	setFilterItems,
	setNoManger,
	noManager,
	importOrderSwitch,
	exportOrderSwitch,
	setImportOrderSwitch,
	setExportOrderSwitch,
	additionalInfo,
	handleOpenModalPlug,
}) {
	//осстояния фильтров
	const [terminal, setTerminal] = useState('');
	const [client, setClient] = useState('');
	const [responsibleManager, setResponsibleManager] = useState('');
	const [responsibleEmployee, setResponsibleEmployee] = useState('');
	//заменить при появлении данных
	const fakeManager = [{ name: 'Анатолий Иванов' }, { name: 'Павел Иванов' }, { name: 'Евгений Иванов' }];
	const fakeEmploee = [{ name: 'Анатолий Иванов' }, { name: 'Павел Иванов' }, { name: 'Евгений Иванов' }];

	const dispatch = useDispatch();
	const { orderFilters } = useSelector((state) => state.order);
	const { terminalList } = useSelector((state) => state.terminalPage);
	const { clientList, url } = useSelector((state) => state.client);

	const formattedTerminals = {
		options: terminalList?.map((el) => {
			return { id: el.id, name: el.name };
		}),
	};
	const formattedClients = {
		options: clientList?.map((client) => {
			return { id: client.id, name: client.organization_name };
		}),
	};

	//! Установка фильтра на заявки на ввоз/вывоз
	const switchExportImportItemFilter = (order) => {
		if (order.type === 'import') {
			setFilterItems((prevItems) => prevItems.concat('Заявки на прием'));
			dispatch(setOrderFilters({ field: 'order_type', data: 1 }));
			setImportOrderSwitch(true);
			if (exportOrderSwitch) {
				dispatch(setOrderFilters({ field: 'order_type', data: null }));
			}
			if (importOrderSwitch) {
				setFilterItems((prevItems) => prevItems.filter((item) => item !== 'Заявки на прием'));
				if (exportOrderSwitch) {
					dispatch(setOrderFilters({ field: 'order_type', data: 2 }));
				}
				if (!exportOrderSwitch) {
					dispatch(setOrderFilters({ field: 'order_type', data: null }));
				}
				setImportOrderSwitch(false);
			}
		}
		if (order.type === 'export') {
			setFilterItems((prevItems) => prevItems.concat('Заявки на вывоз'));
			dispatch(setOrderFilters({ field: 'order_type', data: 2 }));
			setExportOrderSwitch(true);
			if (importOrderSwitch) {
				dispatch(setOrderFilters({ field: 'order_type', data: null }));
			}
			if (exportOrderSwitch) {
				setFilterItems((prevItems) => prevItems.filter((item) => item !== 'Заявки на вывоз'));
				if (importOrderSwitch) {
					dispatch(setOrderFilters({ field: 'order_type', data: 1 }));
				}
				if (!importOrderSwitch) {
					dispatch(setOrderFilters({ field: 'order_type', data: null }));
				}
				setExportOrderSwitch(false);
			}
		}
	};

	//! Установка фильтра (с ответственным менеджером или без)
	const switchNoManager = () => {
		// 	if (!orderFilters.hasNoResponsibleManager) {
		// 		setFilterItems((prevItems) => prevItems.concat('Нет ответственного менеджера'));
		// 		setNoManger(true);
		// 	} else {
		// 		setFilterItems((prevItems) => prevItems.filter((item) => item !== 'Нет ответственного менеджера'));
		// 		setNoManger(false);
		// 	}
		// 	dispatch(setOrderFilters({ field: 'hasNoResponsibleManager', data: !orderFilters.hasNoResponsibleManager }));
	};

	//! Изменение примерной даты начала
	const handleChangeStartDate = (data) => {
		if (data) {
			dispatch(setOrderFilters({ field: 'end_date', data: null }));
			dispatch(setOrderFilters({ field: 'start_date', data: data }));
		} else {
			dispatch(setOrderFilters({ field: 'start_date', data: null }));
		}
	};
	//! Изменение примерной даты окончания
	const handleChangeEndDate = (data) => {
		if (data) {
			dispatch(setOrderFilters({ field: 'end_date', data: data }));
		} else {
			dispatch(setOrderFilters({ field: 'end_date', data: null }));
		}
	};

	//! Выбор фильтра с терминалом
	const handleChangeTerminal = (event) => {
		setTerminal(event.target.value);
		dispatch(setOrderFilters({ field: 'terminal_id', data: event.target.value, extra: terminalList }));
	};
	//! Выбор фильтра с клиентом
	const handleChangelCient = (event) => {
		setClient(event.target.value);
		dispatch(setOrderFilters({ field: 'client_id', data: event.target.value, extra: clientList }));
	};
	//! Выбор фильтра с менеджером
	const handleChangelManager = (event) => {
		setResponsibleManager(event.target.value);
	};
	//! Выбор фильтра с работником
	const handleChangelEmploee = (event) => {
		setResponsibleEmployee(event.target.value);
	};

	useEffect(() => {
		dispatch(setClientUrl(9999));
	}, []);

	//! Запрос на поолучение списка терминалов
	useEffect(() => {
		dispatch(getTerminalList());
	}, []);

	//! Запрос на все получение всех клиентов
	useUpdateEffect(() => {
		dispatch(getClients());
	}, [url, dispatch]);

	//! Очистка данных с клиентами после выхода
	useEffect(() => {
		return () => dispatch(clearClientDataWhileUnmounting());
	}, []);

	return (
		<div className={`${styles.filtrBlock} ${styles.withDatePicker} ${additionalInfo && styles.withAdditionalInfo}`}>
			<p className={styles.title}>Фильтрация</p>
			<div className={styles.noManager} onClick={handleOpenModalPlug}>
				<CheckBox typeCheck={noManager} disabled={true} type={2} onClick={switchNoManager} />
				<p className={styles.filterChechsSwitcher}>Нет ответственного менеджера</p>
			</div>
			<div className={styles.importExportOrder}>
				<Switcher
					externalChecked={importOrderSwitch}
					onChecked={(e) => switchExportImportItemFilter({ action: e, type: 'import' })}
				/>
				<p className={styles.filterChechsSwitcher}>Заявки на приём</p>
			</div>
			<div className={styles.importExportOrder}>
				<Switcher
					externalChecked={exportOrderSwitch}
					onChecked={(e) => switchExportImportItemFilter({ action: e, type: 'export' })}
				/>
				<p className={styles.filterChechsSwitcher}>Заявки на вывоз</p>
			</div>
			<div className={styles.datePicker}>
				<span className={styles.datePickerSpan}>Даты создания заявок</span>
				<DatePickerRange
					startDate={orderFilters?.start_date}
					setStartDate={(e) => handleChangeStartDate(e)}
					endDate={orderFilters?.end_date}
					setEndDate={(e) => handleChangeEndDate(e)}
				/>
			</div>
			<div className={styles.inputSelects}>
				<div className={styles.selectContainer} onClick={handleOpenModalPlug}>
					<p>Ответственные менеджеры</p>
					<FormControl sx={{ m: 1, minWidth: 120, margin: '0px' }} size="small">
						<Select
							id="demo-select-small"
							value={responsibleManager}
							onChange={handleChangelManager}
							displayEmpty
							inputProps={{ 'aria-label': 'Without label' }}
							sx={{ borderRadius: '8px' }}
							required
							disabled
						>
							<MenuItem value={null}>
								<p>не выбран</p>
							</MenuItem>
							{fakeManager.map((el, index) => (
								<MenuItem key={index} value={el.name}>
									{el.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className={styles.selectContainer} onClick={handleOpenModalPlug}>
					<p>Ответственные сотрудники</p>
					<FormControl sx={{ m: 1, minWidth: 120, margin: '0px' }} size="small">
						<Select
							id="demo-select-small"
							value={responsibleEmployee}
							onChange={handleChangelEmploee}
							displayEmpty
							inputProps={{ 'aria-label': 'Without label' }}
							sx={{ borderRadius: '8px' }}
							required
							disabled
						>
							<MenuItem value={null}>
								<p>не выбран</p>
							</MenuItem>
							{fakeEmploee.map((el, index) => (
								<MenuItem key={index} value={el.name}>
									{el.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className={styles.selectContainer} onClick={handleOpenModalPlug}>
					<p>Терминалы</p>
					<FormControl sx={{ m: 1, minWidth: 120, margin: '0px' }} size="small">
						<Select
							id="demo-select-small"
							value={terminal}
							onChange={handleChangeTerminal}
							displayEmpty
							inputProps={{ 'aria-label': 'Without label' }}
							sx={{ borderRadius: '8px' }}
							required
							disabled
						>
							<MenuItem value={null}>
								<p>не выбран</p>
							</MenuItem>
							{formattedTerminals?.options.map((el, index) => (
								<MenuItem key={index} value={el.name}>
									{el.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className={styles.selectContainer} onClick={handleOpenModalPlug}>
					<p>Клиенты</p>
					<FormControl sx={{ m: 1, minWidth: 120, margin: '0px' }} size="small">
						<Select
							id="demo-select-small"
							value={client}
							onChange={handleChangelCient}
							displayEmpty
							inputProps={{ 'aria-label': 'Without label' }}
							sx={{ borderRadius: '8px' }}
							required
							disabled
						>
							<MenuItem value={null}>
								<p>не выбран</p>
							</MenuItem>
							{formattedClients?.options.map((el, index) => (
								<MenuItem key={index} value={el.name}>
									{el.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</div>
		</div>
	);
}

export default AllOrdersFilter;
