import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconLock({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 14 16" fill="none">
				<path
					d="M3.16667 7.33331C3.16667 7.60946 3.39052 7.83331 3.66667 7.83331C3.94281 7.83331 4.16667 7.60946 4.16667 7.33331H3.16667ZM3.66667 4.66665H3.16667H3.66667ZM9.83333 7.33331C9.83333 7.60946 10.0572 7.83331 10.3333 7.83331C10.6095 7.83331 10.8333 7.60946 10.8333 7.33331H9.83333ZM2.33333 7.83331H11.6667V6.83331H2.33333V7.83331ZM11.6667 7.83331C12.1269 7.83331 12.5 8.20641 12.5 8.66665H13.5C13.5 7.65412 12.6792 6.83331 11.6667 6.83331V7.83331ZM12.5 8.66665V13.3333H13.5V8.66665H12.5ZM12.5 13.3333C12.5 13.7936 12.1269 14.1666 11.6667 14.1666V15.1666C12.6792 15.1666 13.5 14.3458 13.5 13.3333H12.5ZM11.6667 14.1666H2.33333V15.1666H11.6667V14.1666ZM2.33333 14.1666C1.8731 14.1666 1.5 13.7936 1.5 13.3333H0.5C0.5 14.3458 1.32081 15.1666 2.33333 15.1666V14.1666ZM1.5 13.3333V8.66665H0.5V13.3333H1.5ZM1.5 8.66665C1.5 8.20641 1.8731 7.83331 2.33333 7.83331V6.83331C1.32081 6.83331 0.5 7.65412 0.5 8.66665H1.5ZM4.16667 7.33331V4.66665H3.16667V7.33331H4.16667ZM4.16667 4.66665C4.16667 3.9152 4.46518 3.19453 4.99653 2.66318L4.28942 1.95607C3.57053 2.67496 3.16667 3.64998 3.16667 4.66665H4.16667ZM4.99653 2.66318C5.52788 2.13182 6.24855 1.83331 7 1.83331V0.833313C5.98334 0.833313 5.00831 1.23718 4.28942 1.95607L4.99653 2.66318ZM7 1.83331C7.75145 1.83331 8.47212 2.13182 9.00347 2.66318L9.71058 1.95607C8.99169 1.23718 8.01666 0.833313 7 0.833313V1.83331ZM9.00347 2.66318C9.53482 3.19453 9.83333 3.9152 9.83333 4.66665H10.8333C10.8333 3.64998 10.4295 2.67496 9.71058 1.95607L9.00347 2.66318ZM9.83333 4.66665V7.33331H10.8333V4.66665H9.83333Z"
					fill={IconColor(color)}
				/>
			</svg>
		</div>
	);
}

export default IconLock;
